<template>
  <div>
    <h3>Container Install</h3>
    <p>
      Attempt to install a container on the selected devices that are currently online.
    </p>
    
    <v-row align="center">
      <v-col cols="12" align="center" class="mt-5">
        <v-select
          v-model="selectedContainer"
          :items="containers"
          :loading="containersLoading"
          itemValue="container_guid"
          item-text="name"
          label="Container to install"
          hide-details
          outlined
        >
        </v-select>
        
        <br />
        
        <v-btn
          class="my-3"
          color="secondary"
          outlined
          @click="containerInstall"
          :disabled="!selectedContainer || actioned"
        >
          Install Container
        </v-btn>
      </v-col>
    </v-row>
    
  </div>
</template>

<script>
  export default {
    name: 'BulkActionContainerInstall',
    props: ['devices'],
    
    data() {
      return {
        minimumAgentVersion: this.$helpers.parseVersion('cloud', 'V1.18.1'),
        
        actioned: false,
        
        containersLoading: false,
        
        containers: [],
        selectedContainer: false,
      }
    },
    
    mounted() {
      this.fetchContainers()
    },
    
    methods: {
      isLicensed(device) {
        var entitlements = device.entitlements()
        if (entitlements['services.docker.enabled'] == true) {
          return true
        }
        return false
      },
      
      isDockerSupported(device) {
        if (device.cloud_version.dev || this.$helpers.versionCheck(this.minimumAgentVersion, device.cloud_version)) {
          return true
        }
        return false
      },
      
      async fetchContainers() {
        this.containersLoading = true
        
        var containers = []
        
        var queryParams = {
          'pagination_size': 50,
        }
        
        var paginationToken = false
        
        try {
          do {
            if (paginationToken) {
              queryParams.pagination_token = paginationToken
            }
            
            let response = await this.axios.get('/containers', {'params': queryParams})
            
            if (response.data.containers.length > 0) {
              console.log('BulkActionContainerInstall fetchContainers response', response)
              
              containers = containers.concat(response.data.containers.map(
                (container) => this.$containers.getContainer(container.container_id, container)
              ))
              
              if (response.data.pagination_token) {
                paginationToken = response.data.pagination_token
              } else {
                paginationToken = false
              }
            }
          } while (paginationToken)
        } catch (error) {
          console.log('BulkActionContainerInstall fetchContainers error', error)
        }
        
        this.containers = containers
        
        this.containersLoading = false
      },
      
      async containerInstall() {
        this.actioned = true
        
        for (var device of this.devices) {
          if (device.isOnline()) {
            if (!this.isLicensed(device)) {
              device.command_message = 'Not Licensed'
            } else if (!this.isDockerSupported(device)) {
              device.command_message = 'Unsupported Version'
            } else {
              try {
                let response = await this.axios.post('devices/' + device.device_guid + '/containers', {'container_id': this.selectedContainer})
                
                console.log('BulkActionContainerInstall containerInstall response', response)
                
                device.command_status = 'accepted'
                device.command_message = 'Command Accepted'
                device.commandGUID = response.data.command_guid
                
                if (device.commandGUID) {
                  device.checkDeviceCommand(device.commandGUID)
                }
              } catch (error) {
                console.log('BulkActionContainerInstall fetchContainers error', error)
              }
            }
          }
        }
      }
    }
  }
</script>