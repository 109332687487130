<template>
  <div>
    <v-dialog
      v-model="showAddDevices"
      max-width="900px"
    >
      <videon-card heading="Add Devices to Fleet" showClose="true" @close="showAddDevices = false" v-if="showAddDevices">
        <v-card-text>
          <p class="mb-0">Add existing devices to this fleet. To adopt a new device, go to <router-link to="/devices">All Devices</router-link>.</p>
          <v-form @submit.prevent="addDevices">
            
            <device-selector v-model="selectedDevices" :disableExisting="true" :existing="fleet.devices"/>
            
            <div class="text-right">
              <v-btn
                class="ma-1"
                color="secondary"
                @click="showAddDevices = false"
                text
              >
                Cancel
              </v-btn>
              <v-btn
                class="ma-1"
                color="primary"
                @click="addDevices"
              >
                Add Devices
              </v-btn>
            </div>
          </v-form>
        </v-card-text>
      </videon-card>
    </v-dialog>
    
    <v-dialog
      v-model="showRemoveDevices"
      max-width="480px"
      v-if="selectedDevices.length > 0"
    >
      <videon-card heading="Remove Device(s)" showClose="true" @close="showRemoveDevices = false">
        <v-card-text>
          <p class="subtitle-1 mt-3">
            Are you sure you want to remove the following devices from this fleet?
            <ul class="mt-2">
              <li 
                v-for="(device, index) in selectedDevices" 
                v-bind:key="index"
                class="primary--text"
              >
                {{ device.deviceName() }} 
              </li>
            </ul>
          </p>
          <v-form @submit.prevent="confirmRemoveDevices">
            <div class="text-right">
              <v-btn
                class="ma-1"
                color="secondary"
                @click="showRemoveDevices = false"
                text
              >
                Cancel
              </v-btn>
              <v-btn
                class="ma-1"
                color="primary"
                @click="confirmRemoveDevices"
              >
                Remove
              </v-btn>
            </div>
          </v-form>
        </v-card-text>
      </videon-card>
    </v-dialog>
    
    <v-dialog
      v-model="showBulkAction"
      max-width="640px"
      v-if="selectedDevices.length > 0"
    >
      <device-bulk-actions v-if="showBulkAction" :action="bulkAction" :devices="selectedDevices" @close="showBulkAction = false" />
    </v-dialog>
    
    <device-status-tiles :status="fleet.status" />
    
    <v-card flat>
      <v-card-title>
        Devices
        <v-spacer/>
        <v-btn
          small
          color="primary"
          @click="showAddDevices = true"
          tooltip="Add Devices to Fleet"
          v-if="canEdit"
        >
          <v-icon>mdi-plus</v-icon> Add Devices
        </v-btn>
      </v-card-title>
    
      <v-card-text>
        <!-- eslint-disable-next-line  -->
        <device-list 
          v-model="fleet.devices" 
          :loading="isLoading" 
          actionGroup="fleet" 
          @deleteSelection="removeDevices"
          @outputControl="outputControl" 
          @firmwareUpdate="firmwareUpdate"
          @containerInstall="containerInstall"
          @rebootDevices="rebootDevices"
          :canEdit="canEdit"
        />
      </v-card-text>
    </v-card>
    <device-events heading="Recent Fleet Events" :showDevice="true" targetObject="fleets" :targetGUID="fleet_guid" :startTime="eventStartTime" />
  </div>
</template>
<script>
  import { mapGetters } from 'vuex'
  
  import DeviceList from '../../device/components/DeviceList.vue'
  
  import DeviceSelector from '../../device/components/DeviceSelector.vue'
  import DeviceEvents from '../../device/components/DeviceEvents.vue'
  import DeviceBulkActions from '../../device/components/DeviceBulkActions.vue'
  
  import moment from 'moment'
  
  export default {
    name: 'FleetOverview',
        
    components: {
      DeviceList,
      DeviceSelector,
      DeviceEvents,
      DeviceBulkActions
    },
    
    props: ['fleet_guid', 'fleet', 'canEdit'],
    
    data() {
      return {
        error: false,
        errorText: '',
      
        loading: false,
      
        showAddDevices: false,
        showRemoveDevices: false,
        showBulkAction: false,
        bulkAction: false,
        
        selectedDevices: [],
        
        deviceHeaders: [
          { text: 'Device', align: 'start', value: 'device_name', sortable: true},
          { text: 'Status', align: 'center', value: 'streaming_status', sortable: true},
          { text: 'Firmware', align: 'center d-none d-lg-table-cell', value: 'firmware_version', sortable: true},
          { text: '', align: 'end', value: 'actions', sortable: false },
        ],
        
        eventStartTime: moment().subtract('5', 'days').toISOString(),
      }
    },
    computed: {
      ...mapGetters('user', ['user', 'guid', 'isOrgAdmin']),
      
      isLoading() {
        if (this.loading) {
          return true
        } else if (!this.fleet.ready) {
          return true
        }
        return false
      },
    },
    
    methods: {
      addDevices() {
        this.showAddDevices = false
        this.loading = true
        
        var deviceGuids = this.selectedDevices.map(device => device.device_guid)
        console.log('FleetDevices addDevices', deviceGuids)
        
        if (deviceGuids.length > 0) {
          this.axios.post('/fleets/' + this.fleet_guid + '/devices', {'devices': deviceGuids})
          .then((response) => {
            console.log('FleetDevices addDevices response', response)
            
            this.selectedDevices = []
            
            this.fleet.fetchDevices()
            
            this.loading = false
            this.error = false
            this.errorText = ''
          }).catch((error) => {
            console.log('FleetDevices addDevices error', error)
            this.error = true
            this.errorText = this.$helpers.parseError(error)
            this.loading = false
          })
        }
      },
      
      manageDevice(item) {
        console.log('FleetDevices manageDevice', item)
        this.$router.push('/devices/' + item.device_guid)
      },
      
      removeDevices(devices) {
        this.selectedDevices = devices
        this.showRemoveDevices = true
      },
      
      confirmRemoveDevices() {
        this.loading = true
        this.showRemoveDevices = false
        
        var deviceGuids = this.selectedDevices.map(device => device.device_guid)
        console.log('FleetDevices confirmRemoveDevices', deviceGuids)
        
        this.axios.delete('/fleets/' + this.fleet_guid + '/devices', {data: {'devices': deviceGuids}})
        .then((response) => {
          console.log('FleetDevices confirmRemoveDevices response', response)
          
          this.fleet.fetchDevices()
          
          this.selectedDevices = []
          this.loading = false
          
          this.error = false
          this.errorText = ''
        }).catch((error) => {
          console.log('FleetDevices confirmRemoveDevices error', error)
          this.error = true
          this.errorText = this.$helpers.parseError(error)
          this.loading = false
        })
      },
      
      outputControl(devices) {
        this.selectedDevices = devices
        this.bulkAction = 'output_control'
        this.showBulkAction = true
      },
      
      firmwareUpdate(devices) {
        this.selectedDevices = devices
        this.bulkAction = 'firmware_update'
        this.showBulkAction = true
      },
      
      containerInstall(devices) {
        this.selectedDevices = devices
        this.bulkAction = 'container_install'
        this.showBulkAction = true
      },
      
      rebootDevices(devices) {
        this.selectedDevices = devices
        this.bulkAction = 'reboot'
        this.showBulkAction = true
      },
      
    }
  }
</script>