<template>
  <v-form
    ref="form"
    v-model="valid"
    lazy-validation
  >
    <v-row><h5 class="text--disabled">Multicast Output</h5></v-row>
    <v-row class="mb-3"><v-divider /></v-row>
    <v-row class="mb-3">
      <v-col cols="12">
        <v-checkbox
          v-model="config.enable"
          label="Output Enabled"
          @change="configChanged"
          :disabled="!canEdit"
          hide-details
          class="mt-0"
        ></v-checkbox>
      </v-col>
    </v-row>
    <v-row v-if="config.name !== undefined || !id" class="mb-3">
      <v-col cols="12">
        <v-text-field
          v-model="config.name"
          label="Output Name"
          @input="configChanged"
          :disabled="!canEdit"
          outlined
          hide-details
        ></v-text-field>
      </v-col>
    </v-row>
    
    <v-row><h5 class="text--disabled">Encoder Selection</h5></v-row>
    <v-row class="mb-3"><v-divider /></v-row>
    <v-row class="mb-3">
      <v-col lg="6" cols="12">
        <encoder-selector :device_guid='device_guid' type="video" :canEdit="canEdit" v-model="config.sources.video" @change="configChanged" :required="config.enable" :rules="videoEncoderValidation" />
      </v-col>
      <v-col lg="6" cols="12">
        <encoder-selector :device_guid='device_guid' type="audio" :canEdit="canEdit" multiple v-model="config.sources.audio" :filter="supportedAudioEncoders" @change="configChanged"/>
      </v-col>
      <v-col v-if="config.sources.data !== undefined" cols="12">
        <encoder-selector :device_guid='device_guid' type="data" :canEdit="canEdit" multiple v-model="config.sources.data" @change="configChanged"/>
      </v-col>
    </v-row>
    
    <v-row><h5 class="text--disabled">Output Target</h5></v-row>
    <v-row class="mb-3"><v-divider /></v-row>
    <v-row class="mb-3">
      <v-col cols="9">
        <v-text-field
          v-model="config.destination_ip"
          label="Multicast Address"
          @input="configChanged"
          :disabled="!canEdit"
          :rules="$validations.multicastAddressValidation"
          outlined
          hide-details="auto"
        ></v-text-field>
      </v-col>
      <v-col cols="3">
        <v-text-field
          v-model.number="config.destination_port"
          label="Port"
          @input="configChanged"
          :disabled="!canEdit"
          type="number"
          :rules="$validations.portValidation"
          outlined
          hide-details="auto"
        ></v-text-field>
      </v-col>
    </v-row>
    
    <v-row><h5 class="text--disabled">Output Configuration</h5></v-row>
    <v-row class="mb-3"><v-divider /></v-row>
    <v-row>
      <v-col cols="3">
        <v-select
          v-model="config.protocol"
          :items="protocolValues"
          label="Protocol"
          @change="configChanged"
          :disabled="!canEdit"
          outlined
          hide-details
        ></v-select>
      </v-col>
      <v-col cols="3">
        <v-text-field
          v-model.number="config.ttl"
          label="Time To Live"
          @input="configChanged"
          :disabled="!canEdit"
          type="number"
          outlined
          hide-details="auto"
        ></v-text-field>
      </v-col>
    </v-row>
    
    <div v-if="id && !isNew && shadowDebug" class="mt-5">
      <v-row><h5 class="text--disabled">Output Details</h5></v-row>
      <v-row class="mb-3"><v-divider /></v-row>
      <v-row class="mb-3">
        <v-col cols="12">
          <pre style="inline-size: 640px; white-space: pre-wrap;">{{ output }}</pre>
        </v-col>
      </v-row>
    </div>
    
    <div v-if="id && !isNew && canEdit" class="text-right">
      <v-btn
        class="ma-1 d-none d-md-inline"
        x-small
        outlined
        @click="deleteOutput()"
      >
        Delete Output
      </v-btn>
    </div>
  </v-form>
</template>
<script>
  import { mapGetters } from 'vuex'
  import ShadowMixins from '../mixins/mixins.js'
  import EncoderSelector from '../components/EncoderSelector.vue'
  
  export default {
    name: 'MulticastOutputDocument',
    
    props: ['output', 'device_guid', 'canEdit', 'isNew'],
    
    components: {
      EncoderSelector
    },
    
    mixins:[ShadowMixins],
    
    data() {
      return {
        valid: true,
        
        id: false,
        type: false,
        
        protocolValues: [
          { 'value': 'RTP', 'text': 'RTP' },
          { 'value': 'RAW_UDP', 'text': 'UDP' },
        ],
        
        supportedAudioEncoders: [
          'mpeg4_aac'
        ],
        
        config: {
          enable: false,
          
          ttl: 5,
          
          protocol: 'RTP',
          
          destination_ip: '',
          destination_port: 1000,
          
          sources: {
            audio: [],
            video: []
          }
        },
      }
    },
    
    watch: {
      output() {
        this.updateValues()
      }
    },
    
    computed: {
      ...mapGetters('userPreferences', ['shadowDebug']),
    },
    
    mounted() {
      this.updateValues()
    },
    
    methods: {
      configChanged() {
        var validationErrors = false
        
        if (this.config.enable == true) {
          var multicastValidation = this.$validations.isValidMulticastAddress(this.config.destination_ip)
        
          // check for validation errors
          if (!multicastValidation) {
            console.log('MulticastOutputDocument ' + this.device_guid + ' validation error: Destination Address must be a valid multicast address.  Valid multicast IP range is 224.0.0.1 - 239.255.255.255', this.config)
            validationErrors = true
          } 
        
          if (this.config.destination_port < 1000 || this.config.destination_port > 65000) {
            console.log('MulticastOutputDocument ' + this.device_guid + ' validation error: Port must be between 1000 and 65000', this.config)
            validationErrors = true
          }
          
          this.$refs.form.validate()
        } else {
          this.$refs.form.resetValidation()
        }
        
        console.log('MulticastOutputDocument ' + this.device_guid + ' configChanged', this.config)
        this.$emit('configUpdate', this.config, validationErrors)
      },
      
      updateValues() {
        if (this.output && this.output.config) {
          Object.keys(this.output).map((key) => {
            this[key] = this.output[key]
          })
          
          // htmlDecode name properties
          if (this.config.name !== undefined) {
            this.config.name = this.$helpers.htmlDecode(this.config.name)
          }
        }
        
        // show data encoder if creating new
        if (this.isNew) {
          if (this.config.sources.data === undefined) {
            this.$set(this.config.sources, 'data', [])
          }
        }
        
        this.configChanged()
      },
      
      deleteOutput() {
        console.log('MulticastOutputDocument ' + this.device_guid + ' deleteOutput')
        this.$emit('deleteOutput')
      }
    }
  }
</script>