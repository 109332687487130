<template>
  <v-card flat>
    <v-card-title>
      System Configuration
      <v-spacer/>
    </v-card-title>
    <v-card-text>
      <v-container>
        <system-document :system="document" :device_guid="device_guid" :canEdit="canEdit" @configUpdate="configUpdate"/>
      </v-container>
      <div class="text-right" v-if="canEdit">
        <v-btn
          color="secondary"
          text
          class="ma-1"
          @click="revertShadow()"
          :disabled="!hasChanges"
        >
          Revert
        </v-btn>
        <v-btn
          color="primary"
          class="ma-1"
          @click="saveShadow()"
          :disabled="!hasChanges"
        >
          Save
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
  import SystemDocument from './documents/SystemDocument.vue'
  
  export default {
    name: 'EdgecasterSystem',
    
    props: ['deviceShadow', 'device_guid'],
    
    components: {
      SystemDocument
    },
    
    data() {
      return {
        document: {},
        hasChanges: false,
        expectingChanges: false,
      }
    },
    watch: {
      deviceShadow: {
        handler() {
          // only overwrite if we dont have any documents to start with
          if (this.expectingChanges || this.document.length == 0) {
            var systemDocument = this.deviceShadow.editableStateCopy()
            if (systemDocument) {
              this.document = systemDocument
              
              // reset expectingChanges
              setTimeout(() => {
                this.expectingChanges = false
              }, 5000)
            }
          }
        }, 
        deep: true
      }
    },
    computed: {
      canEdit() {
        if (this.deviceShadow.updatePending) {
          return false
        }
        
        return this.$devices.getDevice(this.device_guid).canEdit()
      }
    },
    
    mounted() {
      var systemDocument = this.deviceShadow.editableStateCopy()
      if (systemDocument) {
        this.document = systemDocument
        this.hasChanges = false
      }
    },
    
    methods: {
      configUpdate(config) {
        this.hasChanges = true
        this.updatedConfig = config
      },
      saveShadow() {
        this.deviceShadow.saveShadow({'config': this.document.config})
        this.hasChanges = false
        this.expectingChanges = true
      },
      revertShadow() {
        this.document = this.deviceShadow.editableStateCopy()
      }
    }
  }
</script>