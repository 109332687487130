<template>
  <v-list-item 
    @click="showDialog()"
    :disabled="!device.isOnline()"
  >
    <v-dialog
      v-if="device"
      v-model="showControl"
      max-width="540px"
    >
      <videon-card heading="Manage Local UI Password" showClose="true" :ignoreHeaderMargin="!isVersionSupported" @close="showControl = false">
        <v-alert
          text
          color="orange"
          icon="mdi-alert-outline"
          v-if="!isVersionSupported"
        >
          This feature is not supported on {{ this.device.cloud_version.original }}, please update your cloud agent.
        </v-alert>
        <v-card-text>
          <v-alert dense outlined type="error" v-if="commandStatus == 'rejected' || commandStatus == 'failed'" >
            {{ commandMessage }}
          </v-alert>
          <v-alert dense outlined type="info" v-if="commandStatus == 'complete'" >
            {{ commandMessage }}
          </v-alert>
          <div v-if="processing" class="py-5">
            <v-progress-linear
              color="primary"
              indeterminate
              buffer-value="0"
            ></v-progress-linear>
          </div>
          <div class="mt-5">
            <v-text-field
              v-if="isPasswordSet"
              v-model="current_ui_password"
              label="Current Password"
              name="current-ui-password"
              autocomplete="new-password"
              type="password"
              :disabled="processing"
              required
              outlined
            ></v-text-field>
            <v-text-field
              v-model="new_ui_password"
              label="New Password"
              name="new-ui-password"
              autocomplete="new-password"
              type="password"
              :messages="(isPasswordSet) ? 'A blank new password will disable local UI authentication.' : ''"
              :disabled="processing"
              required
              outlined
            ></v-text-field>
            <v-text-field
              v-model="confirm_new_ui_password"
              label="Confirm New Password"
              name="confirm-new-ui-password"
              autocomplete="new-password"
              type="password"
              :disabled="processing"
              required
              outlined
            ></v-text-field>
          </div>
          <div class="text-right">
            <v-btn
              class="ma-1"
              color="secondary"
              @click="showControl = false"
              text
            >
              Cancel
            </v-btn>
            
            <v-btn
              class="ma-1"
              color="primary"
              @click="setUIPassword()"
              :disabled="!isVersionSupported || processing"
            >
              Set Password
            </v-btn>
            
          </div>
        </v-card-text>
      </videon-card>
    </v-dialog>
    
    <v-list-item-title class="pr-2">
      <v-icon class="material-icons-outlined">lock</v-icon> 
      Local UI Password
    </v-list-item-title>
  </v-list-item>
</template>

<script>
  export default {
    name: 'LocalUIPassword',
    
    props: ['device'],
    
    data() {
      return {
        minimumAgentVersion: this.$helpers.parseVersion('cloud', 'V1.7.3'),
        
        showControl: false,
        
        current_ui_password: '',
        new_ui_password: '',
        confirm_new_ui_password: ''
      }
    },
    mounted() {
      
    },
    computed: {
      isVersionSupported() {
        if (this.$helpers.versionCheck(this.minimumAgentVersion, this.device.cloud_version)) {
          return true
        } else if (this.device.cloud_version.dev) {
          return true
        }
        return false
      },
      
      processing() {
        if (this.device.isCommandProcessing()) {
          return true
        }
        return false
      },
      
      isPasswordSet() {
        if (this.device.state && this.device.state.ui_password_set) {
          return this.device.state.ui_password_set
        }
        return false
      },
      
      commandStatus() {
        return this.device.command_status
      },
      commandMessage() {
        return this.device.command_message
      },
    },

    methods: {
      showDialog() {
        this.device.resetCommandState()
        this.showControl = true
      },
      setUIPassword() {
        var data = {}
        
        if (this.isPasswordSet) {
          if (!this.current_ui_password) {
            // do not issue command
            return
          }
          data.current_ui_password = this.current_ui_password
        }
        if (this.new_ui_password != this.confirm_new_ui_password) {
          return
        }
        
        data.new_ui_password = this.new_ui_password
        
        console.log('LocalUIPassword ' + this.device.device_guid + ' setUIPassword', data)
        this.device.runCommand('set_ui_password', data)
      }
    }
  }
</script>