<template>
  <v-list-item 
    @click="showDialog()"
    :disabled="!device.isOnline()"
  >
    <v-dialog
      v-if="device"
      v-model="showControl"
      max-width="480px"
    >
      <videon-card :heading="'Reboot ' + device.deviceName()" showClose="true" @close="showControl = false">
        <v-card-text>
          <p class="subtitle-1 my-5">
            Are you sure you want to reboot <span class="primary--text">{{ device.deviceName() }}</span>?
          </p>
          <v-form @submit.prevent="rebootDevice">
            <v-text-field
              v-model="rebootDeviceConfirm"
              label="Reboot Confirmation"
              messages="Please type CONFIRM above to proceed"
              required
              outlined
            ></v-text-field>
            <div class="mt-3 d-flex align-end">
              <v-btn
                class="ma-1"
                @click="restartAgent"
                text
                small
              >
                Restart Cloud Agent
              </v-btn>
              <v-spacer />
              <v-btn
                class="ma-1"
                color="secondary"
                @click="showControl = false"
                text
              >
                Cancel
              </v-btn>
              <v-btn
                class="ma-1"
                color="primary"
                @click="rebootDevice"
              >
                Reboot
              </v-btn>
            </div>
          </v-form>
        </v-card-text>
      </videon-card>
    </v-dialog>
    
    <v-list-item-title class="red--text">
      <v-progress-circular
        v-if="loading"
        class="mr-1"
        indeterminate
        color="primary"
        size="23"
        width="2"
      ></v-progress-circular>
      <v-icon color="red" v-else>refresh</v-icon> 
      Reboot Device
    </v-list-item-title>
  </v-list-item>
</template>

<script>
  export default {
    name: 'RebootDevice',
    
    props: ['device'],
    
    data() {
      return {
        error: false,
        errorText: '',
        
        showControl: false,
        
        rebootDeviceConfirm: ''
      }
    },
    mounted() {
      
    },
    watch: {
      
    },
    computed: {
      loading() {
        if (this.device.isCommandProcessing()) {
          return true
        }
        return false
      },
    },
    
    methods: {
      showDialog() {
        this.device.resetCommandState()
        this.showControl = true
      },
      restartAgent() {
        this.device.runCommand('restart_cloud')
        this.showControl = false
      },
      rebootDevice() {
        if (this.rebootDeviceConfirm == 'CONFIRM') {
          this.device.runCommand('reboot_device')
          this.showControl = false
        }
      }
    }
  }
</script>