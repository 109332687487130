<template>
  <v-card 
    class="mx-auto"
  >
    <v-dialog
      v-model="showDeleteOrg"
      max-width="400px"
    >
      <videon-card heading="Delete Organization" showClose="true" @close="showDeleteOrg = false">
        <v-card-text>
          <p class="subtitle-1 mt-3">
            Are you sure you want to delete <span class="primary--text text-no-wrap">{{ org_name }}</span>?
          </p>
          <v-form @submit.prevent="confirmDeleteOrg">
            <v-text-field
              v-model="deleteOrgReason"
              label="Reason"
              required
              outlined
            ></v-text-field>
            <div class="text-right">
              <v-btn
                class="ma-1"
                color="secondary"
                @click="showDeleteOrg = false"
                text
              >
                Cancel
              </v-btn>
              <v-btn
                class="ma-1"
                color="primary"
                @click="confirmDeleteOrg"
              >
                Delete
              </v-btn>
            </div>
          </v-form>
        </v-card-text>
      </videon-card>
    </v-dialog>
  
    <v-dialog
      v-model="showRenameOrg"
      max-width="400px"
    >
      <videon-card heading="Rename Organization" showClose="true" @close="showRenameOrg = false">
        <v-card-text>
          <v-form @submit.prevent="renameOrg">
            <v-text-field
              v-model="newOrgName"
              label="Org Name"
              required
              outlined
            ></v-text-field>
            <div class="text-right">
              <v-btn
                class="ma-1"
                color="secondary"
                @click="showRenameOrg = false"
                text
              >
                Cancel
              </v-btn>
              <v-btn
                class="ma-1"
                color="primary"
                @click="renameOrg"
              >
                Rename
              </v-btn>
            </div>
          </v-form>
        </v-card-text>
      </videon-card>
    </v-dialog>
    
    <v-dialog
      v-model="showInviteUser"
      max-width="400px"
    >
      <videon-card heading="Invite User" showClose="true" @close="showInviteUser = false">
        <v-card-text>
          <v-form @submit.prevent="inviteUser">
            <p class="mt-3">
              Please enter the email address of the user you wish to invite.
            </p>
            
            <v-text-field
              v-model="inviteUserEmail"
              label="E-mail"
              type="email"
              :error-messages="emailErrors"
              @input="$v.inviteUserEmail.$touch()"
              @blur="$v.inviteUserEmail.$touch()"
              class="mb-2 mt-2"
              required
              outlined
            ></v-text-field>
            
            <v-select
              v-model="newUserPermissions"
              :items="[{'text': 'Read Only', 'value': 100}, {'text': 'User', 'value': 200}, {'text': 'Admin', 'value': 300}]"
              label="Access Level"
              outlined
            ></v-select>
            
            
            <div class="text-right">
              <v-btn
                class="ma-1"
                color="secondary"
                @click="showInviteUser = false"
                text
              >
                Cancel
              </v-btn>
              <v-btn
                class="ma-1"
                color="primary"
                @click="inviteUser"
              >
                Invite
              </v-btn>
            </div>
          </v-form>
        </v-card-text>
      </videon-card>
    </v-dialog>
    
    <v-dialog
      v-model="showEditPermissions"
      max-width="400px"
    >
      <videon-card heading="Edit Organization Permissions" showClose="true" @close="showEditPermissions = false">
        <v-card-text>
          <v-form @submit.prevent="editPermsForUser">
          
            <user-label v-if="editPermsUser" :user="$users.getUser(editPermsUser.user_guid)" class="ma-3 my-8"/>
            
            <v-select
              v-model="newUserPermissions"
              :items="[{'text': 'Read Only', 'value': 100}, {'text': 'User', 'value': 200}, {'text': 'Admin', 'value': 300}]"
              label="Access Level"
              outlined
            ></v-select>
            
            <div class="text-right">
              <v-btn
                class="ma-1"
                color="secondary"
                @click="showEditPermissions = false"
                text
              >
                Cancel
              </v-btn>
              <v-btn
                class="ma-1"
                color="primary"
                @click="editPermsForUser"
              >
                Update
              </v-btn>
            </div>
          </v-form>
        </v-card-text>
      </videon-card>
    </v-dialog>
    
    <v-toolbar flat>
      <v-toolbar-title>
        <v-avatar
          height="32px"
          class="mx-auto"
        >
          <videon-logo />
        </v-avatar>
        {{ org_name }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      
      <v-btn
        text
        small
        class="ma-1"
        @click="$router.go(-1)"
      >
        <v-icon>mdi-arrow-left</v-icon> Back
      </v-btn>
      <div v-if="isOrgAdmin" class="d-none d-md-inline">
        <v-btn
          class="ma-1"
          small
          text
          color="secondary"
          @click="showRenameOrg = true"
        >
          <v-icon>edit</v-icon> Rename
        </v-btn>
        <v-btn
          class="ma-1"
          small
          text
          @click="showDeleteOrg = true"
        >
          <v-icon>delete</v-icon> Delete
        </v-btn>
      </div>
    </v-toolbar>
    
    <v-divider/>
    
    <v-card-text v-if="editable">
      <v-alert dense outlined type="error" v-if="error">
        {{ errorText }}
      </v-alert>
    
      <v-alert dense outlined type="info" v-if="hasChanges">
        There are unsaved changes, please Save when done.
      </v-alert>
      
      <v-card flat>
        <v-card-title>
          Organization Users
          <v-spacer/>
          <v-btn
            small
            color="accent"
            outlined
            class="mr-1"
            @click="useOrg"
            tooltip="Use Org"
            v-if="isOrgAdmin"
          >
            <v-icon>location_city</v-icon> Switch to Org
          </v-btn>
          <v-btn
            small
            color="primary"
            @click="showInviteUser = true"
            tooltip="Invite User"
            v-if="editable"
          >
            <v-icon>mdi-plus</v-icon> Invite User
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-data-table
            :headers="headers"
            :items="users"
            :loading="loading"
            :items-per-page="200"
            hide-default-footer
          >
            <template v-slot:item.name="{ item }">
              <user-label :user="$users.getUser(item.user_guid)" />
            </template>
            <template v-slot:item.access_level="{ item }">
             <access-level-label :access_level="item.access" />
            </template>
            <template v-slot:item.actions="{ item }">
              <div v-if="editable" class="text-right">
                <v-btn
                  class="ma-1"
                  x-small
                  outlined
                  color="secondary"
                  @click="editUser(item)"
                >
                  Edit Permissions
                </v-btn>
                <v-btn
                  class="ma-1"
                  x-small
                  outlined
                  @click="deleteUser(item)"
                >
                  Remove
                </v-btn>
              </div>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions v-if="hasChanges">
          <v-row
             align="center"
             justify="end"
             class="ma-2"
           >
             <v-btn
               color="secondary"
               @click="revertChanges"
               class="ma-1"
               outlined
             >
               Revert
             </v-btn>
             <v-btn
               color="primary"
               @click="saveChanges"
               class="ma-1"
             >
               Save
             </v-btn>
           </v-row>
        </v-card-actions>
      </v-card>
      
      <v-card flat v-if="invites.length > 0">
        <v-card-title>
          Pending Invites
          <v-spacer/>
        </v-card-title>
        <v-card-text>
          <v-data-table
            :headers="headers"
            :items="invites"
            hide-default-footer
            :items-per-page="200"
          >
            <template v-slot:item.name="{ item }">
              <span class="subtitle-2">{{ item.target_email }}</span>
            </template>
            <template v-slot:item.access_level="{ item }">
              <access-level-label :access_level="item.access" />
            </template>
            <template v-slot:item.actions="{ item }">
              <div v-if="editable" class="text-right">
                <v-btn
                  class="ma-1"
                  x-small
                  outlined
                  @click="deleteInvite(item)"
                >
                  Cancel Invite
                </v-btn>
              </div>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
      
    </v-card-text>
  </v-card>
</template>

<script>
  import { mapGetters } from 'vuex'
  
  import { validationMixin } from 'vuelidate'
  import { required, email } from 'vuelidate/lib/validators'
  
  export default {
    mixins: [validationMixin],
    
    name: 'EditOrg',
    
    validations: {
      inviteUserEmail: { required, email },
    },
    
    data() {
      return {
        error: false,
        errorText: '',
      
        showDeleteOrg: false,
        deleteOrgReason: '',
      
        showRenameOrg: false,
        newOrgName: '',
      
        showInviteUser: false,
        inviteUserEmail: '',
      
        showEditPermissions: false,
        editPermsUser: false,
        newUserPermissions: 100,
      
        loading: false,
        editable: false,
      
        org_name: 'Organization',
      
        headers: [
          { text: 'User', align: 'start', value: 'name', sortable: false },
          { text: 'Access Level', align: 'center', value: 'access_level', sortable: false },
          { text: '', align: 'end', value: 'actions', sortable: false },
        ],
      
        users: [],
        totalUsers: 0,
        usersPerPage: 50,
        page: 1,
        pageCount: 0,
        paginationTokens: [],
      
        hasChanges: false,
      
        invitesAddExisting: false,
        invites: [],
      }
    },
    computed: {
      ...mapGetters('user', ['user', 'guid', 'organization', 'isOrgAdmin']),
      
      emailErrors () {
        const errors = []
        if (!this.$v.inviteUserEmail.$dirty) return errors
        !this.$v.inviteUserEmail.email && errors.push('Must be valid e-mail')
        !this.$v.inviteUserEmail.required && errors.push('E-mail is required')
        return errors
      },
    },
    props: {
      org_guid: {
        type: String,
        required: true
      }
    },
    watch: {
      users() {
        this.validateAccess()
      }
    },
    mounted() {
      this.loadOrg()
      if (this.isOrgAdmin) {
        this.editable = true
      }
    },
    methods: {
      loadOrg() {
        this.loading = true
        
        if (!this.org_guid) {
          this.error = true
          this.errorText = 'org_guid not supplied'
          return
        }
        
        // TODO move this over to using the org cache... and implement cache refresh
        // TODO do the actual TODO
        this.axios.get('/orgs/' + this.org_guid).then((response) => {
          console.log('EditOrg loadOrg response', response)
          
          this.org_name = response.data.org.org_name
          this.newOrgName = this.org_name
          
          // fetch users
          // TODO pagination of org users
          this.axios.get('/orgs/' + this.org_guid + '/users').then((response) => {
            console.log('EditOrg loadOrg users response', response)
            
            this.users = response.data.users
            
            // see if this user can edit or not...
            this.validateAccess()
            
            // fetch invites
            this.axios.get('/invites', {params: {'org_guid': this.org_guid}}).then((response) => {
              console.log('EditOrg loadOrg invites response', response)
            
              this.invites = response.data.invites
            
              this.loading = false
            }).catch(() => {
              // dont care...
              this.loading = false
            })
          }).catch((error) => {
            this.error = true
            this.errorText = this.$helpers.parseError(error)
            this.loading = false
          })
        }).catch((error) => {
          this.error = true
          this.errorText = this.$helpers.parseError(error)
          this.loading = false
        })
      },
      
      validateAccess() {
        for (var i in this.users) {
          var user = this.users[i]
          // if this user has appropriate permissions enable editable flag
          if (this.guid == user.user_guid && user.access > 200) {
            console.log('EditOrg validateAccess user can edit org')
            this.editable = true
            return
          }
        }
      },
      
      useOrg() {
        var org = this.$organizations.getOrg(this.org_guid)
        this.$store.dispatch('user/setOrganization', org)
      },
      
      renameOrg() {
        if (this.newOrgName) {
          this.loading = true
          this.showRenameOrg = false
          
          this.axios.patch('/orgs/' + this.org_guid, { 'org_name': this.newOrgName })
          .then((response) => {
            console.log('EditOrg renameOrg response', response)
            
            this.loadOrg()
            
            this.$organizations.invalidateOrg(this.org_guid)
          }).catch((error) => {
            console.log('EditOrg renameOrg error', error)
          
            this.error = true
            this.errorText = this.$helpers.parseError(error)
          
            this.loading = false
          })
          
        }
      },
      
      confirmDeleteOrg() {
        if (this.deleteOrgReason) {
          this.loading = true
          this.showDeleteOrg = false
        
          this.axios.delete('/orgs/' + this.org_guid, {'data': {'reason': this.deleteOrgReason}})
          .then((response) => {
            console.log('EditOrg confirmDeleteOrg response', response)
            
            this.error = false
            this.errorText = ''
            
            this.$organizations.invalidateOrg(this.org_guid)
            
            this.$router.push('/orgs')
          }).catch((error) => {
            console.log('EditOrg confirmDeleteOrg error', error)
          
            this.error = true
            this.errorText = this.$helpers.parseError(error)
          
            this.loading = false
          })
        }
      },
      
      inviteUser() {
        this.error = false
        this.errorText = ''
        
        if (this.inviteUserEmail) {
          console.log('EditOrg inviteUser', this.inviteUserEmail)
          this.showInviteUser = false
          this.loading = true
          
          for (var x in this.users) {
            var resolvedUser = this.$users.getUser(this.users[x].user_guid)
            if (resolvedUser.email == this.inviteUserEmail) {
              this.error = true
              this.errorText = 'A user with this email already exists in the organization.'
              this.loading = false
              return
            }
          }
          
          this.axios.post('/invites', {'org_guid': this.org_guid, 'target_email': this.inviteUserEmail.toLowerCase(), 'access': this.newUserPermissions})
          .then((response) => {
            console.log('EditOrg inviteUser response', response)
            
            this.loadOrg()
            
            this.inviteUserEmail = ''
          }).catch((error) => {
            console.log('EditOrg inviteUser error', error)
            
            this.error = true
            this.errorText = this.$helpers.parseError(error)
            
            this.loading = false
          })
        }
      },
      
      editUser(item) {
        this.editPermsUser = item
        this.newUserPermissions = item.access
        this.showEditPermissions = true
      },
      
      editPermsForUser() {
        this.showEditPermissions = false
        
        for (var x in this.users) {
          if (this.users[x].user_guid == this.editPermsUser.user_guid) {
            this.users[x].access = this.newUserPermissions
            this.hasChanges = true
            return
          }
        }
        
      },
      
      deleteUser(item) {
        this.loading = true
      
        this.axios.delete('/orgs/' + this.org_guid + '/users', {'data': {'users': [item.user_guid]}})
        .then((response) => {
          console.log('EditOrg deleteUser response', response)
          
          this.loadOrg()
        }).catch((error) => {
          console.log('EditOrg deleteUser error', error)
        
          this.error = true
          this.errorText = this.$helpers.parseError(error)
        
          this.loading = false
        })
      },
      
      viewUser(item) {
        console.log('EditOrg viewUser', item)
        this.$router.push('/admin/users/' + item.user_guid)
      },
      
      saveChanges() {
        this.hasChanges = false
        this.loading = true
        
        var updateUsers = []
        for (var x in this.users) {
          updateUsers.push({'user_guid': this.users[x].user_guid, 'access': this.users[x].access})
        }
        
        console.log('EditOrg saveChanges users', updateUsers)
        
        this.axios.put('/orgs/' + this.org_guid + '/users', { 'users': updateUsers })
        .then((response) => {
          console.log('EditOrg saveChanges response', response)
          
          this.loadOrg()
        }).catch((error) => {
          console.log('EditOrg saveChanges error', error)
        
          this.error = true
          this.errorText = this.$helpers.parseError(error)
        
          this.loading = false
        })
      },
      
      revertChanges() {
        this.loadOrg()
      },
      
      
      deleteInvite(item) {
        if (item.invite_guid) {
          this.loading = true
      
          this.axios.delete('/invites/' + encodeURIComponent(item.invite_guid), {'data': {'org_guid': this.org_guid}})
          .then((response) => {
            console.log('EditOrg deleteInvite response', response)
          
            this.loadOrg()
          }).catch((error) => {
            console.log('EditOrg deleteInvite error', error)
        
            this.error = true
            this.errorText = this.$helpers.parseError(error)
        
            this.loading = false
          })
        }
      }
    }
  }
</script>