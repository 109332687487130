var ipAddressPattern = /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$|^([0-9a-fA-F]{1,4}:){7}[0-9a-fA-F]{1,4}$/
var multicastAddressPattern = /2(?:2[4-9]|3\d)(?:\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d?|0)){2}(?:\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d?)){1}$/
var hostnamePattern = /^[A-Za-z0-9][A-Za-z0-9-._]*\.\D{2,4}$/

var containerNamePattern = /^[a-zA-Z0-9]*$/

export default {
  percent: [
    v => (!isNaN(v)) || 'Value must be a number',
    v => (v >= 0) || 'Value must be >= 0',
    v => (v <= 100) || 'Value must be <= 100'
  ],

  ipAddressValidation: [
    v => ipAddressPattern.test(v) || 'Value must be a valid IP address'
  ],

  multicastAddressValidation: [
    v => multicastAddressPattern.test(v) || 'Value must be a valid multicast address. Valid multicast IP range is 224.0.0.1 - 239.255.255.255'
  ],

  destinationAddressValidation: [
    v => (ipAddressPattern.test(v) || hostnamePattern.test(v)) || 'Value must be a valid IP address or hostname'
  ],

  portValidation: [
    v => (!isNaN(v)) || 'Value must be a number',
    v => (v >= 1000) || 'Value must be >= 1000',
    v => (v <= 65000) || 'Value must be <= 65000'
  ],
  
  passphraseValidation: [
    v => (v && v.length >= 10) || 'Must be >= 10 characters',
    v => (v && v.length > 100 || v.length <= 79) || 'Value must <= 79 characters'
  ],
  
  containerNameValidation: [
    v => containerNamePattern.test(v) || 'Container name must not include spaces or special characters'
  ],
  
  isValidIpAddress(value) {
    return ipAddressPattern.test(value)
  },

  isValidMulticastAddress(value) {
    return multicastAddressPattern.test(value)
  },

  isValidHostname(value) {
    return hostnamePattern.test(value)
  },
  
  isValidContainerName(value) {
    return containerNamePattern.test(value)
  }
}