<template>
  <v-card 
    class="mx-auto"
  >
    <v-toolbar flat>
      <v-toolbar-title>
        <v-icon size="32px">storage</v-icon>
        Provisioned Devices
      </v-toolbar-title>
      <v-spacer></v-spacer>
      
      
    </v-toolbar>
    
    <v-divider/>
    
    <v-card-text>
      <v-alert dense outlined type="error" v-if="error">
        {{ errorText }}
      </v-alert>
    
      <v-container>
        <v-row class="mx-2 my-1" align="end">
          <v-text-field
            class="mr-5"
            v-model="search"
            append-icon="mdi-magnify"
            label="Search by Device Serial"
            hide-details
          ></v-text-field>

          <v-select
            class="mr-5"
            v-model="productNames"
            :items="productNameList"
            item-text="label"
            item-value="value"
            label="Product"
            clearable
            multiple
            hide-details
          ></v-select>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-data-table
              :headers="headers"
              :items="items"
              item-key="device_guid"
              :loading="loading"
              :page.sync="page"
              :items-per-page="itemsPerPage"
              sort-by="device_name"
              must-sort
              hide-default-footer
            >
              <template v-slot:item.device_name="{ item }">
                <device-label :device="item" />
              </template>
              <template v-slot:item.streaming_status="{ item }">
                <streaming-status-label :device="item" />
              </template>
              <template v-slot:item.firmware_version="{ item }">
                <firmware-version-label :device="item" />
              </template>
              <template v-slot:item.org_guid="{ item }">
                <span v-if="item.org_guid" class="subtitle-2">{{ $organizations.getOrg(item.org_guid).displayName() }}</span>
                <span v-else class="subtitle-2">Not Adopted</span>
              </template>
            </v-data-table>
            <data-table-pagination v-model="page" :paginationTokens="paginationTokens" />
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>  
  export default {
    name: 'AdminDeviceListing',
    data() {
      return {
        error: false,
        errorText: '',
      
        selectedDevice: false,
      
        search: '',
        productNames: [],
        awaitingSearch: false,

        productNameList: [
          { label: 'Edgecaster', value: 'edgecaster' },
          { label: 'Edgecaster Max', value: 'edgecaster_max' },
        ],
      
        headers: [
          { text: 'Serial', align: 'center text-no-wrap', value: 'serial_number', sortable: true },
          { text: 'Device', align: 'start', value: 'device_name', sortable: true },
          { text: 'Organization', align: 'left', value: 'org_guid', sortable: true},
          { text: 'Status', align: 'center d-none d-lg-table-cell', value: 'streaming_status', sortable: true},
          { text: 'Firmware', align: 'center d-none d-lg-table-cell', value: 'firmware_version', sortable: true},
          { text: 'Cloud Agent', align: 'center d-none d-lg-table-cell', value: 'state.cloud_version', sortable: true},
        ],
      
        loading: true,
      
        items: [],
      
        itemsPerPage: 50,
        page: 1,
        pageCount: 0,
      
        paginationTokens: []
      }
    },
    mounted() {
      this.searchDevices(0)
    },
    watch: {
      page() {
        this.searchDevices(this.page - 1)
      },
      itemsPerPage() {
        this.items = []
        this.page = 1
        this.searchDevices(this.page - 1)
      },
      search() {
        if (!this.awaitingSearch) {
          setTimeout(() => {
            this.items = []
            this.page = 1
            this.searchDevices(0)
            this.awaitingSearch = false
          }, 2000)
        }
        this.awaitingSearch = true
      },
      productNames() {
        if (!this.awaitingSearch) {
          setTimeout(() => {
            this.items = []
            this.page = 1
            this.searchDevices(0)
            this.awaitingSearch = false
          }, 500) // Reduce time because it's a dropdown
        }
        this.awaitingSearch = true
      }
    },
    computed: {
      
    },
    methods: {
      searchDevices(paginationIndex) {
        this.loading = true
        
        const paginationToken = this.paginationTokens[paginationIndex - 1]
        
        var queryParams = {
          'pagination_size': this.itemsPerPage
        }
        
        if (this.search) {
          queryParams.serial_number = this.search
        }

        if (this.productNames) {
          queryParams.product_names = this.productNames
        }
        
        if (paginationToken) {
          queryParams.pagination_token = paginationToken
        }
        
        this.axios.get('/devices', {
          'params': queryParams,
          paramsSerializer: {
            indexes: null
          }
        }).then((response) => {
          console.log('AdminDeviceListing searchDevices response', response)
          
          if (response.data.devices.length > 0) {  
            this.items = response.data.devices.map((device) => this.$devices.getDevice(device.device_guid, device))
            
            if (response.data.pagination_token) {
              this.$set(this.paginationTokens, paginationIndex, response.data.pagination_token)
            }
          }
          this.loading = false
        }).catch((error) => {
          console.log('AdminDeviceListing searchDevices error', error)
          this.error = true
          this.errorText = this.$helpers.parseError(error)
          this.loading = false
        })
      },
      
      manageDevice(item) {
        console.log('AdminDeviceListing manageDevice', item)
        this.$router.push('/devices/' + item.device_guid)
      }, 
      
    }
  }
</script>