<template>
  <div>
    <v-dialog
      v-if="showFullScreenView"
      v-model="showFullScreenView"
      fullscreen
      hide-overlay
    >
      <v-card
        flat
      >
        <v-card-title>
          {{ fleet.displayName() }} Multi-View
          <v-spacer />
          <v-btn icon small @click="showFullScreenView = false" class="mr-0 pl-1"><v-icon>close</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <v-row no-gutters>
            <v-card
              v-for="(input, index) in devicePreviews"
              v-bind:key="index"
              class="ma-1"
              flat
            >
              <v-card-title>
                <device-label :device="input.device" />
              </v-card-title>
              <v-card-text>
                <span class="caption">{{ inputName(input) }}</span>
                <input-preview :device_guid="input.device.device_guid" :input_id="input.id" :big="true" />
              </v-card-text>
            </v-card>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    
    <div class="float-right">
      <v-btn
        small
        icon
        @click="showFullScreenView = true"
        v-if="devicePreviews.length > 0"
      >
        <v-icon>tv</v-icon>
      </v-btn>
    </div>
    
    <v-row no-gutters v-if="!showFullScreenView">
      <v-col v-if="devicePreviews.length == 0" cols="12" class="mt-5 text-center">
        <v-alert text>
          No previews available...
        </v-alert>
      </v-col>
      
      <v-card
        v-for="(input, index) in devicePreviews"
        v-bind:key="index"
        class="ma-1"
        flat
      >
        <v-card-title>
          <device-label :device="input.device" />
        </v-card-title>
        <v-card-text>
          <span class="caption">{{ inputName(input) }}</span>
          <input-preview :device_guid="input.device.device_guid" :input_id="input.id" :big="true" />
        </v-card-text>
      </v-card>
    </v-row>
  </div>
</template>
<script>
  import { mapGetters } from 'vuex'

  import InputPreview from '../../device/components/InputPreview.vue'
  
  export default {
    name: 'FleetMultiview',
    
    props: ['fleet_guid', 'fleet'],
    
    components: {
      InputPreview
    },
    
    data() {
      return {
        loading: false,
        
        inputPreviewSupportedVersion: this.$helpers.parseVersion('cloud', 'v1.3.0'),
        
        showFullScreenView: false,
      }
    },
    computed: {
      ...mapGetters('user', ['user', 'guid', 'isOrgAdmin']),
      
      devicePreviews() {
        var previews = []
        
        for (var device of this.fleet.devices) {
          if (device.isOnline() && this.isPreviewSupported(device)) {
            var inputsShadow = this.$deviceShadows.getDeviceShadow(device.device_guid, 'Inputs')
            if (inputsShadow.reported) {
              var inputs = inputsShadow.editableStateCopy()
              
              for (var input of inputs) {
                if (input.video_input_format != 'FORMAT_UNDEFINED') {
                  input.device = device
                  previews.push(input)
                }
              }
            }
          }
        }
        
        return previews
      }
      
    },
    
    mounted() {
      
    },
    
    methods: {
      isPreviewSupported(device) {
        if (device.thumbnailEnabled() && this.$helpers.versionCheck(this.inputPreviewSupportedVersion, device.cloud_version)) {
          return true
        }
        return false
      },
      
      inputName(item) {
        if (item.config && item.config.name) {
          return this.$helpers.htmlDecode(item.config.name)
        }
        
        return 'Input #' + item.id
      },
    }
  }
</script>