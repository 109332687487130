<template>
  <v-form
    ref="form"
    v-model="valid"
    lazy-validation
  >
    <v-row><h5 class="text--disabled">Audio Encoder</h5></v-row>
    <v-row class="mb-3"><v-divider /></v-row>
    <v-row class="mb-5">
      <v-col cols="12">
        <v-text-field
          v-model="config.name"
          label="Encoder Name"
          @input="configChanged"
          :disabled="!canEdit"
          outlined
          hide-details
        ></v-text-field>
      </v-col>
    </v-row>
    
    <div v-if="isDynamicSupported">
      <v-row><h5 class="text--disabled">Input Selection</h5></v-row>
      <v-row class="mb-3"><v-divider /></v-row>
      <v-row class="mb-5">
        <v-col lg="12" cols="12">
          <input-selector :device_guid='device_guid' :canEdit="canEdit" v-model="config.in_channel_id" @change="configChanged" />
        </v-col>
      </v-row>
    </div>
    
    <v-row><h5 class="text--disabled">Encoder Configuration</h5></v-row>
    <v-row class="mb-3"><v-divider /></v-row>
    <v-row class="mb-3">
      <v-col cols="12">
        <v-select
          v-model="config.codec"
          :items="codecValues"
          label="Audio Encoding"
          @change="configChanged"
          :disabled="!canChangeCodec"
          outlined
          hide-details
        ></v-select>
      </v-col>
    </v-row>
    
    <v-row class="mb-3">
      <v-col cols="6">
        <v-select
          v-model="config.bitrate_mode"
          :items="bitrate_modeValues"
          label="Encoding Mode"
          @change="configChanged"
          :disabled="!canEdit"
          outlined
          hide-details
        ></v-select>
      </v-col>
      <v-col cols="6">
        <v-select
          v-model.number="config.bitrate"
          :items="bitrateValues"
          label="Audio Bitrate (kbps)"
          @change="configChanged"
          :disabled="!canEdit"
          outlined
          hide-details
        ></v-select>
      </v-col>
    </v-row>
    
    <v-row><h5 class="text--disabled">Audio Channel Selection</h5></v-row>
    <v-row class="mb-3"><v-divider /></v-row>
    
    <v-row>
      <v-col cols="6" lg="3">
        <v-checkbox
          v-model="config.selected_channels"
          label="Channel 1"
          :value="1"
          @change="configChanged"
          :disabled="!canEdit"
          :rules="channelSelectionValidation"
          multiple
          hide-details
          class="mt-0"
        ></v-checkbox>
      </v-col>
      <v-col cols="6" lg="3">
        <v-checkbox
          v-model="config.selected_channels"
          label="Channel 2"
          :value="2"
          @change="configChanged"
          :disabled="!canEdit"
          :rules="channelSelectionValidation"
          multiple
          hide-details
          class="mt-0"
        ></v-checkbox>
      </v-col>
      <v-col cols="6" lg="3">
        <v-checkbox
          v-model="config.selected_channels"
          label="Channel 3"
          :value="3"
          @change="configChanged"
          :disabled="!canEdit"
          :rules="channelSelectionValidation"
          multiple
          hide-details
          class="mt-0"
        ></v-checkbox>
      </v-col>
      <v-col cols="6" lg="3">
        <v-checkbox
          v-model="config.selected_channels"
          label="Channel 4"
          :value="4"
          @change="configChanged"
          :disabled="!canEdit"
          :rules="channelSelectionValidation"
          multiple
          hide-details
          class="mt-0"
        ></v-checkbox>
      </v-col>
      <v-col cols="6" lg="3">
        <v-checkbox
          v-model="config.selected_channels"
          label="Channel 5"
          :value="5"
          @change="configChanged"
          :disabled="!canEdit"
          :rules="channelSelectionValidation"
          multiple
          hide-details
          class="mt-0"
        ></v-checkbox>
      </v-col>
      <v-col cols="6" lg="3">
        <v-checkbox
          v-model="config.selected_channels"
          label="Channel 6"
          :value="6"
          @change="configChanged"
          :disabled="!canEdit"
          :rules="channelSelectionValidation"
          multiple
          hide-details
          class="mt-0"
        ></v-checkbox>
      </v-col>
      <v-col cols="6" lg="3">
        <v-checkbox
          v-model="config.selected_channels"
          label="Channel 7"
          :value="7"
          @change="configChanged"
          :disabled="!canEdit"
          :rules="channelSelectionValidation"
          multiple
          hide-details
          class="mt-0"
        ></v-checkbox>
      </v-col>
      <v-col cols="6" lg="3">
        <v-checkbox
          v-model="config.selected_channels"
          label="Channel 8"
          :value="8"
          @change="configChanged"
          :disabled="!canEdit"
          :rules="channelSelectionValidation"
          multiple
          hide-details="auto"
          class="mt-0"
        ></v-checkbox>
      </v-col>
    </v-row>
    
    <v-row v-if="device.product_name == 'edgecaster_max'">
      <v-col cols="6" lg="3">
        <v-checkbox
          v-model="config.selected_channels"
          label="Channel 9"
          :value="9"
          @change="configChanged"
          :disabled="!canEdit"
          :rules="channelSelectionValidation"
          multiple
          hide-details
          class="mt-0"
        ></v-checkbox>
      </v-col>
      <v-col cols="6" lg="3">
        <v-checkbox
          v-model="config.selected_channels"
          label="Channel 10"
          :value="10"
          @change="configChanged"
          :disabled="!canEdit"
          :rules="channelSelectionValidation"
          multiple
          hide-details
          class="mt-0"
        ></v-checkbox>
      </v-col>
      <v-col cols="6" lg="3">
        <v-checkbox
          v-model="config.selected_channels"
          label="Channel 11"
          :value="11"
          @change="configChanged"
          :disabled="!canEdit"
          :rules="channelSelectionValidation"
          multiple
          hide-details
          class="mt-0"
        ></v-checkbox>
      </v-col>
      <v-col cols="6" lg="3">
        <v-checkbox
          v-model="config.selected_channels"
          label="Channel 12"
          :value="12"
          @change="configChanged"
          :disabled="!canEdit"
          :rules="channelSelectionValidation"
          multiple
          hide-details
          class="mt-0"
        ></v-checkbox>
      </v-col>
      <v-col cols="6" lg="3">
        <v-checkbox
          v-model="config.selected_channels"
          label="Channel 13"
          :value="13"
          @change="configChanged"
          :disabled="!canEdit"
          :rules="channelSelectionValidation"
          multiple
          hide-details
          class="mt-0"
        ></v-checkbox>
      </v-col>
      <v-col cols="6" lg="3">
        <v-checkbox
          v-model="config.selected_channels"
          label="Channel 14"
          :value="14"
          @change="configChanged"
          :disabled="!canEdit"
          :rules="channelSelectionValidation"
          multiple
          hide-details
          class="mt-0"
        ></v-checkbox>
      </v-col>
      <v-col cols="6" lg="3">
        <v-checkbox
          v-model="config.selected_channels"
          label="Channel 15"
          :value="15"
          @change="configChanged"
          :disabled="!canEdit"
          :rules="channelSelectionValidation"
          multiple
          hide-details
          class="mt-0"
        ></v-checkbox>
      </v-col>
      <v-col cols="6" lg="3">
        <v-checkbox
          v-model="config.selected_channels"
          label="Channel 16"
          :value="16"
          @change="configChanged"
          :disabled="!canEdit"
          :rules="channelSelectionValidation"
          multiple
          hide-details="auto"
          class="mt-0"
        ></v-checkbox>
      </v-col>
    </v-row>
    
    <div class="mb-3">&nbsp;</div>
    
    <div v-if="id && !isNew && shadowDebug" class="mt-5">
      <v-row><h5 class="text--disabled">Encoder Details</h5></v-row>
      <v-row class="mb-3"><v-divider /></v-row>
      <v-row class="mb-3">
        <v-col cols="12">
          <pre style="inline-size: 640px; white-space: pre-wrap;">{{ encoder }}</pre>
        </v-col>
      </v-row>
    </div>
    
    <div v-if="id && !isNew && canEdit" class="text-right">
      <v-btn
        class="ma-1 d-none d-md-inline"
        x-small
        outlined
        @click="deleteEncoder()"
      >
        Delete Encoder
      </v-btn>
    </div>
  </v-form>
</template>
<script>
  import { mapGetters } from 'vuex'
  import InputSelector from '../components/InputSelector.vue'
  
  export default {
    name: 'AudioEncoderDocument',
    
    props: ['encoder', 'device_guid', 'canEdit', 'isNew'],
    
    components: {
      InputSelector
    },
    
    data() {
      return {
        opusDaemonVersion: this.$helpers.parseVersion('device', 'v10.1.0'),
        
        valid: true,
        
        id: false,
        seconds_in_running: 0,
        
        bitrate_modeValues: [
          { 'value': 'constant', 'text': 'Constant Bitrate' },
          { 'value': 'variable', 'text': 'Variable Bitrate' },
        ],
        
        config: {
          active: false,
          in_channel_id: -1,
          
          mix_mode: 'STEREO',
          sample: 'SAMPLE_48_khz',
          
          name: '',
          
          codec: 'mpeg4_aac',
          bitrate_mode: 'variable',
          bitrate: 128,
        
          selected_channels: [1, 2]
        },

        // validations
        channelSelectionValidation: [
          v => (v.length <= 6) || "Only 6 channels are allowed per Audio Profile"
        ]
      }
    },
    
    watch: {
      encoder() {
        this.updateValues()
      }
    },
    
    computed: {
      ...mapGetters('userPreferences', ['shadowDebug']),
      
      device() {
        return this.$devices.getDevice(this.device_guid)
      },
      
      isDynamicSupported() {
        return this.$versioning.isDynamicSupported(this.device)
      },
      
      canChangeCodec() {
        if (!this.canEdit || (this.isDynamicSupported && !this.isNew)) {
          return false
        }
        return true
      },
      
      codecValues() {
        var codecValues = [
          { 'value': 'mpeg4_aac', 'text': 'MPEG-4 AAC-LC' },
        ]
        
        if (this.$helpers.versionCheck(this.opusDaemonVersion, this.device.daemon_version)) {
          codecValues.push({ 'value': 'opus', 'text': 'Opus' })
        }
        
        return codecValues
      },
      
      bitrateValues() {
        var bitrateValues = [
          { 'value': 64, 'text': '64' },
          { 'value': 96, 'text': '96' },
          { 'value': 128, 'text': '128' },
          { 'value': 192, 'text': '192' },
          { 'value': 256, 'text': '256' },
          { 'value': 320, 'text': '320' },
          { 'value': 384, 'text': '384' },
          { 'value': 512, 'text': '512' }
        ]
        
        if (this.config.codec == 'mpeg4_aac') {
          bitrateValues.push({ 'value': 576, 'text': '576' })
        }
        
        return bitrateValues
      }
    },
    
    mounted() {
      this.updateValues()
    },
    
    methods: {
      configChanged() {
        var validationErrors = false
        
        // check for validation errors
        if (this.config.selected_channels.length > 6) {
          console.log('AudioEncoderDocument ' + this.device_guid + ' validation error: Only 6 channels are allowed per Audio Profile', this.config)
          validationErrors = true
        }
        
        console.log('AudioEncoderDocument ' + this.device_guid + ' configChanged', this.config)
        this.$emit('configUpdate', this.config, validationErrors)
      },
      
      updateValues() {
        if (this.encoder && this.encoder.config) {
          Object.keys(this.encoder).map((key) => {
            this[key] = this.encoder[key]
          })
          
          // htmlDecode name properties
          if (this.config.name) {
            this.config.name = this.$helpers.htmlDecode(this.config.name)
          }
        }
        
        this.configChanged()
      },
      
      deleteEncoder() {
        console.log('AudioEncoderDocument ' + this.device_guid + ' deleteEncoder')
        this.$emit('deleteEncoder')
      }
    }
  }
</script>