<template>
  <v-card flat>
    <v-dialog
      v-model="showDeleteInput"
      max-width="480px"
    >
      <videon-card heading="Delete Input" showClose="true" @close="showDeleteInput = false">
        <v-card-text>
          <v-alert
            type="info"
            text
            icon="mdi-lock-outline"
            class="mt-3"
            v-if="!isDynamicSupported"
          >
            Adding and removing inputs dynamically requires a minimum of daemon version {{ $versioning.dynamicSupportRequirements.minimumDaemonVersion.original }} 
            and cloud agent version {{ $versioning.dynamicSupportRequirements.minimumAgentVersion.original }}.
          </v-alert>
          <v-form v-else @submit.prevent="confirmDeleteInput">
            <p class="subtitle-1 mt-3">
              Are you sure you want to delete this input?
            </p>
            <div class="text-right">
              <v-btn
                class="ma-1"
                color="secondary"
                @click="showDeleteInput = false"
                text
              >
                Cancel
              </v-btn>
              <v-btn
                class="ma-1"
                color="primary"
                @click="confirmDeleteInput"
              >
                Delete
              </v-btn>
            </div>
          </v-form>
        </v-card-text>
      </videon-card>
    </v-dialog>
    
    <v-dialog
      v-model="showNewInput"
      max-width="640px"
    >
      <videon-card heading="Add New Input" showClose="true" @close="showNewInput = false">
        <v-card-text>
          <v-alert
            type="info"
            text
            icon="mdi-lock-outline"
            class="mt-3"
            v-if="!isDynamicSupported"
          >
            Adding and removing inputs dynamically requires newer software. Please update to at least daemon version <strong>{{ $versioning.dynamicSupportRequirements.minimumDaemonVersion.original }}</strong>
            and cloud agent version <strong>{{ $versioning.dynamicSupportRequirements.minimumAgentVersion.original }}</strong>.
          </v-alert>
          
          <v-form v-else @submit.prevent="createInput">
            <v-row class="mb-3">
              <v-col cols="8">
                <v-select
                  v-model="newInput.type"
                  :items="newInputTypes"
                  @change="newInputTypeChange"
                  label="Input Type"
                  outlined
                  hide-details
                ></v-select>
              </v-col>
              <v-col cols="4">
                <v-select
                  v-if="newInput.type == 'hardware'"
                  v-model="newInput.hw_channel"
                  :items="hw_channelValues"
                  label="Hardware Channel"
                  @change="newInputTypeChange"
                  outlined
                  hide-details
                ></v-select>
              </v-col>
            </v-row>
            
            <div class="mt-5" v-if="newInput">
              <inputs-document :input="newInput" :device_guid="device_guid" :canEdit="canEdit" :isNew="true" @configUpdate="newInputUpdate" :key="newInput.id"/>
            </div>
            
            <div class="text-right">
              <v-btn
                class="ma-1"
                color="secondary"
                @click="showNewInput = false"
                text
              >
                Cancel
              </v-btn>
              <v-btn
                class="ma-1"
                color="primary"
                @click="createInput"
                :disabled="newInputHasValidationErrors"
              >
                Create Input
              </v-btn>
            </div>
          </v-form>
        </v-card-text>
      </videon-card>
    </v-dialog>
    
    
    <v-card-title>
      Input Configuration
      <v-spacer/>
      <v-btn
        small
        color="primary"
        tooltip="New Input"
        @click="newInputShow()"
        :disabled="!canEdit"
      >
        <v-icon>mdi-plus</v-icon> New Input
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-alert type="info" v-if="xmlConfig">These settings are currently managed by the active XML configuration.</v-alert>
      
      <v-data-table
        :headers="headers"
        :items="documents"
        :single-expand="true"
        :expanded.sync="expanded"
        item-key="id"
        :items-per-page="100"
        show-expand
        hide-default-footer
        @click:row="(item, slot) => slot.expand(!slot.isExpanded)"
      >
        <template
          v-slot:item.id="{ item }"
        >
          <span :class="(item.new) ? 'text--disabled' : ''">{{ inputName(item) }}</span>
        </template>
        <template
          v-slot:item.type="{ item }"
        >
          <span :class="(item.new) ? 'text--disabled' : ''">{{ inputType(item) }}</span>
        </template>
        <template
          v-slot:item.status="{ item }"
        >
          <span v-if="item.new" class="text--disabled">Not Created</span>
          <input-status-label v-else :input="item" />
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length" class="px-8 pt-8 pb-5">
            <inputs-document :input="item" :device_guid="device_guid" :canEdit="canEdit" @configUpdate="configUpdate" @deleteInput="deleteInput(item)" :key="item.id"/>
          </td>
        </template>
      </v-data-table>
      
      <div class="mt-5 text-right" v-if="canEdit">
        <v-btn
          color="secondary"
          text
          class="ma-1"
          @click="revertShadow()"
          :disabled="!hasChanges"
        >
          Revert
        </v-btn>
        <v-btn
          color="primary"
          class="ma-1"
          @click="saveShadow()"
          :disabled="hasChanges == false || hasValidationError == true"
        >
          Save
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
  import InputsDocument from './documents/InputsDocument.vue'
  
  export default {
    name: 'EdgecasterInputs',
    
    components: {
      InputsDocument
    },
    
    props: ['deviceShadow', 'device_guid'],
    
    data() {
      return {
        hw_channelValues: [
          { 'value': 0, 'text': 'Channel 0' },
          { 'value': 1, 'text': 'Channel 1' },
        ],
        
        expanded: [],
        
        documents: [],
        hasChanges: false,
        expectingChanges: false,
        
        hasValidationError: false,
        
        showDeleteInput: false,
        selectedInput: false,
        
        showNewInput: false,
        newInputTypes: [
          { 'id': false, 'value': 'hardware', 'text': 'Hardware' },
        ],
        newInput:false,
        newInputHasValidationErrors: false,
        
        headers: [
          { text: 'Name', align: 'start', value: 'id', sortable: true },
          { text: 'Input Type', align: 'start text-capitalize', value: 'type', sortable: true },
          { text: 'Input Status', align: 'start', value: 'status', sortable: true },
        ],
        
        inputs: []
      }
    },
    watch: {
      deviceShadow: {
        handler() {
          // only overwrite if we dont have any documents to start with
          if (this.expectingChanges || this.expanded.length == 0 || this.documents.length == 0) {
            var documents = this.deviceShadow.editableStateCopy()
            if (documents) {
              this.documents = documents
              
              // reset expectingChanges
              setTimeout(() => {
                this.expectingChanges = false
              }, 5000)
            }
          }
        }, 
        deep: true
      }
    },
    computed: {
      device() {
        return this.$devices.getDevice(this.device_guid)
      },
      canEdit() {
        if (this.xmlConfig || this.deviceShadow.updatePending) {
          return false
        }
        return this.$devices.getDevice(this.device_guid).canEdit()
      },
      xmlConfig() {
        return this.$devices.getDevice(this.device_guid).hasXMLConfig()
      },
      isDynamicSupported() {
        return this.$versioning.isDynamicSupported(this.device)
      }
    },
    
    mounted() {
      var documents = this.deviceShadow.editableStateCopy()
      if (documents) {
        this.documents = documents
        this.hasChanges = false
      }
    },
    
    methods: {
      inputName(item) {
        if (item.config && item.config.name) {
          return this.$helpers.htmlDecode(item.config.name)
        } else if (item.new) {
          return 'New Input'
        }
        return 'Input #' + item.id
      },
      inputType(item) {
        if (item.type) {
          return this.$helpers.getLabel(item.type)
        }
        return 'Hardware'
      },
      
      newInputShow() {
        this.newInput =  {  'id': 'new-' +  new Date().getTime(), 'type': 'hardware', 'hw_channel': 0, 'new': true }
        this.showNewInput = true
      },
      
      newInputTypeChange() {
        console.log('EdgecasterInputs newInputTypeChange', this.newInput)
        this.$delete(this.newInput, 'config')
      },
      
      newInputUpdate(config, hasValidationError = false) {
        console.log('EdgecasterInputs newInputUpdate', config, hasValidationError)
        this.newInput.config = config
        this.newInputHasValidationErrors = hasValidationError
      },
      
      createInput() {
        if (!this.newInputHasValidationErrors) {
          // fix for transient vars
          if (!this.newInput.config.name) {
            this.newInput.config.name = ''
          }
          
          if (!this.newInput.config.sdi_audio_ignore_phase) {
            this.newInput.config.sdi_audio_ignore_phase = false
          }
          
          console.log('EdgecasterInputs createInput', this.newInput)
          
          this.documents.push(Object.assign({}, this.newInput))
          this.hasChanges = true
          this.expanded = []
          
          this.showNewInput = false
        }
      },
      
      deleteInput(item) {
        this.selectedInput = item
        this.showDeleteInput = true
      },
      confirmDeleteInput() {
        var inputIndex = this.documents.indexOf(this.selectedInput)
        if (inputIndex !== -1) {
          console.log('EdgecasterInputs confirmDeleteInput deleting input', this.selectedInput)
          
          this.documents.splice(inputIndex, 1)
          this.hasChanges = true
          this.expanded = []
        }
        this.showDeleteInput = false
      },
      
      
      
      configUpdate(config, hasValidationError = false) {
        this.hasChanges = true
        this.hasValidationError = hasValidationError
      },
      
      saveShadow() {
        var state = this.documents.map((input) => {
          var newInput = {'id': (input.new) ? false : input.id, 'type': input.type, 'config': input.config}
          
          if (input.hw_channel !== undefined) {
            newInput.hw_channel = input.hw_channel
          }
          
          return newInput
        })
        
        this.deviceShadow.saveShadow(state)
        this.expanded = []
        this.hasChanges = false
        this.expectingChanges = true
      },
      
      revertShadow() {
        this.documents = this.deviceShadow.editableStateCopy()
        this.hasChanges = false
      }
    }
  }
</script>