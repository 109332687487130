<template>
  <v-card flat>
    <v-card-title v-if="heading">{{ heading }}<v-spacer/><v-btn @click="refresh" text small><v-icon small>refresh</v-icon> refresh</v-btn></v-card-title>
    <v-card-text>
      <v-alert dense outlined type="error" v-if="error">
        {{ errorText }}
      </v-alert>
      <v-data-table
        v-if="!error"
        :headers="headers"
        :items="items"
        :loading="loading"
        :page.sync="page"
        :items-per-page="itemsPerPage"
        mobile-breakpoint="0"
        hide-default-footer
      >
        <template v-slot:item.device_guid="{ item }">
          <span class="text-no-wrap">
            <device-label :device="$devices.getDevice(item.device_guid)" :hide-state="true" />
          </span>
        </template>
        <template v-slot:item.timestamp="{ item }">
          <span class="text-no-wrap">
            {{ item.timestamp | moment("YYYY/MM/DD hh:mm:ss a") }}
          </span>
        </template>
        <template v-slot:item.event_type="{ item }">
          <span class="text-no-wrap font-weight-medium">
            {{ item.event_data.message }}
          </span>
        </template>
        <template v-slot:item.event_data="{ item }">
          <div class="text-left">
            <docker-state-change-diff v-if="item.event_type == 'state' && isDockerState(item)" :event-data="item.event_data" />
            <state-change-diff v-else-if="item.event_type == 'state'" :event-data="item.event_data" />
            <shadow-change-diff v-else-if="item.event_type == 'shadow'" :event-data="item.event_data" :device="$devices.getDevice(item.device_guid)" />
            <alert-event v-else-if="item.event_type == 'alert' || !item.event_type" :event-data="item.event_data" />
            <agent-event v-else-if="item.event_type == 'agent'" :event-data="item.event_data" />
            <div v-else>{{item.event_type}}: {{ item.event_data }}</div>
          </div>
        </template>
      </v-data-table>
      <data-table-pagination v-if="!disablePagination" v-model="page" :paginationTokens="paginationTokens" />
    </v-card-text>
  </v-card>
</template>

<script> 
  import moment from 'moment'

  import StateChangeDiff from '../../common/events/StateChangeDiff.vue'
  import ShadowChangeDiff from '../../common/events/ShadowChangeDiff.vue'
  import DockerStateChangeDiff from '../../common/events/DockerStateChangeDiff.vue'
  import AlertEvent from '../../common/events/Alert.vue'
  import AgentEvent from '../../common/events/Agent.vue'
  
  export default {
    name: 'DeviceEvents',
    
    components: {
      StateChangeDiff,
      ShadowChangeDiff,
      DockerStateChangeDiff,
      AlertEvent,
      AgentEvent
    },
    props: [
      'heading', 
      'showDevice', 
      'eventLimit',
      'targetObject',
      'targetGUID',
      'startTime',
      'endTime',
      'eventType'
    ],
    
    data() {
      return {
        error: false,
        errorText: '',
        
        loading: false,
        
        items: [],
        
        itemsPerPage: 25,
        
        disablePagination: false,
        page: 1,
        paginationTokens: []
      }
    },
    computed: {
      headers() {
        var headers = []
        
        if (this.showDevice) {
          headers.push({ text: 'Device', align: 'start', value: 'device_guid', sortable: false })
        }
        
        headers.push({ text: 'Time', align: 'start', value: 'timestamp', sortable: false })
        headers.push({ text: 'Event', align: 'center d-none d-lg-table-cell', value: 'event_type', sortable: false })
        headers.push({ text: 'Event Description', align: 'start', value: 'event_data', sortable: false })
        
        return headers
      }
    },
    watch: {
      page() {
        if (!this.loading) {
          this.searchEvents(this.page - 1)
          this.$emit('pageChange', this.page)
        }
      },
      startTime() {
        if (!this.loading) {
          this.searchEvents(this.page - 1)
        }
      },
      endTime() {
        if (!this.loading) {
          this.searchEvents(this.page - 1)
        }
      },
      eventType() {
        if (!this.loading) {
          this.searchEvents(this.page - 1)
        }
      },
    },
    mounted() {
      if (this.eventLimit) {
        this.itemsPerPage = parseInt(this.eventLimit)
        this.disablePagination = true
      }
      
      this.searchEvents(0)
    },
    
    methods: {
      requestURL() {
        // TODO maybe this needs to become its own thing.. but for now, lets look at eventType == alert & targetObject == orgs
        if (this.eventType == 'alert' && this.targetObject == 'orgs') {
          return '/alerts/history'
          
        // standard behavior
        } else if (this.targetObject == 'devices') {
          return '/devices/' + this.targetGUID + '/events'
        } else if (this.targetObject == 'fleets') {
          return '/fleets/' + this.targetGUID + '/events'
        }
        
        return '/orgs/' + this.targetGUID + '/events'
      },
      
      refresh() {
        if (this.page != 1) {
          this.page = 1
        } else {
          this.page = 1
          this.searchEvents(0)
        }
      },
      
      searchEvents(paginationIndex) {
        if (!this.targetGUID) {
          return
        }
        
        if (this.targetObject == 'orgs' && !this.startTime) {
          return
        }
        
        this.error = false
        this.errorText = ''
        this.loading = true
        this.items = []
        
        const paginationToken = this.paginationTokens[paginationIndex - 1]
        
        var queryParams = {
          'pagination_size': this.itemsPerPage
        }
        
        if (this.endTime) {
          queryParams.end_time = this.endTime
        }
        
        if (this.startTime) {
          if (!this.endTime) {
            queryParams.end_time = moment().toISOString()
          }
          queryParams.start_time = this.startTime
        }
        
        if (paginationToken) {
          queryParams.pagination_token = paginationToken
        }
        
        this.axios.get(this.requestURL(), {'params': queryParams}).then((response) => {
          console.log('DeviceEvents searchEvents response', response)
          
          if (response.data.events && response.data.events.length > 0) {
            this.items = response.data.events
            
            if (response.data.pagination_token) {
              this.$set(this.paginationTokens, paginationIndex, response.data.pagination_token)
            }
          } else if (response.data.alerts && response.data.alerts.length > 0) {
            this.items = response.data.alerts
            
            if (response.data.pagination_token) {
              this.$set(this.paginationTokens, paginationIndex, response.data.pagination_token)
            }
          }
          
          this.$emit('loaded')
          
          this.loading = false
        }).catch((error) => {
          this.error = true
          this.errorText = this.$helpers.parseError(error)
          this.loading = false
        })
      },
      
      isDockerState(item) {
        if (item.event_data.message.includes('Docker')) {
          return true
        } else if (item.event_data.message.includes('Initial') && item.event_data.to_state.containers) {
          return true
        }
        return false
      }
    }
  }
</script>