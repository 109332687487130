<template>
  <v-form
    ref="form"
    v-model="valid"
    lazy-validation
  >
    <v-row><h5 class="text--disabled">RTMP Output</h5></v-row>
    <v-row class="mb-3"><v-divider /></v-row>
    <v-row class="mb-3">
      <v-col cols="12">
        <v-checkbox
          v-model="config.enable"
          label="Output Enabled"
          @change="configChanged"
          :disabled="!canEdit"
          hide-details
          class="mt-0"
        ></v-checkbox>
      </v-col>
    </v-row>
    <v-row v-if="config.name !== undefined || !id" class="mb-3">
      <v-col cols="12">
        <v-text-field
          v-model="config.name"
          label="Output Name"
          @input="configChanged"
          :disabled="!canEdit"
          outlined
          hide-details
        ></v-text-field>
      </v-col>
    </v-row>
    
    <v-row><h5 class="text--disabled">Encoder Selection</h5></v-row>
    <v-row class="mb-3"><v-divider /></v-row>
    <v-row class="mb-3">
      <v-col lg="6" cols="12">
        <encoder-selector :device_guid='device_guid' type="video" :canEdit="canEdit" v-model="config.sources.video" @change="configChanged" :required="config.enable" :rules="videoEncoderValidation" />
      </v-col>
      <v-col lg="6" cols="12">
        <encoder-selector :device_guid='device_guid' type="audio" :canEdit="canEdit" v-model="config.sources.audio" :filter="supportedAudioEncoders" @change="configChanged"/>
      </v-col>
    </v-row>
    
    <v-row><h5 class="text--disabled">Output Target</h5></v-row>
    <v-row class="mb-3"><v-divider /></v-row>
    <v-row class="mb-3">
      <v-col cols="12">
        <v-select
          v-model="config.service_name"
          :items="service_nameValues"
          label="Streaming Provider"
          @change="configChanged"
          :disabled="!canEdit"
          outlined
          hide-details
        ></v-select>
      </v-col>
    </v-row>
    <div v-if="config.service_name == 'generic'">
      <v-row class="mb-3">
        <v-col cols="12">
          <v-text-field
            v-model="parsedServiceData.url"
            label="Stream URL"
            @input="configChanged"
            :disabled="!canEdit"
            outlined
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>
    </div>
    <div v-if="config.service_name != 'generic'">
      <v-row>
        <v-col cols="12">
          <v-alert
            type="info"
            text
          >
            This Streaming Provider must be managed directly on the device at this time.
          </v-alert>
        </v-col>
      </v-row>
    </div>
    
    <div v-if="id && !isNew && shadowDebug" class="mt-5">
      <v-row><h5 class="text--disabled">Output Details</h5></v-row>
      <v-row class="mb-3"><v-divider /></v-row>
      <v-row class="mb-3">
        <v-col cols="12">
          <pre style="inline-size: 640px; white-space: pre-wrap;">{{ output }}</pre>
        </v-col>
      </v-row>
    </div>
    
    <div v-if="id && !isNew && canEdit" class="text-right">
      <v-btn
        class="ma-1 d-none d-md-inline"
        x-small
        outlined
        @click="deleteOutput()"
      >
        Delete Output
      </v-btn>
    </div>
  </v-form>
</template>
<script>
  import { mapGetters } from 'vuex'
  import ShadowMixins from '../mixins/mixins.js'
  import EncoderSelector from '../components/EncoderSelector.vue'
  
  export default {
    name: 'RTMPOutputDocument',
    
    props: ['output', 'device_guid', 'canEdit', 'isNew'],
    
    components: {
      EncoderSelector
    },
    
    mixins:[ShadowMixins],
    
    data() {
      return {
        valid: true,
        
        id: false,
        type: false,
        
        service_nameValues: [
          { 'value': 'generic', 'text': 'Generic RTMP' },
          { 'value': 'akamai', 'text': 'Akamai' },
          { 'value': 'aws_ivs', 'text': 'Amazon IVS' },
          { 'value': 'wowza', 'text': 'Wowza Streaming Engine' },
          { 'value': 'wowza_cloud', 'text': 'Wowza Cloud' },
          { 'value': 'youtube', 'text': 'YouTube' },
          { 'value': 'facebook', 'text': 'Facebook' },
          { 'value': 'mux', 'text': 'Mux' },
        ],
        
        parsedServiceData: {'url': ''},
        
        supportedAudioEncoders: [
          'mpeg4_aac'
        ],
        
        config: {
          enable: false,
          
          service_name: 'generic',
          service_data: '',
          
          sources: {
            audio: [],
            video: []
          }
        }
      }
    },
    
    watch: {
      output() {
        this.updateValues()
      }
    },
    
    computed: {
      ...mapGetters('userPreferences', ['shadowDebug']),
    },
    
    mounted() {
      this.updateValues()
    },
    
    methods: {
      configChanged() {
        // update the config.data string
        if (this.parsedServiceData && this.parsedServiceData.url) {
          this.config.service_data = JSON.stringify(this.parsedServiceData)
        } else if (this.config.service_name == 'generic' && !this.parsedServiceData.url) {
          this.config.service_data = '{}'
        }
        
        var validationErrors = false
        
        if (this.config.enable == true) {
          if (this.config.sources && this.config.sources.video.length == 0) {
            console.log('RTMPOutputDocument ' + this.device_guid + ' validation error: Video encoder must be specified', this.config)
            validationErrors = true
          }
          this.$refs.form.validate()
        } else {
          this.$refs.form.resetValidation()
        }
        
        console.log('RTMPOutputDocument ' + this.device_guid + ' configChanged', this.config)
        this.$emit('configUpdate', this.config, validationErrors)
      },
      
      updateValues() {
        if (this.output && this.output.config) {
          Object.keys(this.output).map((key) => {
            this[key] = this.output[key]
          })
          
          // parse data in to an actual object for use...
          if (this.config.service_data) {
            this.parsedServiceData = JSON.parse(this.config.service_data)
          }
          
          // htmlDecode name properties
          if (this.config.name !== undefined) {
            this.config.name = this.$helpers.htmlDecode(this.config.name)
          }
        }
        
        this.configChanged()
      },
      
      deleteOutput() {
        console.log('RTMPOutputDocument ' + this.device_guid + ' deleteOutput')
        this.$emit('deleteOutput')
      }
    }
  }
</script>