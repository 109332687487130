<template>
  <v-container>
    <v-row class="mx-2 my-1" align="end">
      <v-text-field
        class="mx-5"
        v-model="search"
        append-icon="mdi-magnify"
        label="Search by Device Serial"
        hide-details
      ></v-text-field>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-data-table
          v-model="selection"
          item-key="device_guid"
          show-select
          :headers="headers"
          :items="items"
          :items-per-page="itemsPerPage"
          :loading="loading"
          hide-default-footer
        >
          <template v-slot:item.state.device_name="{ item }">
            <device-label :device="item" :clickable="false" :disabled="!item.isSelectable"/>
          </template>
          <template v-slot:item.streaming_status="{ item }">
            <streaming-status-label :device="item" />
          </template>
          <template v-slot:item.firmware_version="{ item }">
            <firmware-version-label :device="item" />
          </template>
        </v-data-table>
        <data-table-pagination v-model="page" :paginationTokens="paginationTokens" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapGetters } from 'vuex'
  
  export default {
    name: 'DeviceSelector',
    
    data() {
      return {
        error: false,
        errorText: '',
      
        loading: false,
      
        search: '',
        awaitingSearch: false,
      
        items: [],
        selection: [],
        
        limitedSelection: [],
      
        headers: [
          { text: 'Device', align: 'start', value: 'state.device_name', sortable: true },
          { text: 'Status', align: 'center d-none d-md-table-cell', value: 'streaming_status', sortable: true},
          { text: 'Serial', align: 'center', value: 'serial_number', sortable: true },
          { text: 'MAC Address', align: 'center d-none d-md-table-cell', value: 'mac_address', sortable: true },
        ],
      
        itemsPerPage: 50,
        page: 1,
        pageCount: 0,
        paginationTokens: []
      }
    },
    computed: {
      ...mapGetters('user', ['user', 'organization']),
    },
    props: {
      value: {
        type: Array,
        required: true
      },
      existing: {
        type: Array,
        required: false,
        default: () => { return [] }
      },
      disableExisting: {
        type: Boolean,
        required: false
      },
      selectionLimit: {
        type: Number,
        required: false
      },
      productNames: {
        type: Array,
        required: false
      }
    },
    watch: {
      value() {
        this.selection = this.value
        this.checkSelectionLimit()
      },
      page() {
        this.searchDevices(this.page - 1)
      },
      itemsPerPage() {
        this.items = []
        this.page = 1
        this.searchDevices(this.page - 1)
      },
      selection() {
        this.selectionChanged()
      },
      existing() {
        this.searchDevices(0)
      },
      search() {
        if (!this.awaitingSearch) {
          setTimeout(() => {
            this.items = []
            this.page = 1
            this.searchDevices(0)
            this.awaitingSearch = false
          }, 2000)
        }
        this.awaitingSearch = true
      }
    },
    mounted() {
      if (this.value) {
        this.selection = this.value
      }
      
      this.searchDevices(0)
    },
    beforeDestroy() {
      console.log('DeviceSelector cleaning selection properties')
      this.items.map((dev) => {
        var device = this.$devices.getDevice(dev.device_guid)
        device.isSelectable = true
        device.selected = false
        return device
      })
      this.items = []
    },
    methods: {
      searchDevices(paginationIndex) {
        this.loading = true
        this.items = []
        
        const paginationToken = this.paginationTokens[paginationIndex - 1]
        
        var queryParams = {
          'org_guid': this.organization.org_guid,
          'pagination_size': this.itemsPerPage
        }
        
        if (this.search) {
          queryParams.serial_number = this.search
        }

        if (this.productNames) {
          queryParams.product_names = this.productNames
        }
        
        if (paginationToken) {
          queryParams.pagination_token = paginationToken
        }

        this.axios.get('/devices', {
          'params': queryParams,
          paramsSerializer: {
            indexes: null
          }
        }).then((response) => {
          console.log('DeviceSelector searchDevices response', response)
          
          if (response.data.devices.length > 0) {
            /*
            // HACK.. if we dont have a product_name use edgecaster
            response.data.devices.map((dev) => {
              if (!dev.product_name) {
                dev.product_name = 'edgecaster'
              }
              return dev
            }) 
            */
            this.items = response.data.devices.map((dev) => {
              var device = this.$devices.getDevice(dev.device_guid)
              
              var existingDevice = this.existing.find(x => x.device_guid === device.device_guid)
              if (existingDevice) {
                device.isSelectable = false
              } else {
                device.isSelectable = true
              }
              
              var selectedDevice = this.selection.find(x => x.device_guid === device.device_guid)
              if (selectedDevice) {
                device.selected = true
              }
              
              return device
            })
            
            if (response.data.pagination_token) {
              this.$set(this.paginationTokens, paginationIndex, response.data.pagination_token)
            }
          }
          
          this.loading = false
        }).catch((error) => {
          this.error = true
          this.errorText = this.$helpers.parseError(error)
          this.loading = false
        })
      },
      
      selectionChanged() {
        this.$emit('input', this.selection)
      },
      
      checkSelectionLimit() {
        if (this.selectionLimit > 0 && this.selection.length >= this.selectionLimit) {
          console.log('DeviceSelector checkSelectionLimit limit hit (' + this.selection.length + '/' + this.selectionLimit + ')')
          
          for (var i in this.items) {
            if (this.items[i].isSelectable && !this.selection.includes(this.items[i])) {
              this.limitedSelection.push(this.items[i])
              this.items[i].isSelectable = false
            }
          }
        } else if (this.limitedSelection.length > 0) {
          console.log('DeviceSelector checkSelectionLimit resetting limit')
          for (var x in this.limitedSelection) {
            for (var y in this.items) {
              if (this.limitedSelection[x].device_guid == this.items[y].device_guid) {
                this.items[y].isSelectable = true
              }
            }
          }
        }
      }
    } 
  }
</script>