<template>
  <div>
    <v-alert outlined dense rounded class="state-chip ma-1" v-for="(change, index) in stateChanges" v-bind:key="index">
      <span class="text-capitalize font-weight-medium">{{ nameLabel(change.key) }}</span>:&nbsp;
      <span v-if="$helpers.isTimestamp(change.key)">{{ change.from | moment("YYYY/MM/DD hh:mm:ss a") }} <v-icon x-small>mdi-arrow-right</v-icon>{{ change.to | moment("YYYY/MM/DD hh:mm:ss a") }}</span>
      <span v-else-if="!change.array">{{ parseValue(change.from) }} <v-icon x-small>mdi-arrow-right</v-icon>{{ parseValue(change.to) }}</span>
      <span v-else-if="change.array.length == 0" class="font-weight-regular text--disabled">[ No Visible Changes ]</span>
      <span v-else>
        <span v-for="(arrayChange, index) in change.array" v-bind:key="index">
          <span v-if="!arrayChange.array">{{ parseValue(arrayChange.from) }} <v-icon x-small>mdi-arrow-right</v-icon>{{ parseValue(arrayChange.to) }}&nbsp;</span>
          <span v-else-if="arrayChange.array.length == 0" class="font-weight-regular text--disabled">[ No Visible Changes ]&nbsp;</span>
          <span v-else>
            [&nbsp;
            <span v-if="arrayChange.unchanged_state">
              <span v-for="(unchangedValue, unchangedKey) in arrayChange.unchanged_state" v-bind:key="unchangedKey">
                <span class="text-capitalize font-weight-medium">{{ nameLabel(unchangedKey) }}</span>:
                <span>{{ unchangedValue }}&nbsp;</span>
              </span>
            </span>
            <span v-for="(subArrayChange, index) in arrayChange.array" v-bind:key="index">
              <span class="text-capitalize font-weight-medium">{{ nameLabel(subArrayChange.key) }}</span>:
              <span v-if="!subArrayChange.array">{{ parseValue(subArrayChange.from) }} <v-icon x-small>mdi-arrow-right</v-icon>{{ parseValue(subArrayChange.to) }}&nbsp;</span>
              <span v-else-if="subArrayChange.array.length == 0" class="font-weight-regular text--disabled">[ No Visible Changes ]&nbsp;</span>
              <span v-else class="font-weight-regular text--disabled">
                [ Has Changes ]&nbsp;
              </span>
            </span>]&nbsp;
          </span>
        </span>
      </span>
    </v-alert>
  </div>
</template>

<script>
export default {
    name: 'StateChangeDiff',
    props: {
      eventData: {
        type: Object,
        default: () => {},
      },
    },
    computed: {
      stateChanges() {
        return this.$helpers.stateDiff(this.eventData.from_state, this.eventData.to_state)
      }
    },
    methods: {
      nameLabel(stateName) {
        return stateName.replace(/_/g, ' ')
      },
      
      parseValue(value) {
        if (value === undefined) {
          return 'undefined'
        } else if (typeof(value) == 'object') {
          return 'Object (' + JSON.stringify(value) + ')'
        }
        return this.$helpers.getLabel(value)
      },

    }
  }
</script>
<style lang="scss" scoped>
  .state-chip {
     font-size: 12px;
     line-height: 20px;
     padding: 3px 12px;
     min-height: 24px;
     align-items: center;
     display: inline-flex;
  }
  .theme--light.state-chip {
      border-color: rgba(0, 0, 0, 0.12) !important;
      color: rgba(0, 0, 0, 0.87) !important;
  }
  .theme--dark.state-chip {
    border-color: rgba(255, 255, 255, 0.12) !important;
    color: #FFFFFF !important;
  }
</style>