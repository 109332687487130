<template>
  <v-card 
    class="mx-auto"
  > 
    <v-dialog
      v-model="showDeleteUser"
      max-width="400px"
    >
      <videon-card heading="Delete User" showClose="true" @close="showDeleteUser = false">
        <v-card-text>
          <p class="subtitle-1 mt-3">
            Are you sure you want to delete <span class="primary--text text-no-wrap">{{ selectedUser.name }}</span>?
          </p>
          <v-form @submit.prevent="confirmDeleteUser">
            <v-text-field
              v-model="deleteUserReason"
              label="Reason"
              required
              outlined
            ></v-text-field>
            <div class="text-right">
              <v-btn
                class="ma-1"
                color="secondary"
                @click="showDeleteUser = false"
                text
              >
                Cancel
              </v-btn>
              <v-btn
                class="ma-1"
                color="primary"
                @click="confirmDeleteUser"
              >
                Delete
              </v-btn>
            </div>
          </v-form>
        </v-card-text>
      </videon-card>
    </v-dialog>
  
    <v-toolbar flat>
      <v-toolbar-title>
        <v-avatar
          height="32px"
          class="mx-auto"
        >
          <videon-logo />
        </v-avatar>
        Users
      </v-toolbar-title>
      <v-spacer></v-spacer>
      
    </v-toolbar>
    
    <v-divider/>
    
    <v-card-text>
      <v-alert dense outlined type="error" v-if="error" class="ma-2">
        {{ errorText }}
      </v-alert>
    
      <v-container>
        <v-row class="mx-2 my-1" align="end">
          <v-select
            style="width: 10%; min-width: 100px;"
            v-model="searchBy"
            hide-details
            :items="[{'text': 'Name', 'value': 'name'}, {'text': 'Email', 'value': 'email'}, {'text': 'Phone', 'value': 'phone_number'}]"
          ></v-select>
          <v-text-field
            class="mx-5"
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            hide-details
          ></v-text-field>
        </v-row>
    
        <v-row>
          <v-col cols="12">
            <v-data-table
              :headers="headers"
              :items="items"
              :loading="loading"
              :page.sync="page"
              :server-items-length="totalItems"
              :items-per-page="itemsPerPage"
              sort-by="name"
              hide-default-footer
            >
              <template v-slot:item.name="{ item }">
                <span class="text-no-wrap" @click="editItem(item)" style="cursor: pointer;">
                  <user-label :user="item"/>
                </span>
              </template>
              <template v-slot:item.email="{ item }">
                  <v-icon class="mr-2 green--text" v-if="item.email_verified">check</v-icon>
                  <v-icon class="mr-2 red--text" v-if="!item.email_verified">close</v-icon>
                  <span style="word-break: break-all;">{{ item.email }}</span>
              </template>
              <template v-slot:item.actions="{ item }">
                <div class="text-right">
                  <v-btn
                    class="ma-1 d-none d-md-inline"
                    x-small
                    outlined
                    @click="deleteItem(item)"
                    :disabled="guid == item.user_guid"
                  >
                    Delete User
                  </v-btn>
                </div>
              </template>
            </v-data-table>
            <data-table-pagination v-model="page" :paginationTokens="paginationTokens" />
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
  import { mapGetters } from 'vuex'
  
  export default {
    name: 'UserListing',
    data() {
      return {
        error: false,
        errorText: '',
        
        showDeleteUser: false,
        selectedUser: {},
        deleteUserReason: '',
      
        searchBy: 'name',
        search: '',
        awaitingSearch: false,
      
        headers: [
          { text: 'Name', align: 'start', value: 'name', sortable: true },
          { text: 'Email', align: 'start d-none d-lg-table-cell', value: 'email', sortable: true },
          { text: '', align: 'end', value: 'actions', sortable: false },
        ],
      
        loading: true,
      
        items: [],
      
        totalItems: 0,
        itemsPerPage: 50,
        page: 1,
        pageCount: 0,
      
        paginationTokens: []
      }
    },
    mounted() {
      this.searchUsers(0)
    },
    watch: {
      page() {
        this.searchUsers(this.page - 1)
      },
      itemsPerPage() {
        this.items = []
        this.page = 1
        this.searchUsers(this.page - 1)
      },
      search() {
        if (!this.awaitingSearch) {
          setTimeout(() => {
            this.items = []
            this.page = 1
            this.searchUsers(0)
            
            this.awaitingSearch = false
          }, 2000)
        }
        this.awaitingSearch = true
      }
    },
    computed: {
      ...mapGetters('user', ['user', 'guid', 'isUserAdmin']),
    },
    methods: {
      searchUsers(paginationIndex) {
        this.loading = true
        
        const paginationToken = this.paginationTokens[paginationIndex - 1]
        
        var queryParams = {
          'pagination_size': this.itemsPerPage
        }
        
        if (this.search) {
          queryParams.search_attribute = this.searchBy
          queryParams.search_value = this.search
          queryParams.starts_with = true
        } else {
          queryParams.search_attribute = 'enabled'
          queryParams.search_value = true
        }
        
        if (paginationToken) {
          queryParams.pagination_token = paginationToken
        }
        
        this.axios.get('/users', {'params': queryParams}).then((response) => {
          console.log('UserListing searchUsers response', response)
          if (response.data.users.length > 0) {
            
            this.items = response.data.users.map((user) => this.$users.getUser(user.user_guid, user))
            this.totalItems = response.data.total_found
            
            if (response.data.pagination_token) {
              this.$set(this.paginationTokens, paginationIndex, response.data.pagination_token)
            }
          }
          this.loading = false
        }).catch((error) => {
          console.log('UserListing searchUsers error', error)
          this.error = true
          this.errorText = this.$helpers.parseError(error)
          this.loading = false
        })
      },
      
      createUser() {
        /*
        
        if (this.newUserName) {
          this.loading = true
          this.showCreateUser = false

          this.axios.post('/users', {name: this.newOrgName})
            .then((response) => {
              console.log('createOrg', response)
              this.items = []
              this.page = 1
              this.searchOrgs(0)
              
              this.error = false
              this.errorText = ''
            }).catch((error) => {
              console.log('createOrg error', error)
              
              this.error = true
              this.errorText = this.$helpers.parseError(error)
              
              this.loading = false
          })
          
        }
        */
      },
      
      editItem(item) {
        console.log('UserListing editItem', item)
        this.$router.push('/admin/users/' + item.user_guid)
      },
       
      deleteItem(item) {
        console.log('UserListing deleteItem', item)
        this.selectedUser = item
        this.showDeleteUser = true
      },
      
      confirmDeleteUser() {
        if (this.deleteUserReason) {
          this.loading = true
          this.showDeleteUser = false
        
          this.axios.delete('/users/' + this.selectedUser.user_guid, {'data': {'reason': this.deleteUserReason}})
          .then((response) => {
            console.log('UserListing confirmDeleteUser response', response)
            
            this.deleteUserReason = ''
            
            this.items = []
            this.page = 1
            this.searchUsers(0)
            
            this.error = false
            this.errorText = ''
          }).catch((error) => {
            console.log('UserListing confirmDeleteUser error', error)
            this.error = true
            this.errorText = this.$helpers.parseError(error)
            this.loading = false
          })
        }
        
        
      }
    }
  }
</script>