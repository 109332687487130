<template>
  <v-list-item 
    @click="showDialog()"
    :disabled="!device.isOnline()"
  >
    <v-dialog
      v-if="device"
      v-model="showControl"
      max-width="540px"
    >
      <videon-card heading="Download Device Logs" showClose="true" :ignoreHeaderMargin="!isVersionSupported" @close="showControl = false">
        <v-alert
          text
          color="orange"
          icon="mdi-alert-outline"
          v-if="!isVersionSupported"
        >
          This feature is not supported on {{ this.device.cloud_version.original }}, please update your cloud agent.
        </v-alert>
        <v-card-text>
          <v-alert dense outlined type="error" v-if="error" >
            {{ errorText }}
          </v-alert>
          <div class="text-center mb-5">
            <div v-if="loading">
              <v-progress-linear
                color="primary"
                indeterminate
                buffer-value="0"
                class="mb-3"
              ></v-progress-linear>
            </div>
            
            <h3 v-if="logs && lastExportDate" class="mb-5">Device Logs from {{ lastExportDate | moment("YYYY/MM/DD hh:mm:ss a") }}</h3>
            
            <v-btn
              class="ma-2"
              color="accent"
              @click="requestDeviceLogs"
              :disabled="loading || !isVersionSupported"
              small
              outlined
            >
              Request New Device Logs
            </v-btn>
          </div>
          
          <div class="text-right">
            <v-btn
              class="ma-1"
              color="secondary"
              @click="showControl = false"
              text
            >
              Cancel
            </v-btn>
            <v-btn
              class="ma-1"
              color="primary"
              :href="downloadURL"
              target="_blank"
              :disabled="loading || !downloadURL"
            >
              <v-icon class="material-icons-outlined">folder_zip</v-icon> 
              Download
            </v-btn>
          </div>
        </v-card-text>
      </videon-card>
    </v-dialog>
    
    <v-list-item-title class="pr-2">
      <v-icon class="material-icons-outlined">folder_zip</v-icon> 
      Download Logs
    </v-list-item-title>
  </v-list-item>
</template>

<script>
  export default {
    name: 'DownloadLogs',
    
    props: ['device'],
    
    data() {
      return {
        minimumAgentVersion: this.$helpers.parseVersion('cloud', 'V1.0.0b18'),
        
        error: false,
        errorText: '',
        
        showControl: false,
        
        loading: false,
        
        logs: false
      }
    },
    mounted() {
      
    },
    computed: {
      isVersionSupported() {
        if (this.$helpers.versionCheck(this.minimumAgentVersion, this.device.cloud_version)) {
          return true
        } else if (this.device.cloud_version.dev) {
          return true
        }
        return false
      },
      downloadURL() {
        if (this.logs) {
          return this.logs.url
        }
        return ""
      },
      lastExportDate() {
        if (this.logs) {
          return this.logs.last_retrieved
        }
        return false
      }
    },

    methods: {
      showDialog() {
        this.device.resetCommandState()
        this.fetchLogs()
        this.showControl = true
      },
      fetchLogs() {
        this.loading = true
        
        this.axios.get('/devices/' + this.device.device_guid + '/logs')
        .then((response) => {
          console.log('DownloadLogs ' + this.device.device_guid + ' fetchLogs response', response)
          
          this.logs = response.data.logs
          
          this.loading = false
        }).catch(() => {
          // expect 404 if logs have never been requested, if we're in loading state, continue to fetch until we get something...
          this.loading = false
        })
      },
      requestDeviceLogs() {
        this.loading = true
        
        this.axios.post('/devices/' + this.device.device_guid + '/logs')
        .then((response) => {
          console.log('DownloadLogs ' + this.device.device_guid + ' requestDeviceLogs response', response)
          
          setTimeout(() => {
            this.fetchLogs()
          }, 10000)
        }).catch((error) => {
          this.error = true
          this.errorText = this.$helpers.parseError(error)
        })
      },
    }
  }
</script>