<template>
  <v-card 
    class="mx-auto"
  >
    <v-dialog
      v-model="showDeleteFleets"
      max-width="480px"
      v-if="selectedFleets.length > 0"
    >
      <videon-card heading="Delete Fleet" showClose="true" @close="showDeleteFleets = false">
        <v-card-text>
          <p class="subtitle-1">
            Are you sure you want to forget the following fleets?
            <ul class="mt-2">
              <li 
                v-for="(fleet, index) in selectedFleets" 
                v-bind:key="index"
                class="primary--text"
              >
                {{ fleet.displayName() }} 
              </li>
            </ul>
          </p>
          <v-form @submit.prevent="confirmDeleteFleet">
            <v-text-field
              v-model="deleteFleetReason"
              label="Reason"
              required
              outlined
            ></v-text-field>
            <div class="text-right">
              <v-btn
                class="ma-1"
                color="secondary"
                @click="showDeleteFleets = false"
                text
              >
                Cancel
              </v-btn>
              <v-btn
                class="ma-1"
                color="primary"
                @click="confirmDeleteFleet"
              >
                Delete
              </v-btn>
            </div>
          </v-form>
        </v-card-text>
      </videon-card>
    </v-dialog>
    
    <v-dialog
      v-model="showCreateFleet"
      max-width="600px"
    >
      <videon-card heading="Create Fleet" showClose="true" @close="showCreateFleet = false">
        <v-card-text>
          <v-form @submit.prevent="createFleet">
            
            <v-text-field
              v-model="newFleetName"
              label="Fleet Name"
              required
              outlined
            ></v-text-field>
            
            <div class="text-right">
              <v-btn
                class="ma-1"
                color="secondary"
                @click="showCreateFleet = false"
                text
              >
                Cancel
              </v-btn>
              <v-btn
                class="ma-1"
                color="primary"
                @click="createFleet"
              >
                Create
              </v-btn>
            </div>
          </v-form>
        </v-card-text>
      </videon-card>
    </v-dialog>

  
    <v-toolbar flat>
      <v-toolbar-title>
        <v-icon size="32px">folder</v-icon>
        
        Fleets
      </v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    
    <v-divider/>
    
    <v-card-text>
      <v-alert dense outlined type="error" v-if="error" >
        {{ errorText }}
      </v-alert>
    
      <v-container>
        <v-row class="mx-1 mb-5">
          <v-tabs
            v-model="fleetsBy"
            @change="searchFleets(0)"
            :show-arrows="false"
          >
            <v-tab>
              <v-icon class="mr-2">house</v-icon>
              {{ this.$helpers.htmlDecode(organization.org_name) }} Fleets
            </v-tab>
            <v-tab>
              <v-icon class="mr-2">person</v-icon>
              Assigned To Me
            </v-tab>
          </v-tabs>
        </v-row>
        <v-row class="mx-2 my-1" align="end">
          <v-text-field
            class="mr-5"
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            hide-details
          ></v-text-field>

          <v-btn
            small
            color="primary"
            class="mt-5"
            @click="showCreateFleet = true"
            tooltip="Create New Fleet"
            v-if="canEdit"
          >
            <v-icon>mdi-plus</v-icon> Create Fleet
          </v-btn>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-data-table
              v-model="selectedFleets"
              item-key="fleet_guid"
              :headers="headers"
              :items="items"
              :show-select="canEdit"
              :loading="loading"
              :page.sync="page"
              :items-per-page="itemsPerPage"
              hide-default-footer
            >
              <template v-slot:item.fleet_name="{ item }">
                <v-icon class="mr-2">folder</v-icon>
                <span class="subtitle-1" @click="manageFleet(item)" style="cursor: pointer;">{{ item.displayName() }}</span>
              </template>
              <template v-slot:item.fleet_status="{ item }">
                <fleet-status-label :fleet="item" />
              </template>
            </v-data-table>
            <v-card-actions v-if="selectedFleets && selectedFleets.length > 0" class="px-0">
              <v-sheet
                rounded
                elevation="2"
                class="py-1 px-3"
                width="100%"
              >
                <span class="subtitle-2 mr-2">Actions</span>
                <v-btn
                  small
                  class="ma-1"
                  outlined
                  @click="deleteFleets()"
                  :disabled="!canEdit"
                >
                  Delete Fleet(s)
                </v-btn>
              </v-sheet>
            </v-card-actions>
            <data-table-pagination v-model="page" :paginationTokens="paginationTokens" />
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
  import { mapGetters } from 'vuex'
  
  export default {
    name: 'FleetListing',
    data() {
      return {
        error: false,
        errorText: '',
        
        showDeleteFleets: false,
        selectedFleets: [],
        deleteFleetReason: '',
      
        showCreateFleet: false,
        newFleetName: '',
      
        search: '',
        awaitingSearch: false,
      
        headers: [
          { text: 'Fleet', align: 'start', value: 'fleet_name', sortable: true },
          { text: 'Status', align: 'center d-none d-md-table-cell', value: 'fleet_status', sortable: false },
        ],
      
        loading: true,
      
        fleetsBy: 0,
      
        items: [],
      
        itemsPerPage: 50,
        page: 1,
        pageCount: 0,
      
        paginationTokens: []
      }
    },
    mounted() {
      this.searchFleets(0)
    },
    watch: {
      page() {
        this.searchFleets(this.page - 1)
      },
      itemsPerPage() {
        this.items = []
        this.page = 1
        this.searchFleets(this.page - 1)
      },
      search() {
        if (!this.awaitingSearch) {
          setTimeout(() => {
            this.items = []
            this.page = 1
            this.searchFleets(0)
            
            this.awaitingSearch = false
          }, 2000)
        }
        this.awaitingSearch = true
      },
    },
    computed: {
      ...mapGetters('user', ['user', 'guid', 'organization']),
      
      orgName() {
        if (this.organization) {
          return this.organization.org_name
        }
        return ''
      },
      canEdit() {
        if (this.fleetsBy == 0) {
          if (this.organization) {
            return this.$organizations.getOrg(this.organization.org_guid).canEdit()
          }
          return false
        } else {
          return false
        }
      }
    },
    methods: {
      searchFleets(paginationIndex) {
        this.loading = true
        this.items = []
        
        const paginationToken = this.paginationTokens[paginationIndex - 1]
        
        var queryParams = {
          'pagination_size': this.itemsPerPage
        }
        
        if (paginationToken) {
          queryParams.pagination_token = paginationToken
        }
        
        if (this.search) {
          queryParams.fleet_name = this.search
        }
        
        if (this.fleetsBy == 1) {
          queryParams.user_guid = this.guid
        }
        
        this.axios.get('/fleets', {'params': queryParams}).then((response) => {
          console.log('FleetListing searchFleets response', response)
          
          if (response.data.fleets.length > 0) {  
            this.items = response.data.fleets.map((fleet) => this.$fleets.getFleet(fleet.fleet_guid, fleet)) // NOTE: this is stripping perms data off
            
            if (response.data.pagination_token) {
              this.$set(this.paginationTokens, paginationIndex, response.data.pagination_token)
            }
          }
          this.loading = false
        }).catch((error) => {
          console.log('FleetListing searchFleets error', error)
          this.error = true
          this.errorText = this.$helpers.parseError(error)
          this.loading = false
        })
      },
      
      createFleet() {
        if (this.newFleetName) {
          this.loading = true
          this.showCreateFleet = false
        
          this.error = false
          this.errorText = ''
        
          console.log('FleetListing createFleet', this.newFleetName)
        
          this.axios.post('/fleets', {'fleet_name': this.newFleetName}).then((response) => {
            console.log('FleetListing createFleet response', response)
            
            if (response.data.fleet_guid) {
              this.$router.push('/fleets/' + response.data.fleet_guid)
            } else {
              this.error = true
              this.errorText = 'fleet_guid not returned in response'
            }
          }).catch((error) => {
            console.log('FleetListing createFleet error', error)
            this.error = true
            this.errorText = this.$helpers.parseError(error)
            this.loading = false
          })
        }
      },
      
      manageFleet(item) {
        console.log('FleetListing manageFleet', item)
        this.$router.push('/fleets/' + item.fleet_guid)
      }, 
      
      deleteFleets() {
        console.log('FleetListing deleteFleets', this.selectedFleets)
        this.showDeleteFleets = true
      },
      
      async confirmDeleteFleet() {
        if (this.deleteFleetReason) {
          var fleetGuids = this.selectedFleets.map(fleet => fleet.fleet_guid)
          
          this.loading = true
          this.showDeleteFleets = false
          
          for (var fleet_guid of fleetGuids) {
            try {
              console.log('FleetListing confirmDeleteFleet deleting fleet', fleet_guid)
              await this.axios.delete('/fleets/' + fleet_guid, {'data': {'reason': this.deleteFleetReason}})
            } catch (error) {
              console.log('DeviceListing confirmDeleteDevice error', error)
              this.error = true
              this.errorText = this.$helpers.parseError(error)
            }
          }
          
          this.selectedFleets = []
          this.loading = false
          this.searchFleets(0)
        }
      }
    }
  }
</script>