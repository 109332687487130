<template>
  <v-container class="pa-0" fluid>
    <v-row class="ma-0 ma-md-2">
      <v-col rows="12">
        <edit-user-box />
      </v-col>
    </v-row>
    <v-row class="ma-0 ma-md-2">
      <v-col rows="12">
        <access-tokens-box :user_guid="guid"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapGetters } from 'vuex'

  import EditUserBox from '../components/user/MyUser.vue'
  import AccessTokensBox from '../components/user/AccessTokens.vue'

  export default {
    name: "Account",
    components: { 
      EditUserBox,
      AccessTokensBox
    },
    computed: {
      ...mapGetters('user', ['user', 'guid']),
    },
  }
</script>
