<template>
  <v-card 
    class="mx-auto"
  >
    <v-dialog
      v-model="showDeleteFleet"
      max-width="400px"
    >
      <videon-card heading="Delete Fleet" showClose="true" @close="showDeleteFleet = false">
        <v-card-text>
          <p class="subtitle-1 mt-3">
            Are you sure you want to delete <span class="primary--text text-no-wrap">{{ fleetName }}</span>?
          </p>
          <v-form @submit.prevent="confirmDeleteFleet">
            <v-text-field
              v-model="deleteFleetReason"
              label="Reason"
              required
              outlined
            ></v-text-field>
            <div class="text-right">
              <v-btn
                class="ma-1"
                color="secondary"
                @click="showDeleteFleet = false"
                text
              >
                Cancel
              </v-btn>
              <v-btn
                class="ma-1"
                color="primary"
                @click="confirmDeleteFleet"
              >
                Delete
              </v-btn>
            </div>
          </v-form>
        </v-card-text>
      </videon-card>
    </v-dialog>
    
    <v-dialog
      v-model="showRenameFleet"
      max-width="400px"
    >
      <videon-card heading="Rename Fleet" showClose="true" @close="showRenameFleet = false">
        <v-card-text>
          <v-form @submit.prevent="renameFleet">
            <v-text-field
              v-model="newFleetName"
              label="Fleet Name"
              required
              outlined
            ></v-text-field>
            <div class="text-right">
              <v-btn
                class="ma-1"
                color="secondary"
                @click="showRenameFleet = false"
                text
              >
                Cancel
              </v-btn>
              <v-btn
                class="ma-1"
                color="primary"
                @click="renameFleet"
              >
                Rename
              </v-btn>
            </div>
          </v-form>
        </v-card-text>
      </videon-card>
    </v-dialog>
    
    <div v-if="fleetError" class="ma-2">
      <v-row align="center" style="min-height: 200px;">
        <v-col cols="2" class="text-center"><v-icon size="72" color="red">warning</v-icon></v-col>
        <v-col cols="9">
          <h2 class="my-5">Fleet not found</h2>
          <p>
            There was an error loading this fleet...
          </p>
          <v-btn
            outlined
            small
            class="ma-1"
            @click="$router.go(-1)"
          >
            <v-icon>mdi-arrow-left</v-icon> Back
          </v-btn>
        </v-col>
      </v-row>
    </div>
    
    <div v-else>
      <v-toolbar flat>
        <v-toolbar-title>
          <v-icon size="32px">folder</v-icon>
        
          {{ fleetName }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          text
          small
          class="ma-1"
          @click="$router.go(-1)"
        >
          <v-icon>mdi-arrow-left</v-icon> Back
        </v-btn>
      
        <div v-if="canEdit" class="d-none d-md-inline">
          <v-btn
            class="ma-1"
            small
            text
            color="secondary"
            @click="showRenameFleet = true"
          >
            <v-icon>edit</v-icon> Rename
          </v-btn>
        
          <v-btn
            class="ma-1"
            small
            text
            @click="showDeleteFleet = true"
          >
            <v-icon>delete</v-icon> Delete
          </v-btn>
        
        </div>
      </v-toolbar>
  
      <v-divider/>
    
      <v-tabs
        v-model="section"
        color="secondary"
        :show-arrows="true"
      >
        <v-tabs-slider></v-tabs-slider>
        <v-tab :to="href('devices')">
          Fleet Overview
        </v-tab>
        <v-tab :to="href('monitor')">
          Fleet Monitor
        </v-tab>
        <v-tab :to="href('multiview')">
          Fleet Multi-View
        </v-tab>
        <v-tab :to="href('users')" v-if="canEdit">
          Fleet Users
        </v-tab>
      </v-tabs>
    
      <v-divider/>
    
      <v-card-text>
        <v-alert dense outlined type="error" v-if="error">
          {{ errorText }}
        </v-alert>
      
        <v-progress-linear
          v-if="loading && !fleet"
          color="primary"
          indeterminate
          buffer-value="0"
        ></v-progress-linear>
      
        <div v-if="fleet">
          <v-tabs-items v-model="section">
            <v-tab-item :value="href('devices')">
              <fleet-overview :fleet_guid="fleet_guid" :fleet="fleet" :canEdit="canEdit" />
            </v-tab-item>
          
            <v-tab-item :value="href('monitor')">
              <fleet-monitor :fleet_guid="fleet_guid" :fleet="fleet" />
            </v-tab-item>
          
            <v-tab-item :value="href('multiview')">
              <fleet-multiview :fleet_guid="fleet_guid" :fleet="fleet" />
            </v-tab-item>
          
            <v-tab-item :value="href('users')" v-if="canEdit">
              <fleet-users :fleet_guid="fleet_guid" :fleet="fleet" />
            </v-tab-item>
          </v-tabs-items>
        </div>
      </v-card-text>
    </div>
  </v-card>
</template>

<script>
  import { mapGetters } from 'vuex'
  
  import FleetOverview from './sections/FleetOverview.vue'
  import FleetMonitor from './sections/FleetMonitor.vue'
  import FleetMultiview from './sections/FleetMultiview.vue'
  import FleetUsers from './sections/FleetUsers.vue'

  export default {
    name: 'Fleet',
        
    components: {
      FleetOverview,
      FleetMonitor,
      FleetMultiview,
      FleetUsers
    },
    
    props: {
      fleet_guid: {
        type: String,
        required: true
      }
    },
    
    watch: {
      $route() {
        this.section = this.$route.params.section || 'devices'
      },
    },
    
    data() {
      return {
        section: this.$route.params.section || 'devices',
        
        error: false,
        errorText: '',
        
        loading: false,
        
        showRenameFleet: false,
        newFleetName: '',
        
        showDeleteFleet: false,
        deleteFleetReason: '',
        
        fleet: false,
        
        refreshTimer: false,
        refreshInterval: this.$helpers.getRefreshInterval(15000, 60000)
      }
    },
    computed: {
      ...mapGetters('user', ['user', 'guid', 'isAdmin', 'isOrgAdmin']),
      
      fleetError() {
        if (this.fleet) {
          return this.fleet.error
        }
        return false
      },
      
      fleetName() {
        return this.fleet.displayName()
      },
      
      canEdit() {
        return this.fleet.canEdit()
      }
    },
    
    created() {
      this.loadFleet()
    },
    mounted() {
      this.newFleetName = this.fleet.fleet_name
    },
    
    beforeDestroy() {
      if (this.refreshTimer) {
        clearTimeout(this.refreshTimer)
        this.refreshTimer = false
      }
    },
    
    methods: {
      href(section) {
        return '/fleets/' + this.fleet_guid + '/' + section
      },
      
      loadFleet() {
        this.loading = true
        
        if (!this.fleet_guid) {
          this.error = true
          this.errorText = 'fleet_guid not supplied'
          return
        }
        
        if (this.fleet) {
          this.fleet.refresh()
        } else {
          this.fleet = this.$fleets.getFleet(this.fleet_guid)
          
          // if we dont have status... fetch it
          if (!this.fleet.status) {
            this.fetchStatus()
          }
          
          // if we dont have devices... fetch them
          if (this.fleet.devices.length == 0) {
            this.fleet.fetchDevices()
          }
          // if we dont have users... fetch them
          if (this.fleet.users.length == 0) {
            this.fleet.fetchUsers()
          }
        }
      },
      
      fetchStatus() {
        this.fleet.fetchStatus()
        
        if (this.refreshInterval) {
          this.refreshTimer = setTimeout(() => {
            this.fetchStatus()
          }, this.refreshInterval)
        }
      },
      
      renameFleet() {
        if (this.newFleetName) {
          this.loading = true
          this.showRenameFleet = false
          
          this.axios.patch('/fleets/' + this.fleet_guid, { 'fleet_name': this.newFleetName })
          .then((response) => {
            console.log('Fleet renameFleet response', response)
            
            this.loadFleet()
          }).catch((error) => {
            console.log('Fleet renameFleet error', error)
          
            this.error = true
            this.errorText = this.$helpers.parseError(error)
          
            this.loading = false
          })
        }
      },
      
      confirmDeleteFleet() {
        if (this.deleteFleetReason) {
          this.loading = true
          this.showDeleteFleet = false
        
          this.axios.delete('/fleets/' + this.fleet_guid, {'data': {'reason': this.deleteFleetReason}})
          .then((response) => {
            console.log('Fleet confirmDeleteFleet response', response)
            
            this.deleteFleetReason = ''
            
            this.error = false
            this.errorText = ''
            
            this.$fleets.invalidateFleet(this.fleet_guid)
            
            this.$router.push('/fleets')
          }).catch((error) => {
            console.log('Fleet confirmDeleteFleet error', error)
          
            this.error = true
            this.errorText = this.$helpers.parseError(error)
          
            this.loading = false
          })
        }
      },
    }
  }
</script>