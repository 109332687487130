<template>
  <v-card 
    :flat="flat"
  >
    <v-dialog
      v-model="showDeleteToken"
      max-width="400px"
    >
      <videon-card heading="Delete Token" showClose="true" @close="showDeleteToken = false">
        <v-card-text>
          <p class="subtitle-1 mt-3">
            Are you sure you want to delete token <span class="primary--text">{{ selectedToken.token_prefix }}</span>?
          </p>
          <v-form @submit.prevent="confirmDeleteToken">
            <div class="text-right">
              <v-btn
                class="ma-1"
                color="secondary"
                @click="showDeleteToken = false"
                text
              >
                Cancel
              </v-btn>
              <v-btn
                class="ma-1"
                color="primary"
                @click="confirmDeleteToken"
              >
                Delete
              </v-btn>
            </div>
          </v-form>
        </v-card-text>
      </videon-card>
    </v-dialog>
    
    
    <v-dialog
      v-model="showCreateToken"
      max-width="600px"
    >
      <videon-card heading="Create Access Token" showClose="true" @close="showCreateToken = false">
        <v-card-text>
          <div v-if="!newToken">
            <p class="subtitle-1 mb-5 mt-3">
              Click the <span class="primary--text">Generate Token</span> button to create a new Personal Access Token.
            </p>
            <v-text-field
              v-model="tokenComment"
              label="Token Comment / Notes"
              outlined
            ></v-text-field>
          </div>
          <div v-if="newToken" class="mb-5">
            <p class="subtitle-1">
              Click the <span class="primary--text">Download</span> button to download your token.
            </p>
            <pre>{{ newToken }}</pre>
          </div>
          <p>
            <i>The token will only be displayed here once, please download it before closing this dialog.</i>
          </p>
          <div class="text-right">
            <v-btn
              class="ma-1"
              color="secondary"
              @click="showCreateToken = false"
              text
            >
              Cancel
            </v-btn>
            <v-btn
              class="ma-1"
              color="primary"
              @click="downloadNewToken"
              v-if="newToken"
            >
              Download
            </v-btn>
            <v-btn
              color="primary"
              @click="generateToken"
              v-if="!newToken"
            >
              Generate Token
            </v-btn>
          </div>
        </v-card-text>
      </videon-card>
    </v-dialog>
    
    <v-toolbar flat>
      <v-toolbar-title>
        <v-avatar v-if="!flat"
          height="32px"
          class="mx-auto"
        >
          <videon-logo />
        </v-avatar>
        Access Tokens
      </v-toolbar-title>
      <v-spacer></v-spacer>
      
      <v-btn
        small
        color="primary"
        class="d-none d-md-inline-flex"
        @click="showCreateToken = true"
        tooltip="Create Token"
        v-if="editable"
      >
        <v-icon>mdi-plus</v-icon> Create Token
      </v-btn>
    </v-toolbar>
    
    <v-divider/>
    
    <v-alert dense outlined type="error" v-if="error" class="ma-5 mb-0">
      {{ errorText }}
    </v-alert>
    
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="tokens"
        :loading="loading"
        hide-default-footer
        :items-per-page="200"
      >
        <template v-slot:item.token_prefix="{ item }">
          <span class="subtitle-2">{{ item.token_prefix }}</span>
        </template>
        <template v-slot:item.last_used="{ item }">
          {{ item.last_used | moment("YYYY/MM/DD hh:mm:ss a") }}
        </template>
        <template v-slot:item.issued="{ item }">
          {{ item.issued | moment("YYYY/MM/DD hh:mm:ss a") }}
        </template>
        <template v-slot:item.expires="{ item }">
          {{ item.expires | moment("YYYY/MM/DD hh:mm:ss a") }}
        </template>
        <template v-slot:item.comment="{ item }">
          {{ item.comment }}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn
            class="ma-1"
            x-small
            outlined
            @click="deleteToken(item)"
            v-if="editable"
          >
            Delete Access Token
          </v-btn>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'AccessTokens',
 
    data() {
      return {
        error: false,
        errorText: '',
      
        loading: false,
        editable: false,
            
        headers: [
          { text: 'Identifier', align: 'center', value: 'token_prefix', sortable: true },
          { text: 'Last Used', align: 'center', value: 'last_used', sortable: true },
          { text: 'Issued', align: 'center', value: 'issued', sortable: true },
          { text: 'Expires', align: 'center', value: 'expires', sortable: true },
          { text: 'Comment', align: 'left', value: 'comment', sortable: true },
          { text: '', align: 'end', value: 'actions', sortable: false },
        ],
      
        tokens: [],
      
        showDeleteToken: false,
        showCreateToken: false,
      
        tokenLifespanDays: 365,
        tokenComment: '',
      
        newToken: false,
        selectedToken: false,
      }
    },
    
    props: {
      user_guid: {
        type: String,
        required: true
      },
      flat: {
        type: Boolean,
        default: false
      }
    },
    
    computed: {
      ...mapGetters('user', ['user', 'guid']),
    },


    mounted() {
      if (this.user_guid == this.guid) {
        this.editable = true
      }
      this.loadTokens()
    },
    methods: {
      loadTokens() {
        this.loading = true
        this.tokens = []
        
        if (!this.user_guid) {
          this.error = true
          this.errorText = 'user_guid not supplied'
          return
        }
        
        this.axios.get('/pats', {params:{'user_guid': this.user_guid}}).then((response) => {
          console.log('AccessTokens loadTokens response', response)
          
          if (response.data.personal_access_tokens && response.data.personal_access_tokens.length > 0) {
            this.tokens = response.data.personal_access_tokens
          }
          this.loading = false
        }).catch((error) => {
          this.error = true
          this.errorText = this.$helpers.parseError(error)
          this.loading = false
        })
      },
      deleteToken(item) {
        console.log('AccessTokens deleteToken', item)
        this.selectedToken = item
        this.showDeleteToken = true
      },
      confirmDeleteToken() {
        console.log('AccessTokens confirmDeleteToken', this.selectedToken)
        this.loading = true
        this.showDeleteToken = false
      
        this.axios.delete('/pats/' + this.selectedToken.token_guid)
        .then((response) => {
          console.log('AccessTokens confirmDeleteToken response', response)
          
          this.loadTokens()
        }).catch((error) => {
          console.log('AccessTokens confirmDeleteOrg error', error)
          this.error = true
          this.errorText = this.$helpers.parseError(error)
          this.loading = false
        })
        
      },
      
      generateToken() {
        console.log('AccessTokens generateToken')
        
        this.axios.post('/pats', {'token_lifespan_days': this.tokenLifespanDays, 'comment': this.tokenComment}).then((response) => {
          console.log('AccessTokens generateToken response', response)
          
          var tokenData = response.data
          
          if (tokenData.token_guid) {
            this.newToken = {'token': tokenData.token_value, 'expires': tokenData.expires}
          }
          
          this.loading = false
        }).catch((error) => {
          this.error = true
          this.errorText = this.$helpers.parseError(error)
          this.loading = false
        })
      },
      
      downloadNewToken() {
        console.log('AccessTokens downloadNewToken')
        var tokenBlob = new Blob([JSON.stringify(this.newToken)], {type : "application/json"})
                
        const link = document.createElement('a')
        link.href = URL.createObjectURL(tokenBlob)
        link.download = 'videon-personal-access-token.json'
        
        document.body.appendChild(link)
        link.click()
        
        this.showCreateToken = false
      }
      
    }
  }
</script>