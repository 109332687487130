<template>
  <v-container class="pa-0 my-3">
    <v-dialog
      v-model="showSelectMetrics"
      max-width="480px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Select Metrics</span>
        </v-card-title>
        <v-card-text>
          <v-form @submit.prevent="selectionChanged">
            <v-list flat>
              <v-list-group
                v-for="(namespace, key) in availableMetrics"
                :key="key"
                v-model="namespace.active"
                multiple
              >
                <template v-slot:activator>
                  <v-list-item-content>
                    <v-list-item-title v-text="key" class="text-capitalize font-weight-medium"></v-list-item-title>
                  </v-list-item-content>
                </template>

                <v-list-item
                  v-for="(metric) in namespace"
                  :key="metric"
                >
                  <v-list-item-action>
                    <v-checkbox
                      :id="key + '|' + metric"
                      :key="key + '|' + metric"
                      :value="key + '|' + metric"
                      v-model="selectMetricsItems"
                    ></v-checkbox>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title v-text="$helpers.getLabel(metric)"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-group>
            </v-list>
                
            <div class="text-right">
              <v-btn
                class="ma-1"
                color="secondary"
                @click="showSelectMetrics = false"
                text
              >
                Cancel
              </v-btn>
              <v-btn
                class="ma-1"
                color="primary"
                @click="selectionChanged(true)"
              >
                Select
              </v-btn>
            </div>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    
    <h4>Metric Selection</h4>
    <v-card outlined flat>
      <v-row class="mx-2 my-1" align="center" style="min-height: 48px">
        <span v-if="items.length == 0" class="ml-2 text--secondary">Please make a selection</span>
        <v-chip 
          v-for="(metric, index) in items" 
          v-bind:key="metric" 
          outlined
          close
          close-icon="mdi-close"
          @click:close="removeItem(metric)"
          class="ma-1"
        >
          <v-icon
            small
            v-if="symbols"
            class="mr-1"
            :color="(colorCode) ? $helpers.getGraphColorByIndex(index) : 'text--disabled'"
          >{{ getIcon(index) }}
          </v-icon>
          {{ $helpers.getLabel(metric) }}
        </v-chip>
        <v-spacer />
        <v-btn color="primary" @click="selectMetrics()" small class="ma-1">Select Metrics</v-btn>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>  
  export default {
    name: 'MetricsSelection',
    components: {
      
    },
    data() {
      return {
        error: false,
        errorText: '',
      
        loading: true,
      
        showSelectMetrics: false,
        selectMetricsItems: [],
        items: [],
      
        availableMetrics: {},
      }
    },
    props: {
      value: {
        type: [Array, Boolean]
      },
      symbols: {
        type: Array,
        default: null
      },
      colorCode: {
        type: Boolean,
        default: true
      }
    },
    mounted() {
      if (this.value) {
        this.items = this.value
      }
      
      this.fetchAvailableMetrics()
    },
    methods: {
      fetchAvailableMetrics() {
        this.loading = true
        
        this.axios.get('/devices/metrics').then((response) => {
          console.log('MetricsSelection fetchAvailableMetrics response', response)
          
          this.availableMetrics = response.data.namespaces
          
          this.loading = false
        }).catch((error) => {
          this.error = true
          this.errorText = this.$helpers.parseError(error)
          this.loading = false
        })
      },
      
      removeItem(item) {        
        for (var x in this.items) {
          if (this.items[x] == item) {
            this.items.splice(x, 1)
            this.selectionChanged()
            return
          }
        }
      },
      
      selectMetrics() {
        this.selectMetricsItems = this.items
        this.showSelectMetrics = true
      },
      
      selectionChanged(updateFromSelectMetrics = false) {
        if (updateFromSelectMetrics) {
          this.items = this.selectMetricsItems
        }
        this.showSelectMetrics = false
        this.$emit('input', this.items)
      },

      getIcon(index) {
        var name = this.symbols[index % this.symbols.length]
        if (name == 'rect') {
          name = 'square'
        } else if (name == 'rectRot') {
          name = 'rhombus'
        }

        return 'mdi-' + name
      }
    }
  }
</script>