<template>
  <v-card 
    class="mx-auto"
  >
    <v-dialog
      v-model="showDeleteDevices"
      max-width="480px"
      v-if="selectedDevices.length > 0"
    >
      <videon-card heading="Forget Device(s)" showClose="true" @close="showDeleteDevices = false">
        <v-card-text>
          <p class="subtitle-1 mt-3">
            Are you sure you want to forget the following devices?
            <ul class="mt-2">
              <li 
                v-for="(device, index) in selectedDevices" 
                v-bind:key="index"
                class="primary--text"
              >
                {{ device.deviceName() }} 
              </li>
            </ul>
          </p>
          <v-form @submit.prevent="confirmDeleteDevice">
            <v-text-field
              v-model="deleteDeviceReason"
              label="Reason"
              required
              outlined
            ></v-text-field>
            <div class="text-right">
              <v-btn
                class="ma-1"
                color="secondary"
                @click="showDeleteDevices = false"
                text
              >
                Cancel
              </v-btn>
              <v-btn
                class="ma-1"
                color="primary"
                @click="confirmDeleteDevices"
              >
                Forget
              </v-btn>
            </div>
          </v-form>
        </v-card-text>
      </videon-card>
    </v-dialog>
    
    <v-dialog
      v-model="showAdoptDevice"
      max-width="600px"
    >
      <videon-card heading="Adopt a device" showClose="true" @close="showAdoptDevice = false">
        <v-card-text>
          <p class="mt-3">
            Adopting via serial number is temporary as it is not very secure. 
            The official release of {{ deploymentName }} will have device adoption initiated from the device.
          </p>
          <v-form @submit.prevent="adoptDevice">
            
            <v-text-field
              v-model="deviceSerial"
              label="Serial Number"
              hint="example: 1234-1234"
              required
              outlined
            ></v-text-field>
            
            <div class="text-right">
              <v-btn
                class="ma-1"
                color="secondary"
                @click="showAdoptDevice = false"
                text
              >
                Cancel
              </v-btn>
              <v-btn
                class="ma-1"
                color="primary"
                @click="adoptDevice"
              >
                Adopt
              </v-btn>
            </div>
          </v-form>
        </v-card-text>
      </videon-card>
    </v-dialog>
    
    <v-dialog
      v-model="showBulkAction"
      max-width="640px"
      v-if="selectedDevices.length > 0"
    >
      <device-bulk-actions v-if="showBulkAction" :action="bulkAction" :devices="selectedDevices" @close="showBulkAction = false" />
    </v-dialog>
  
    <v-toolbar flat>
      <v-toolbar-title>
        <v-icon size="32px">storage</v-icon>
        All Devices
      </v-toolbar-title>
      <v-spacer></v-spacer>
      
    </v-toolbar>
    
    <v-divider/>
    
    <v-card-text>
      <v-alert dense outlined type="error" v-if="error">
        {{ errorText }}
      </v-alert>
    
      <v-container>
        <v-row class="mx-1 mb-5">
          <v-tabs
            v-model="devicesBy"
            @change="searchDevices(0)"
            :show-arrows="false"
          >
            <v-tab>
              <v-icon class="mr-2">house</v-icon>
              {{ organization.org_name }} Devices
            </v-tab>
            <v-tab>
              <v-icon class="mr-2">person</v-icon>
              Assigned To Me
            </v-tab>
          </v-tabs>
        </v-row>
        
        <v-row class="mx-2 my-1" align="end">
          <v-text-field
            class="mr-5"
            v-model="search"
            append-icon="mdi-magnify"
            label="Search by Device Serial"
            hide-details
          ></v-text-field>

          <v-select
            class="mr-5"
            v-model="productNames"
            :items="productNameList"
            item-text="label"
            item-value="value"
            label="Product"
            clearable
            multiple
            hide-details
          ></v-select>

          <v-btn
            small
            color="primary"
            class="mt-5"
            @click="showAdoptDevice = true"
            tooltip="Adopt a device"
            v-if="canEdit"
          >
            <v-icon>mdi-plus</v-icon> Adopt
          </v-btn>
        </v-row>

        <v-row>
          <v-col cols="12">
            <device-list 
              v-model="items" 
              :loading="loading" 
              actionGroup="devices" 
              @deleteSelection="deleteDevices"
              @outputControl="outputControl" 
              @firmwareUpdate="firmwareUpdate"
              @containerInstall="containerInstall"
              @rebootDevices="rebootDevices"
              :canEdit="canEdit"
            />
            <data-table-pagination v-model="page" :paginationTokens="paginationTokens" />
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
  import { mapGetters } from 'vuex'

  import DeviceList from './components/DeviceList.vue'
  import DeviceBulkActions from './components/DeviceBulkActions.vue'
  
  export default {
    name: 'DeviceListing',
    components: {
      DeviceList,
      DeviceBulkActions
    },
    data() {
      return {
        error: false,
        errorText: '',
              
        deleteDeviceReason: '',
        
        showDeleteDevices: false,
        showAdoptDevice: false,
        showBulkAction: false,
        bulkAction: false,
        
        selectedDevices: [],
        
        deviceSerial: '',
      
        devicesBy: 0,
      
        search: '',
        productNames: [],
        awaitingSearch: false,

        productNameList: [
          { label: 'Edgecaster', value: 'edgecaster' },
          { label: 'Edgecaster Max', value: 'edgecaster_max' },
        ],
      
        headers: [
          { text: 'Device', align: 'start', value: 'device_name', sortable: true },
          { text: 'Status', align: 'center', value: 'streaming_status', sortable: true},
          { text: 'Firmware', align: 'center', value: 'firmware_version', sortable: true},
          { text: 'Serial', align: 'center d-none d-lg-table-cell', value: 'serial_number', sortable: true },
          { text: 'MAC Address', align: 'center d-none d-xl-table-cell', value: 'mac_address', sortable: true },
          { text: '', align: 'end', value: 'actions', sortable: false },
        ],
      
        loading: true,
      
        items: [],
      
        itemsPerPage: 25,
        page: 1,
        pageCount: 0,
      
        paginationTokens: []
      }
    },
    mounted() {
      this.searchDevices(0)
    },
    watch: {
      page() {
        this.searchDevices(this.page - 1)
      },
      itemsPerPage() {
        this.items = []
        this.page = 1
        this.searchDevices(this.page - 1)
      },
      search() {
        if (!this.awaitingSearch) {
          setTimeout(() => {
            this.items = []
            this.page = 1
            this.searchDevices(0)
            this.awaitingSearch = false
          }, 2000)
        }
        this.awaitingSearch = true
      },
      productNames() {
        if (!this.awaitingSearch) {
          setTimeout(() => {
            this.items = []
            this.page = 1
            this.searchDevices(0)
            this.awaitingSearch = false
          }, 500) // Reduce time because it's a dropdown
        }
        this.awaitingSearch = true
      }
    },
    computed: {
      ...mapGetters('user', ['user', 'guid', 'organization']),
      ...mapGetters('config', ['deploymentName']),
      orgName() {
        if (this.organization) {
          return this.organization.org_name
        }
        return ''
      },
      canEdit() {
        if (this.organization) {
          return this.$organizations.getOrg(this.organization.org_guid).canEdit()
        }
        return false
      }
    },
    methods: {
      searchDevices(paginationIndex) {
        this.loading = true
        this.items = []
        
        const paginationToken = this.paginationTokens[paginationIndex - 1]
        
        var queryParams = {
          'pagination_size': this.itemsPerPage
        }
        
        if (this.search) {
          queryParams.serial_number = this.search
        }

        if (this.productNames) {
          queryParams.product_names = this.productNames
        }
        
        if (paginationToken) {
          queryParams.pagination_token = paginationToken
        }
        
        if (this.devicesBy == 1) {
          queryParams.user_guid = this.guid
        } else {
          queryParams.org_guid = this.organization.org_guid
        }
        
        this.axios.get('/devices', {
          'params': queryParams,
          paramsSerializer: {
            indexes: null
          }
        }).then((response) => {
          console.log('DeviceListing searchDevices response', response)
          
          if (response.data.devices.length > 0) {  
            this.items = response.data.devices.map((device) => this.$devices.getDevice(device.device_guid, device))
            
            if (response.data.pagination_token) {
              this.$set(this.paginationTokens, paginationIndex, response.data.pagination_token)
            }
          }
          this.loading = false
        }).catch((error) => {
          console.log('DeviceListing searchDevices error', error)
          this.error = true
          this.errorText = this.$helpers.parseError(error)
          this.loading = false
        })
      },
      
      adoptDevice() {
        this.loading = true
        this.showAdoptDevice = false
        
        this.error = false
        this.errorText = ''
        
        console.log('DeviceListing adoptDevice', this.deviceSerial)
        
        this.axios.post('/devices/adopt?serial_number=' + encodeURIComponent(this.deviceSerial)).then((response) => {
          console.log('DeviceListing adoptDevice response', response)
          
          this.searchDevices(0)
          this.deviceSerial = ''
          
          // refresh device to ensure it has users/state/etc...
          var device = this.$devices.deviceBySerial(this.deviceSerial)
          if (device) {
            device.refresh()
          }
          
          this.loading = false
        }).catch((error) => {
          console.log('DeviceListing adoptDevice error', error)
          this.error = true
          this.errorText = this.$helpers.parseError(error)
          this.loading = false
        })
      },
      
      manageDevice(item) {
        console.log('DeviceListing manageDevice', item)
        this.$router.push('/devices/' + item.device_guid)
      }, 
      
      deleteDevices(devices) {
        console.log('DeviceListing deleteDevices', devices)
        this.selectedDevices = devices
        this.showDeleteDevices = true
      },
      
      async confirmDeleteDevices() {
        if (this.deleteDeviceReason) {
          var deviceGuids = this.selectedDevices.map(device => device.device_guid)
          
          this.loading = true
          this.showDeleteDevices = false
          
          for (var device_guid of deviceGuids) {
            try {
              console.log('DeviceListing confirmDeleteDevices deleting device', device_guid)
              await this.axios.delete('/devices/' + device_guid, {'data': {'reason': this.deleteDeviceReason}})
            } catch (error) {
              console.log('DeviceListing confirmDeleteDevice error', error)
              this.error = true
              this.errorText = this.$helpers.parseError(error)
            }
          }
          
          this.selectedDevices = []
          this.loading = false
          this.searchDevices(0)
        }
      },
      
      outputControl(devices) {
        this.selectedDevices = devices
        this.bulkAction = 'output_control'
        this.showBulkAction = true
      },
      
      firmwareUpdate(devices) {
        this.selectedDevices = devices
        this.bulkAction = 'firmware_update'
        this.showBulkAction = true
      },
      
      containerInstall(devices) {
        this.selectedDevices = devices
        this.bulkAction = 'container_install'
        this.showBulkAction = true
      },
      
      rebootDevices(devices) {
        this.selectedDevices = devices
        this.bulkAction = 'reboot'
        this.showBulkAction = true
      },
      
    }
  }
</script>