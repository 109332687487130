<template>
  <div v-if="encoder">
    <span v-if="encoder.new" class="text--disabled">Not Created</span>
    <span v-else-if="encoder.status == 'UNABLE_TO_ENCODE'" class="error--text"><v-icon small color="error">warning</v-icon> {{ encoderStatus(encoder) }}</span>
    <span v-else-if="encoder.status != 'RUNNING'" :class="(encoder.new) ? 'text--disabled' : ''">{{ encoderStatus(encoder) }}</span>
    
    <span v-if="encoder.type == 'video' && encoder.status == 'RUNNING'" class="primary--text">{{ $helpers.getLabel(encoder.config.scaling_resolution) }} @ {{ encoder.current_bitrate }} kbps</span>
    <span v-if="encoder.type == 'audio' && encoder.status == 'RUNNING'" class="primary--text">{{ encoder.config.selected_channels.length }} channels @ {{ encoder.config.bitrate }} kbps</span>
    
    <span v-if="encoder.type == 'data' && encoder.active == true" class="primary--text">Active</span>
  </div>
</template>

<script>
  
  export default {
    name: 'EncoderStatusLabel',
    props:['encoder'],
    computed: {
      
    },
    methods: {
      encoderName(item) {
        if (item.config && item.config.name) {
          return this.$helpers.htmlDecode(item.config.name)
        } else if (item.new) {
          return 'New Encoder'
        }
        return 'Encoder #' + item.id
      },
      encoderType(item) {
        var encType = item.type.split('_').join(' ')
        
        if (item.config && item.config.selected_codec) {
          encType += ' (' + this.$helpers.getLabel(item.config.selected_codec) + ')'
        } else if (item.config && item.config.codec) {
          encType += ' (' + this.$helpers.getLabel(item.config.codec) + ')'
        } else if (item.config && item.config.data_type) {
          encType += ' (' + this.$helpers.getLabel(item.config.data_type) + ')'
        }
        
        return encType
      },
      encoderStatus(item) {
        if (item.status) {
          return this.$helpers.getLabel(item.status)
        }
        return ''
      },
    }
  }
</script>