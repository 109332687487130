<template>
  <div>
    <v-dialog
      v-model="showInviteUser"
      max-width="400px"
    >
      <videon-card heading="Invite User" showClose="true" @close="showInviteUser = false">
        <v-card-text>
          <v-form @submit.prevent="inviteUser">
            <p class="mt-3">
              Please enter the email address of the user you wish to invite.
            </p>
          
            <v-text-field
              v-model="inviteUserEmail"
              label="E-mail"
              type="email"
              :error-messages="emailErrors"
              @input="$v.inviteUserEmail.$touch()"
              @blur="$v.inviteUserEmail.$touch()"
              class="mb-2 mt-2"
              required
              outlined
            ></v-text-field>
          
            <v-select
              v-model="newUserPermissions"
              :items="[{'text': 'Read Only', 'value': 100}, {'text': 'User', 'value': 200}, {'text': 'Admin', 'value': 300}]"
              label="Access Level"
              outlined
            ></v-select>
          
          
            <div class="text-right">
              <v-btn
                class="ma-1"
                color="secondary"
                @click="showInviteUser = false"
                text
              >
                Cancel
              </v-btn>
              <v-btn
                class="ma-1"
                color="primary"
                @click="inviteUser"
              >
                Invite
              </v-btn>
            </div>
          </v-form>
        </v-card-text>
      </videon-card>
    </v-dialog>
  
    <v-dialog
      v-model="showEditPermissions"
      max-width="400px"
    >
      <videon-card heading="Edit Device Permissions" showClose="true" @close="showEditPermissions = false">
        <v-card-text>
          <v-form @submit.prevent="editPermsForUser">
            <user-label v-if="editPermsUser" :user="$users.getUser(editPermsUser.user_guid)" class="mb-6 mt-2"/>
          
            <v-select
              v-model="newUserPermissions"
              :items="[{'text': 'Read Only', 'value': 100}, {'text': 'User', 'value': 200}, {'text': 'Admin', 'value': 300}]"
              label="Access Level"
              outlined
            ></v-select>
          
          
            <div class="text-right">
              <v-btn
                class="ma-1"
                color="secondary"
                @click="showEditPermissions = false"
                text
              >
                Cancel
              </v-btn>
              <v-btn
                class="ma-1"
                color="primary"
                @click="editPermsForUser"
              >
                Update
              </v-btn>
            </div>
          </v-form>
        </v-card-text>
      </videon-card>
    </v-dialog>
    
    <v-card flat>
      <v-card-title>
        Device Users
        <v-spacer/>
        <v-btn
          small
          color="primary"
          @click="showInviteUser = true"
          tooltip="Invite User"
          v-if="device.canEdit()"
        >
          <v-icon>mdi-plus</v-icon> Invite User
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-alert dense outlined type="error" v-if="userError">
          {{ userErrorText }}
        </v-alert>
        <v-data-table
          :headers="userHeaders"
          :items="device.users"
          hide-default-footer
          disable-pagination
          :loading="loading"
          :items-per-page="200"
        >
          <template v-slot:item.user_guid="{ item }">
            <user-label :user="$users.getUser(item.user_guid)" />
          </template>
          <template v-slot:item.access="{ item }">
           <access-level-label :access_level="item.access" />
          </template>
          <template v-slot:item.actions="{ item }">
            <div v-if="device.canEdit() && !item.org_guid && !item.fleet_guid">
              <v-btn
                class="ma-1"
                x-small
                outlined
                color="secondary"
                @click="editUser(item)"
              >
                Edit Permissions
              </v-btn>
              <v-btn
                class="ma-1"
                x-small
                outlined
                @click="deleteUser(item)"
              >
                Remove User
              </v-btn>
            </div>
            <div v-if="item.org_guid">
              <v-btn
                class="ma-1"
                x-small
                outlined
                disabled
              >
                Managed by Org
              </v-btn>
            </div>
            <div v-if="item.fleet_guid">
              <v-btn
                class="ma-1"
                x-small
                outlined
                disabled
              >
                Managed by Fleet
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions v-if="hasUserChanges">
        <v-row
           align="center"
           justify="end"
           class="ma-2"
         >
           <v-btn
             color="secondary"
             @click="revertChanges"
             class="ma-1"
             outlined
           >
             Revert
           </v-btn>
           <v-btn
             color="primary"
             @click="saveChanges"
             class="ma-1"
           >
             Save
           </v-btn>
         </v-row>
      </v-card-actions>
    </v-card>

    <v-card flat v-if="device.userInvites.length > 0">
      <v-card-title>
        Pending Invites
        <v-spacer/>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="userHeaders"
          :items="device.userInvites"
          hide-default-footer
          :items-per-page="200"
        >
          <template v-slot:item.user_guid="{ item }">
            <span class="subtitle-2">{{ item.target_email }}</span>
          </template>
          <template v-slot:item.access="{ item }">
            <access-level-label :access_level="item.access" />
          </template>
          <template v-slot:item.actions="{ item }">
            <div v-if="device.canEdit()" class="text-right">
              <v-btn
                class="ma-1"
                x-small
                outlined
                @click="deleteInvite(item)"
              >
                Cancel Invite
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
  import { mapGetters } from 'vuex'
  
  import { validationMixin } from 'vuelidate'
  import { required, email } from 'vuelidate/lib/validators'
  
  export default {
    name: 'DeviceUsers',
    
    props: ['device_guid', 'device'],
    
    mixins: [validationMixin],
    
    data() {
      return {
        loading: false,
        
        userError: false,
        userErrorText: '',
      
        showInviteUser: false,
        inviteUserEmail: '',
        checkExistingUsers: true,
        
        showEditPermissions: false,
        editPermsUser: false,
        newUserPermissions: 100,
        
        usersLoading: false,
        hasUserChanges: false,
      
        userHeaders: [
          { text: 'User', align: 'start', value: 'user_guid', sortable: false },
          { text: 'Access Level', align: 'center', value: 'access', sortable: false },
          { text: '', align: 'end', value: 'actions', sortable: false },
        ],
        
        invitesAddExisting: false,
      }
    },
    
    validations: {
      inviteUserEmail: { required, email },
    },
    
    computed: {
      ...mapGetters('user', ['user', 'guid', 'organization']),
      
      emailErrors () {
        const errors = []
        if (!this.$v.inviteUserEmail.$dirty) return errors
        !this.$v.inviteUserEmail.email && errors.push('Must be valid e-mail')
        !this.$v.inviteUserEmail.required && errors.push('E-mail is required')
        return errors
      },
    },
    
    mounted() {
      this.device.fetchUserInvites()
    },
    
    methods: {
      editUser(item) {
        this.editPermsUser = item
        this.newUserPermissions = item.access
        this.showEditPermissions = true
      },
      
      editPermsForUser() {
        this.showEditPermissions = false
        for (var x in this.device.users) {
          if (this.device.users[x].user_guid == this.editPermsUser.user_guid) {
             // eslint-disable-next-line 
            this.device.users[x].access = this.newUserPermissions
            this.hasUserChanges = true
            return
          }
        }
      },
      
      deleteUser(item) {
        this.loading = true
        
        this.axios.delete('/devices/' + this.device_guid + '/users', {'data': {'users': [item.user_guid]}})
        .then((response) => {
          console.log('DeviceUsers deleteUser ' + this.device_guid + ' response', response)
          
          this.device.fetchUsers()
          
          this.loading = false
        }).catch((error) => {
          console.log('DeviceUsers deleteUser ' + this.device_guid + ' error', error)
          this.userError = true
          this.userErrorText = this.$helpers.parseError(error)
          this.loading = false
        })
      },
      
      saveChanges() {
        this.loading = true
        
        var updateUsers = []
        for (var x in this.device.users) {
          // dont include org/fleet users....
          if (!this.device.users[x].org_guid && !this.device.users[x].fleet_guid) {
            updateUsers.push({'user_guid': this.device.users[x].user_guid, 'access': this.device.users[x].access})
          }
        }
        
        console.log('DeviceUsers saveChanges ' + this.device_guid + ' users', updateUsers)
        
        this.axios.put('/devices/' + this.device_guid + '/users', { 'users': updateUsers })
        .then((response) => {
          console.log('DeviceUsers saveChanges ' + this.device_guid + ' response', response)
          this.hasUserChanges = false
          
          this.device.fetchUsers()
          
          this.loading = false
        }).catch((error) => {
          console.log('DeviceUsers saveChanges ' + this.device_guid + ' error', error)
          this.userError = true
          this.userErrorText = this.$helpers.parseError(error)
          this.loading = false
        })
      },
      
      revertChanges() {
        this.device.fetchUsers()
      },
      
      inviteUser() {
        this.userError = false
        this.userErrorText = ''
        
        if (this.inviteUserEmail) {
          console.log('DeviceUsers inviteUser ' + this.device_guid, this.inviteUserEmail)
          this.showInviteUser = false
          
          this.loading = true
          
          if (this.checkExistingUsers) {
            for (var x in this.device.users) {
              var resolvedUser = this.$users.getUser(this.device.users[x].user_guid)
              if (resolvedUser.email == this.inviteUserEmail) {
                this.userError = true
                this.userErrorText = 'A user with this email already exists on this device.'
                this.loading = false
                return
              }
            }
          }
          
          this.axios.post('/invites', {'device_guid': this.device_guid, 'target_email': this.inviteUserEmail.toLowerCase(), 'access': this.newUserPermissions})
          .then((response) => {
            console.log('DeviceUsers inviteUser ' + this.device_guid + ' response', response)
            
            this.device.fetchUserInvites()
            
            this.inviteUserEmail = ''
            
            this.loading = false
          }).catch((error) => {
            console.log('DeviceUsers inviteUser ' + this.device_guid + ' error', error)
            this.userError = true
            this.userErrorText = this.$helpers.parseError(error)
            this.loading = false
          })
        }
      },
      
      deleteInvite(item) {
        if (item.invite_guid) {
          this.loading = true
      
          this.axios.delete('/invites/' + encodeURIComponent(item.invite_guid), {'data': {'device_guid': this.device_guid}})
          .then((response) => {
            console.log('DeviceUsers deleteInvite ' + this.device_guid + ' response', response)
            
            this.device.fetchUserInvites()
            
            this.loading = false
          }).catch((error) => {
            console.log('DeviceUsers deleteInvite ' + this.device_guid + ' error', error)
            this.userError = true
            this.userErrorText = this.$helpers.parseError(error)
            this.loading = false
          })
        }
      },
      
    }
  }
</script>
