export default {
  computed: { 
    device() {
      return this.$devices.getDevice(this.device_guid)
    },
    
    selectedVideoEncoder() {
      if (this.config && this.config.sources.video.length > 0 && this.config.sources.video[0]) {
        var videoEncoderID = this.config.sources.video[0]
        if (videoEncoderID > 0) {
          return this.device.encoderByID(videoEncoderID)
        }
      }
      return false
    },
    
    videoEncoderHasBackpressureControl() {
      var videoEncoder = this.selectedVideoEncoder
      
      var outputsShadow = this.$deviceShadows.getDeviceShadow(this.device_guid, 'Outputs') 
      var outputs = (outputsShadow.reported) ? outputsShadow.reported.state : []
      
      var backpressureControlledOutputs = outputs.find(x => (
        x.config 
        && x.config.sources.video.length > 0
        && x.config.sources.video[0] == videoEncoder.id
        && x.type == 'zixi'
        && x.id != this.id
        && x.config.enable_back_pressure_bitrate_adjustment
        && x.config.enable_back_pressure_bitrate_adjustment == true
      ))
      
      return (backpressureControlledOutputs) ? true : false
    },
    
    videoEncoderValidation() {
      if (this.videoEncoderHasBackpressureControl) {
        return ['The Video Encoder you’re trying to connect to has its bitrate managed by another output. Using this video encoder for both outputs could have undesirable behavior.']
      }
      return false
    },
  }
}