<template>
  <span>
    <v-dialog
      v-if="device"
      v-model="showControl"
      max-width="440px"
    >
      <videon-card :heading="device.deviceName() + ' Output Control'" showClose="true" @close="showControl = false">
        <v-card-text>
          <h4 class="mb-0">
            Configured Outputs
          </h4>
          <v-list class="mb-2">
            <v-list-item
              v-for="(output, index) in configuredOutputs" 
              v-bind:key="index"
            >
              <v-checkbox
                class="mt-0"
                :disabled="loading"
                :loading="loading"
                v-model="output.config.enable"
                inset
                :label="'Enable ' + outputName(output)"
                hide-details
              >
              </v-checkbox>
            </v-list-item>
          </v-list>
          <div class="text-right">
            <v-btn
              class="ma-1"
              color="secondary"
              @click="showControl = false"
              text
            >
              Cancel
            </v-btn>
            <v-btn
              color="primary"
              class="ma-1"
              @click="updateOutputs"
              :disabled="loading"
              :loading="loading"
            >
              Save
            </v-btn>
          </div>
        </v-card-text>
      </videon-card>
    </v-dialog>
    
    <v-list-item 
      v-if="!button"
      @click="showDialog()"
      :disabled="!device.isOnline()"
    > 
      <v-list-item-title>
        <v-progress-circular
          v-if="loading"
          class="mr-1"
          indeterminate
          color="primary"
          size="23"
          width="2"
        ></v-progress-circular>
        <v-icon v-else>{{ controlIcon }}</v-icon> 
        Output Control
      </v-list-item-title>
    </v-list-item>
    
    <v-btn
      v-else
      small
      color="primary"
      @click="showDialog()"
    >
      Output Control
    </v-btn>
  </span>
</template>

<script>
  export default {
    name: 'OutputControl',
    
    props: ['device', 'button'],
    
    data() {
      return {
        error: false,
        errorText: '',
        loading: false, 
        
        showControl: false,
        
        outputShadow: false,
        documents: []
      }
    },
    mounted() {
      this.outputShadow = this.$deviceShadows.getDeviceShadow(this.device.device_guid, 'Outputs')
      this.loadDocuments()
    },
    watch: {
      device() {
        this.outputShadow = this.$deviceShadows.getDeviceShadow(this.device.device_guid, 'Outputs')
      },
      outputShadow: {
        handler() {
          this.loadDocuments()
        }, 
        deep: true
      }
    },
    computed: {
      configuredOutputs() {
        if (this.documents) {
          return this.documents.filter(output => (this.$helpers.outputStatus(output).configured === true && output.type != 'thumbnail'))
        }
        return []
      },
      online() {
        return this.device.isOnline()
      },
      outputsState() {
        return this.device.outputsState()
      },
      isStreaming() {
        if (this.outputsState && this.outputsState.filter(output => this.$helpers.outputStatus(output).enabled === true).length > 0) {
          return true
        }
        return false
      },
      controlIcon() {
        if (this.isStreaming) {
          return 'stop'
        } 
        return 'play_arrow'
      },
    },

    methods: {
      showDialog() {
        this.device.resetCommandState()
        this.showControl = true
      },
      outputName(item) {
        if (item.config.name) {
          return this.$helpers.htmlDecode(item.config.name)
        }
        
        var outputName = 'Output #' + item.id
        if (item.type) {
          outputName += ' (' + this.$helpers.getLabel(item.type) + ')'
        }
        return outputName
      },
      
      loadDocuments() {
        this.documents = this.outputShadow.editableStateCopy()
      },
      updateOutputs() {
        if (this.documents) {
          this.loading = true
          // enumerate configured outputs and build an array of id & enabled
          var outputs = this.configuredOutputs.map((output) => {
            return {'output_id': output.id, 'enable': output.config.enable}
          })
          
          // post to outputs endpoint
          this.axios.post('/devices/' + this.device.device_guid + '/outputs', outputs)
          .then((response) => {
            console.log('OutputControl updateOutputs ' + this.device.device_guid + ' response', response)
            
            // fetch device state after waiting 5s
            setTimeout(() => {
              this.loading = false
              this.showControl = false
              
              this.device.syncDocuments()
            }, 5000)
          }).catch((error) => {
            this.error = true
            this.errorText = this.$helpers.parseError(error)
            console.log('OutputControl updateOutputs ' + this.device.device_guid + ' error', error)
          })
        }
      }
    }
  }
</script>