<template>
  <v-card 
    class="mx-auto"
  > 
    <v-dialog
      v-model="showDeleteLicense"
      max-width="400px"
      v-if="this.selectedLicense"
    >
      <videon-card heading="Delete License" showClose="true" @close="showDeleteLicense = false">
        <v-card-text>
          <p class="subtitle-1 mt-3">
            Are you sure you want to delete this license?
          </p>
          <v-form @submit.prevent="confirmDeleteLicense">
            <v-text-field
              v-model="deleteLicenseReason"
              label="Reason"
              required
              outlined
            ></v-text-field>
            <div class="text-right">
              <v-btn
                class="ma-1"
                color="secondary"
                @click="showDeleteLicense = false"
                text
              >
                Cancel
              </v-btn>
              <v-btn
                class="ma-1"
                color="primary"
                @click="confirmDeleteLicense"
              >
                Delete
              </v-btn>
            </div>
          </v-form>
        </v-card-text>
      </videon-card>
    </v-dialog>
    
    <v-dialog
      v-model="showCreateLicense"
      max-width="640px"
    >
      <videon-card :heading="(section == 'org') ? 'Create Org License' : 'Create Device License'" showClose="true" @close="showCreateLicense = false">
        <v-card-text>
          <v-form @submit.prevent="createLicense">
            
            <org-selector 
              v-model="newLicense.org_guid" 
              class="mb-5" 
              v-if="section == 'org'"
            />
            
            <v-text-field
              v-if="section == 'serial'"
              autocomplete="off"
              v-model="newLicense.serial_number"
              label="Device Serial"
              required
              outlined
            ></v-text-field>
            
            <profile-selector v-model="newLicense.profile_guid" />
                        
            <v-text-field
              autocomplete="off"
              v-model="newLicense.license_name"
              label="License Name"
              class="mt-10"
              outlined
              hide-details
            ></v-text-field>
            
            <v-text-field
              autocomplete="off"
              v-model="newLicense.sales_order"
              label="Sales Order"
              class="mt-5 mb-10"
              outlined
              hide-details
            ></v-text-field>
                        
            <div v-if="section == 'org'">
              <div v-if="!newLicenseEntitlement || newLicenseEntitlement.profile_platform !== 'cloud_ui'">
                <v-checkbox 
                  v-model="newLicense.unlimited"
                  label="Unlimited Devices"
                />
                <v-text-field
                  v-if="!newLicense.unlimited"
                  autocomplete="off"
                  v-model="newLicense.allocation_limit"
                  label="Device License Count (example: 10)"
                  required
                  outlined
                  hide-details
                ></v-text-field>
              </div>
            </div>
            
            <v-text-field
              autocomplete="off"
              v-model="newLicense.lifespan_days"
              label="License Length (days)"
              type="number"
              class="mt-5"
              outlined
              hide-details
            ></v-text-field>
            
            <div class="text-right mt-5">
              <v-btn
                class="ma-1"
                color="primary"
                @click="createLicense"
              >
                Create
              </v-btn>
            </div>
          </v-form>
        </v-card-text>
      </videon-card>
    </v-dialog>
    
    <v-dialog
      v-model="showExtendLicense"
      max-width="400px"
      v-if="this.selectedLicense"
    >
      <videon-card heading="Extend License" showClose="true" @close="showExtendLicense = false">
        <v-card-text>
          <p class="subtitle-1 mt-3">
            How many additional days do you wish to add to this license?
          </p>
          <v-form @submit.prevent="confirmDeleteLicense">
            <v-text-field
              v-model="extendAdditionalDays"
              label="Additional Days"
              type="number"
              required
              outlined
            ></v-text-field>
            <div class="text-right">
              <v-btn
                class="ma-1"
                color="secondary"
                @click="showExtendLicense = false"
                text
              >
                Cancel
              </v-btn>
              <v-btn
                class="ma-1"
                color="primary"
                @click="renewLicense"
              >
                Extend
              </v-btn>
            </div>
          </v-form>
        </v-card-text>
      </videon-card>
    </v-dialog>
    
    
    <v-toolbar flat>
      <v-toolbar-title>
        <v-avatar
          height="32px"
          class="mx-auto"
        >
          <videon-logo />
        </v-avatar>
        License Manager
      </v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    
    <v-divider/>
    
    <v-tabs
      v-model="section"
      color="secondary"
    >
      <v-tabs-slider></v-tabs-slider>
      <v-tab href="#org">
        By Organization
      </v-tab>
      <v-tab href="#serial">
        By Device Serial
      </v-tab>
    </v-tabs>
    
    <v-divider/>
    
    <v-card-text>
      <v-card flat>
        <v-card-title>
          Licenses
          <v-spacer/>
          <v-btn
            small
            color="primary"
            @click="showCreateLicense = true"
            tooltip="Create License"
          >
            <v-icon>mdi-plus</v-icon> Generate License
          </v-btn>
        </v-card-title>
        
        <v-card-text>
          <v-alert dense outlined type="error" v-if="error" >
            {{ errorText }}
          </v-alert>
          
          <v-row>
            <v-col cols="12">
              <v-data-table
                class="fixHeaders"
                :headers="headers"
                :items="items"
                :loading="loading"
                :page.sync="page"
                :items-per-page="itemsPerPage"
                hide-default-footer
              >
                <template v-slot:item.org_guid="{ item }">
                  <span class="subtitle-1">{{ orgName(item) }}</span>
                </template>
                <template v-slot:item.serial_number="{ item }">
                  {{ item.serial_number }}
                </template>
                <template v-slot:item.profile_guid="{ item }">
                  <span class="">{{ entitlementProfileName(item) }}</span>
                </template>
                <template v-slot:item.unlimited="{ item }">
                  {{ licenseTypeLabel(item) }}
                </template>
                <template v-slot:item.profile_platform="{ item }">
                  {{ $helpers.getLabel(item.profile_platform) }}
                </template>
                <template v-slot:item.created="{ item }">
                  <span>{{ item.created | moment("YYYY/MM/DD") }}</span>
                </template>
                <template v-slot:item.expires="{ item }">
                  <span v-if="item.expires">{{ item.expires | moment("YYYY/MM/DD") }}</span>
                  <span v-else>Never</span>
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-btn
                    v-if="item.expires"
                    class="ma-1"
                    x-small
                    outlined
                    color="secondary"
                    @click="extendLicense(item)"
                  >
                    Extend License
                  </v-btn>
                  <v-btn
                    class="ma-1"
                    x-small
                    outlined
                    @click="deleteLicense(item)"
                  >
                    Delete License
                  </v-btn>
                </template>
              </v-data-table>
              <data-table-pagination v-model="page" :paginationTokens="paginationTokens" />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-card-text>
  </v-card>
</template>

<script> 
  import ProfileSelector from './components/ProfileSelector.vue'
  import OrgSelector from '../organization/OrgSelector.vue'
  
  export default {
    name: 'AdminLicenseManager',
    components: {
      ProfileSelector,
      OrgSelector
    },
    data() {
      return {
        section: 'org',
        
        error: false,
        errorText: '',
        
        showCreateLicense: false,
        newLicense: { 'org_guid': null, 'serial_number': null, 'profile_guid': null, 'license_name': '', 'sales_order': '', 'unlimited': null, 'allocation_limit': null, 'lifespan_days': null},
        
        showDeleteLicense: false,
        deleteLicenseReason: '',
        
        showEditLicense: false,
        
        showExtendLicense: false,
        extendAdditionalDays: 30, 
        
        selectedLicense: false,
        
        loading: false,
        
        items: [],
      
        itemsPerPage: 50,
        page: 1,
        pageCount: 0,
      
        paginationTokens: []
      }
    },
    mounted() {
      this.loadLicenses(0)
    },
    watch: {
      section() {
        this.loadLicenses(this.page - 1)
      },
      page() {
        this.loadLicenses(this.page - 1)
      },
      itemsPerPage() {
        this.items = []
        this.page = 1
        this.loadLicenses(this.page - 1)
      },
    },
    computed: {
      headers() {
        var headers = []
        
        if (this.section == 'org') {
          headers.push({ text: 'Organization', align: '', value: 'org_guid', sortable: true })
        } else {
          headers.push({ text: 'Serial Number', align: '', value: 'serial_number', sortable: true })
        }
        
        headers.push({ text: 'License Name', align: '', value: 'license_name', sortable: true })
        headers.push({ text: 'Sales Order', align: '', value: 'sales_order', sortable: true })
        
        headers.push({ text: 'Entitlement Profile', align: '', value: 'profile_guid', sortable: true })
        headers.push({ text: 'License Type', align: '', value: 'unlimited', sortable: true })
        headers.push({ text: 'License Platform', align: '', value: 'profile_platform', sortable: true })
        headers.push({ text: 'Issue Date', align: '', value: 'created', sortable: true })
        headers.push({ text: 'Expiration Date', align: 'center', value: 'expires', sortable: true })
        headers.push({ text: '', align: 'end', value: 'actions', sortable: false })
        
        return headers
      },

      newLicenseEntitlement() {
        var guid = this.newLicense.profile_guid

        if (guid) {
          return this.$entitlementProfiles.getEntitlementProfile(guid)
        }

        return false
      }
    },
    methods: {
      orgName(item) {
        if (item.org_guid) {
           return this.$organizations.getOrg(item.org_guid).displayName()
        }
        return 'Org GUID Missing'
      }, 
      
      entitlementProfileName(item) {
        if (item.profile_guid) {
           return this.$entitlementProfiles.getEntitlementProfile(item.profile_guid).displayName()
        }
        return 'Profile GUID Missing'
      },
      
      licenseTypeLabel(item) {
        if (item.unlimited) {
          return 'Unlimited License'
        } else if (item.allocation_limit) {
          return 'Per-Device License (' + item.allocation_limit + ' allocated, ' + item.allocated + ' in use)'
        } else if (item.serial_number) {
          return 'Base License'
        }
        return 'Unknown'
      },
      
      loadLicenses(paginationIndex = 0) {
        console.log('AdminLicenseManager loadLicenses section', this.section)
        
        this.loading = true
        this.items = []
        
        const paginationToken = this.paginationTokens[paginationIndex - 1]
        
        var queryParams = {
          'pagination_size': this.itemsPerPage
        }
        
        if (paginationToken) {
          queryParams.pagination_token = paginationToken
        }
        
        if (this.section == 'org') {
          queryParams.show_orgs = true
          queryParams.show_serials = false
        } else if (this.section == 'serial') {
          queryParams.show_orgs = false
          queryParams.show_serials = true
        }
        
        this.axios.get('/entitlements/licenses', {'params': queryParams}).then((response) => {
          console.log('AdminLicenseManager loadLicenses response', response)
          
          if (response.data.licenses.length > 0) {
            this.items = response.data.licenses
            
            if (response.data.pagination_token) {
              this.$set(this.paginationTokens, paginationIndex, response.data.pagination_token)
            }
          }
          this.loading = false
        }).catch((error) => {
          console.log('AdminLicenseManager loadLicenses error', error)
          this.error = true
          this.errorText = this.$helpers.parseError(error)
          this.loading = false
        })
      },

      createLicense() {
        console.log('AdminLicenseManager createLicense', this.newLicense)

        // For an organization license, it must be either for the cloud, unlimited, or have a number of devices it can be applied to
        var orgTargetSpecified = this.newLicenseEntitlement.profile_platform === 'cloud_ui' || this.newLicense.unlimited || this.newLicense.allocation_limit
        // A license's 'target' are the devices that it can be applied to, so for an organization-based license its orgTargetSpecified
        // and for a serial license its the serial number
        var targetSpecified = (this.newLicense.org_guid && orgTargetSpecified) || this.newLicense.serial_number
        
        if (this.newLicense.profile_guid && targetSpecified) {
          this.loading = true
          this.showCreateLicense = false
          
          this.error = false
          this.errorText = ''
          
          var newLicense = { 'profile_guid': this.newLicense.profile_guid } 
          
          if (this.section == 'org') {
            newLicense.org_guid = this.newLicense.org_guid
            if (this.newLicense.unlimited || this.newLicenseEntitlement.profile_platform === 'cloud_ui') {
              newLicense.unlimited = true
            } else {
              if (this.newLicense.allocation_limit) {
                newLicense.allocation_limit = parseInt(this.newLicense.allocation_limit)
              }
            }
          } else {
            newLicense.serial_number = this.newLicense.serial_number
          }
          
          if (this.newLicense.license_name) {
            newLicense.license_name = this.newLicense.license_name
          }
          
          if (this.newLicense.sales_order) {
            newLicense.sales_order = this.newLicense.sales_order
          }
          
          if (this.newLicense.lifespan_days) {
            newLicense.lifespan_days = parseInt(this.newLicense.lifespan_days)
          }
          
          this.axios.post('/entitlements/licenses', newLicense)
          .then((response) => {
            console.log('AdminLicenseManager createLicense response', response)
            this.loadLicenses(0)
          }).catch((error) => {
            console.log('AdminLicenseManager createLicense error', error)
            
            this.error = true
            this.errorText = this.$helpers.parseError(error)
            
            this.loading = false
          })
          
        }
      },
      
      extendLicense(item) {
        console.log('AdminLicenseManager extendLicense', item)
        this.selectedLicense = item
        this.showExtendLicense = true
      },
      
      renewLicense() {
        this.loading = true
        this.showExtendLicense = false
        
        this.error = false
        this.errorText = ''
        
        console.log('AdminLicenseManager renewLicense', this.selectedLicense.license_id, this.extendAdditionalDays)
        
        this.axios.patch('/entitlements/licenses/' + encodeURIComponent(this.selectedLicense.license_id), { 'additional_lifespan_days': parseInt(this.extendAdditionalDays) })
        .then((response) => {
          console.log('AdminLicenseManager renewLicense response', response)
          this.loadLicenses(0)
        }).catch((error) => {
          console.log('AdminLicenseManager renewLicense error', error)
        
          this.error = true
          this.errorText = this.$helpers.parseError(error)
        
          this.loading = false
        })
        
      },
      
      deleteLicense(item) {
        console.log('AdminLicenseManager deleteLicense', item)
        this.selectedLicense = item
        this.showDeleteLicense = true
      },
      
      confirmDeleteLicense() {
        if (this.selectedLicense.license_id && this.deleteLicenseReason) {
          console.log('AdminLicenseManager confirmDeleteLicense', this.selectedLicense)
          
          this.showDeleteLicense = false
          this.loading = true
          
          this.error = false
          this.errorText = ''
          
          this.axios.delete('/entitlements/licenses/' + encodeURIComponent(this.selectedLicense.license_id), {'data': {'reason': this.deleteLicenseReason}})
          .then((response) => {
            console.log('AdminLicenseManager confirmDeleteLicense response', response)
            
            this.selectedLicense = false
            this.deleteLicenseReason = ''
            
            this.loadLicenses(0)
            
            this.error = false
            this.errorText = ''
            
            this.loading = false
          }).catch((error) => {
            console.log('AdminLicenseManager confirmDeleteLicense error', error)
        
            this.error = true
            this.errorText = this.$helpers.parseError(error)
            
            this.loading = false
          })
        }
      }
      
    }
  }
</script>
<style lang="css">
.fixHeaders th {
  min-width: 160px !important;
}
</style>