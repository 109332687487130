<template>
  <v-btn
    x-small
    outlined
    color="accent"
    @click="showDialog"
  >
    Entitlement JSON
    <v-dialog
      v-if="device"
      v-model="showEntitlementDebug"
      max-width="90%"
    >
      <videon-card heading="Device Entitlements JSON" showClose="true" @close="showEntitlementDebug = false">
        <v-card-text>
          <v-alert dense outlined type="error" v-if="error" >
            {{ errorText }}
          </v-alert>
          <div v-if="loading">
            <v-progress-linear
              color="primary"
              indeterminate
              buffer-value="0"
              class="mb-3"
            ></v-progress-linear>
          </div>

          <v-row>
            <v-col>
              <v-text-field
                v-model="firmwareVersion"
                label="Firmware Version"
                outlined
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="1">
              <v-btn
                color="primary"
                @click="fetchEntitlement"
              >Fetch</v-btn>
            </v-col>
          </v-row>


          <pre v-if="entitlement" style="white-space: pre-wrap;">{{ entitlement }}</pre>
          <div class="text-right">
            <v-btn
              class="ma-1"
              color="primary"
              @click="showEntitlementDebug = false"
            >
              Close
            </v-btn>
          </div>
        </v-card-text>
      </videon-card>
    </v-dialog>
  </v-btn>
</template>

<script>
  export default {
    name: 'EntitlementDebug',
    
    props: ['device'],
    
    data() {
      return {
        minimumAgentVersion: this.$helpers.parseVersion('cloud', 'V1.0.0b12'), // must be 1 revision less than target
        
        error: false,
        errorText: '',
        
        loading: false,
        showEntitlementDebug: false,

        firmwareVersion: '',
        
        entitlement: false
      }
    },
    mounted() {
      this.firmwareVersion = this.firmwareVersion || this.device.firmware_version.substring(1)
    },
    computed: {
      
    },

    methods: {
      showDialog() {
        this.fetchEntitlement()
        this.showEntitlementDebug = true
      },
      fetchEntitlement() {
        this.loading = true

        var versionQueryParam = this.firmwareVersion
        
        if (!versionQueryParam && this.device.firmware_version != 'Unknown') {
          versionQueryParam = this.device.firmware_version.substring(1)
        }

        versionQueryParam = '?version=' + versionQueryParam
        
        this.axios.get('/entitlements/devices/' + this.device.device_guid + versionQueryParam)
        .then((response) => {
          console.log('EntitlementDebug ' + this.device.device_guid + ' fetchEntitlement response', response)
          
          this.entitlement = response.data
          
          this.error = false
          this.loading = false
        }).catch((error) => {
          this.error = true
          this.errorText = this.$helpers.parseError(error)
          this.loading = false
        })
      }
    }
  }
</script>