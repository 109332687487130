<template>
  <div>
    <v-dialog
      v-model="showAddDevices"
      max-width="90%"
    >
      <videon-card heading="Attach Devices to License" showClose="true" @close="showAddDevices = false">
        <v-card-text>
          <v-form @submit.prevent="addDevices">
            
            <device-selector
              v-model="selectedDevices"
              :disableExisting="true" :existing="items"
              :selectionLimit="allocationLimit"
              :productNames="[license.profile_platform]"
            />
            
            <div class="text-right">
              <v-btn
                class="ma-1"
                color="secondary"
                @click="showAddDevices = false"
                text
              >
                Cancel
              </v-btn>
              <v-btn
                class="ma-1"
                color="primary"
                @click="attachDevices"
              >
                Attach Devices
              </v-btn>
            </div>
          </v-form>
        </v-card-text>
      </videon-card>
    </v-dialog>
    
    
    <v-row class="ma-2">
      <videon-metric-tile color="info" heading="Licenses Available" icon="devices" :description="(license.allocation_limit) ? '' + license.allocation_limit : 'Unlimited'" width="30%" large class="ma-1" />
      <videon-metric-tile color="info" heading="Licenses Attached" icon="devices" :description="'' + items.length" width="30%" large class="ma-1" />
    </v-row>
    
    <v-card flat>
      <v-card-title>
        Attached {{ $helpers.getLabel(license.profile_platform) }} Devices
        <v-spacer/>
        <v-btn
          small
          color="primary"
          @click="showAddDevices = true"
          tooltip="Attach Devices to Licese"
          v-if="canAddDevices"
        >
          <v-icon>mdi-plus</v-icon> Attach Devices
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-alert dense outlined type="error" v-if="error">
          {{ errorText }}
        </v-alert>
      
        <v-row>
          <v-col cols="12">
            <v-data-table
              :headers="headers"
              :items="items"
              :loading="loading"
              :page.sync="page"
              :items-per-page="itemsPerPage"
              hide-default-footer
            >
              <template v-slot:item.device_guid="{ item }">
                <span class="subtitle-1">
                  <device-label :device="$devices.getDevice(item.device_guid)" :clickable="false" />
                </span>
              </template>
              <template v-slot:item.pending_deallocation="{ item }">
                {{ licenseStatusLabel(item) }}
              </template>
              <template v-slot:item.actions="{ item }">
                <div class="text-right">
                  <v-btn
                    class="ma-1"
                    x-small
                    outlined
                    @click="detachDevice(item)"
                  >
                    Detach Device
                  </v-btn>
                </div>
              </template>
            </v-data-table>
            <data-table-pagination v-model="page" :paginationTokens="paginationTokens" />
          </v-col>
        </v-row>
        

      </v-card-text>
    </v-card>
    <div class="text-right mt-5">
      <v-btn
        class="ma-1"
        color="primary"
        @click="$emit('close')"
      >
        Close
      </v-btn>
    </div>
  </div>
</template>

<script>
  import DeviceSelector from '../device/components/DeviceSelector.vue'
  
  export default {
    name: 'LicenseAllocations',
    
    components: {
      DeviceSelector,
    },
    
    data() {
      return {
        error: false,
        errorText: '',
        
        loading: false,
        
        headers: [
          { text: 'Device', align: '', value: 'device_guid', sortable: false },
          { text: 'Status', align: '', value: 'pending_deallocation', sortable: false },
          { text: '', align: 'end', value: 'actions', sortable: false }
        ],
        
        items: [],
        
        itemsPerPage: 50,
        page: 1,
        pageCount: 0,
      
        paginationTokens: [],
        
        showAddDevices: false,
        selectedDevices: [],
        
      }
    },

    props: ['license'],

    watch: {
      license() {
        this.loadAllocations(0)
      },
      page() {
        this.loadAllocations(this.page - 1)
      },
      itemsPerPage() {
        this.items = []
        this.page = 1
        this.loadAllocations(this.page - 1)
      },
    },  
    
    computed: {
      canAddDevices() {
        if (this.license && this.items.length < this.license.allocation_limit) {
          return true
        }
        return false
      },
      allocationLimit() {
        if (this.license && this.license.allocation_limit) {
          return this.license.allocation_limit
        }
        return 0
      }
    },
    
    mounted() {
      this.loadAllocations(0)
    },
    
    methods: {
      licenseStatusLabel(item) {
        if (item.pending_deallocation) {
          return 'Pending De-Allocation'
        }
        return 'Allocated'
      },
      loadAllocations(paginationIndex = 0) {
        if (!this.license || !this.license.license_id) {
          return
        }
        
        console.log('LicenseAllocations loadAllocations', this.license.license_id)
        this.loading = true
        
        this.error = false
        this.errorText = ''
        
        this.items = []
        
        const paginationToken = this.paginationTokens[paginationIndex - 1]
        
        var queryParams = {
          'pagination_size': this.itemsPerPage,
        }
        
        if (paginationToken) {
          queryParams.pagination_token = paginationToken
        }
        
        this.axios.get('/entitlements/licenses/' + encodeURIComponent(this.license.license_id) + '/allocations', {'params': queryParams}).then((response) => {
          console.log('LicenseAllocations loadAllocations response', response)
          
          if (response.data.allocations.length > 0) {
            this.items = response.data.allocations
            this.selectedDevices = this.items.map((device) => this.$devices.getDevice(device.device_guid))
            
            if (response.data.pagination_token) {
              this.$set(this.paginationTokens, paginationIndex, response.data.pagination_token)
            }
          }
          
          this.loading = false
        }).catch((error) => {
          console.log('LicenseAllocations loadAllocations error', error)
          this.error = true
          this.errorText = this.$helpers.parseError(error)
          this.loading = false
        })
      },
      
      attachDevices() {
        if (this.selectedDevices.length > 0) { 
          this.loading = true
          this.showAddDevices = false
          
          var addDeviceGuids = this.selectedDevices.map(device => device.device_guid)
          var existingDeviceGuids = this.items.map(device => device.device_guid)
          
          var deviceGuids = addDeviceGuids.filter(x => !existingDeviceGuids.includes(x))
          console.log('addDevices', deviceGuids)
          
          this.axios.post('/entitlements/licenses/' + encodeURIComponent(this.license.license_id) + '/allocations', {'devices': deviceGuids}).then((response) => {
            console.log('LicenseAllocations attachDevices response', response)
            
            this.selectedDevices = []
            this.loadAllocations(0)
            
            this.error = false
            this.errorText = ''
            this.loading = false
          }).catch((error) => {
            this.error = true
            this.errorText = this.$helpers.parseError(error)
            this.loading = false
          })
        }
      },
      
      detachDevice(item) {
        if (item.device_guid) {
          this.loading = true
          console.log('LicenseAllocations detachDevice', item)
          
          this.axios.delete('/entitlements/licenses/' + encodeURIComponent(this.license.license_id) + '/allocations', {'data': {'devices': [ item.device_guid ]}}).then((response) => {
            console.log('LicenseAllocations detachDevice response', response)
            
            this.loadAllocations(0)
            
            this.error = false
            this.errorText = ''
            this.loading = false
          }).catch((error) => {
            this.error = true
            this.errorText = this.$helpers.parseError(error)
            this.loading = false
          })
        }
      },
    }
  }
</script>