<template>
  <v-card flat>
    
    <v-dialog
      v-model="showDeleteOutput"
      max-width="480px"
    >
      <videon-card heading="Delete Output" showClose="true" @close="showDeleteOutput = false">
        <v-card-text>
          <v-alert
            type="info"
            text
            icon="mdi-lock-outline"
            class="mt-3"
            v-if="!isDynamicSupported"
          >
            Adding and removing outputs dynamically requires a minimum of daemon version {{ $versioning.dynamicSupportRequirements.minimumDaemonVersion.original }} 
            and cloud agent version {{ $versioning.dynamicSupportRequirements.minimumAgentVersion.original }}.
          </v-alert>
          <v-form v-else @submit.prevent="confirmDeleteOutput">
            <p class="subtitle-1 mt-3">
              Are you sure you want to delete this output?
            </p>
            <div class="text-right">
              <v-btn
                class="ma-1"
                color="secondary"
                @click="showDeleteOutput = false"
                text
              >
                Cancel
              </v-btn>
              <v-btn
                class="ma-1"
                color="primary"
                @click="confirmDeleteOutput"
              >
                Delete
              </v-btn>
            </div>
          </v-form>
        </v-card-text>
      </videon-card>
    </v-dialog>
    
    <v-dialog
      v-model="showNewOutput"
      max-width="640px"
    >
      <videon-card heading="Add New Output" showClose="true" @close="showNewOutput = false">
        <v-card-text>
          <v-alert
            type="info"
            text
            icon="mdi-lock-outline"
            class="mt-3"
            v-if="!isDynamicSupported"
          >
            Adding and removing outputs dynamically requires newer software. Please update to at least daemon version <strong>{{ $versioning.dynamicSupportRequirements.minimumDaemonVersion.original }}</strong>
            and cloud agent version <strong>{{ $versioning.dynamicSupportRequirements.minimumAgentVersion.original }}</strong>.
          </v-alert>
          
          <v-form v-else @submit.prevent="createOutput">
            <v-select
              v-model="newOutput.type"
              :items="newOutputTypes"
              @change="newOutputTypeChange"
              label="Output Type"
              outlined
              hide-details
            ></v-select>
            
            <div class="mt-5" v-if="newOutput">
              <multicast-output-document v-if="newOutput.type == 'multicast'" :output="newOutput" :device_guid="device_guid" :canEdit="canEdit" :isNew="true" @configUpdate="newOutputUpdate" :key="newOutput.id"/>
              <unicast-output-document v-else-if="newOutput.type == 'unicast'" :output="newOutput" :device_guid="device_guid" :canEdit="canEdit" :isNew="true" @configUpdate="newOutputUpdate" :key="newOutput.id"/>
              <rtmp-output-document v-else-if="newOutput.type == 'rtmp'" :output="newOutput" :device_guid="device_guid" :canEdit="canEdit" :isNew="true" @configUpdate="newOutputUpdate" :key="newOutput.id"/>
              <file-output-document v-else-if="newOutput.type == 'file'" :output="newOutput" :device_guid="device_guid" :canEdit="canEdit" :isNew="true" @configUpdate="newOutputUpdate" :key="newOutput.id"/>
              <rtsp-output-document v-else-if="newOutput.type == 'rtsp'" :output="newOutput" :device_guid="device_guid" :canEdit="canEdit" :isNew="true" @configUpdate="newOutputUpdate" :key="newOutput.id"/>
              <http-pull-output-document v-else-if="newOutput.type == 'http_pull'" :output="newOutput" :device_guid="device_guid" :canEdit="canEdit" :isNew="true" @configUpdate="newOutputUpdate" :key="newOutput.id"/>
              <http-push-output-document v-else-if="newOutput.type == 'http_push'" :output="newOutput" :device_guid="device_guid" :canEdit="canEdit" :isNew="true" @configUpdate="newOutputUpdate" :key="newOutput.id"/>
              <srt-output-document v-else-if="newOutput.type == 'srt'" :output="newOutput" :device_guid="device_guid" :canEdit="canEdit" :isNew="true" @configUpdate="newOutputUpdate" :key="newOutput.id"/>
              <zixi-output-document v-else-if="newOutput.type == 'zixi'" :output="newOutput" :device_guid="device_guid" :canEdit="canEdit" :isNew="true" @configUpdate="newOutputUpdate" :key="newOutput.id"/>
              <thumbnail-output-document v-else-if="newOutput.type == 'thumbnail'" :output="newOutput" :device_guid="device_guid" :canEdit="canEdit" :isNew="true" @configUpdate="newOutputUpdate" :key="newOutput.id"/>
              <whip-output-document v-else-if="newOutput.type == 'whip'" :output="newOutput" :device_guid="device_guid" :canEdit="canEdit" :isNew="true" @configUpdate="newOutputUpdate" :key="newOutput.id"/>
              <generic-output-document v-else :output="item" :device_guid="device_guid" @deleteOutput="deleteOutput(item)" :canEdit="canEdit" :isNew="true" @configUpdate="newOutputUpdate" :key="newOutput.id"/>
            </div>
            
            <div class="text-right">
              <v-btn
                class="ma-1"
                color="secondary"
                @click="showNewOutput = false"
                text
              >
                Cancel
              </v-btn>
              <v-btn
                class="ma-1"
                color="primary"
                @click="createOutput"
                :disabled="newOutputHasValidationErrors"
              >
                Create Output
              </v-btn>
            </div>
          </v-form>
        </v-card-text>
      </videon-card>
    </v-dialog>
    
    <v-card-title>
      Output Configuration
      <v-spacer/>
      <v-btn
        small
        color="primary"
        tooltip="New Output"
        @click="newOutputShow()"
        :disabled="!canEdit"
      >
        <v-icon>mdi-plus</v-icon> New Output
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-alert type="info" v-if="xmlConfig">These settings are currently managed by the active XML configuration.</v-alert>
      <v-data-table
        :headers="headers"
        :items="documents"
        :single-expand="true"
        :expanded.sync="expanded"
        item-key="id"
        :items-per-page="100"
        show-expand
        hide-default-footer
        @click:row="(item, slot) => slot.expand(!slot.isExpanded)"
      >
        <template
          v-slot:item.id="{ item }"
        >
          <span :class="(item.new) ? 'text--disabled' : ''">{{ outputName(item) }}</span>
        </template>
        <template
          v-slot:item.type="{ item }"
        >
          <span :class="(item.new) ? 'text--disabled' : ''">{{ outputType(item) }}</span>
        </template>
        <template
          v-slot:item.status="{ item }"
        >
          <span v-if="item.new" class="text--disabled">Not Created</span>
          <output-status-label v-else :output="item" />
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length" class="px-8 pt-8 pb-5">
            <multicast-output-document v-if="item.type == 'multicast'" :output="item" :device_guid="device_guid" :canEdit="canEdit" @deleteOutput="deleteOutput(item)"  @configUpdate="configUpdate" :key="item.id"/>
            <unicast-output-document v-else-if="item.type == 'unicast'" :output="item" :device_guid="device_guid" :canEdit="canEdit" @deleteOutput="deleteOutput(item)" @configUpdate="configUpdate" :key="item.id"/>
            <rtmp-output-document v-else-if="item.type == 'rtmp'" :output="item" :device_guid="device_guid" :canEdit="canEdit" @deleteOutput="deleteOutput(item)" @configUpdate="configUpdate" :key="item.id"/>
            <file-output-document v-else-if="item.type == 'file'" :output="item" :device_guid="device_guid" :canEdit="canEdit" @deleteOutput="deleteOutput(item)" @configUpdate="configUpdate" :key="item.id"/>
            <rtsp-output-document v-else-if="item.type == 'rtsp'" :output="item" :device_guid="device_guid" :canEdit="canEdit" @deleteOutput="deleteOutput(item)" @configUpdate="configUpdate" :key="item.id"/>
            <http-pull-output-document v-else-if="item.type == 'http_pull'" :output="item" :device_guid="device_guid" :canEdit="canEdit" @deleteOutput="deleteOutput(item)" @configUpdate="configUpdate" :key="item.id"/>
            <http-push-output-document v-else-if="item.type == 'http_push'" :output="item" :device_guid="device_guid" :canEdit="canEdit" @deleteOutput="deleteOutput(item)" @configUpdate="configUpdate" :key="item.id"/>
            <srt-output-document v-else-if="item.type == 'srt'" :output="item" :device_guid="device_guid" :canEdit="canEdit" @deleteOutput="deleteOutput(item)" @configUpdate="configUpdate" :key="item.id"/>
            <zixi-output-document v-else-if="item.type == 'zixi'" :output="item" :device_guid="device_guid" :canEdit="canEdit" @deleteOutput="deleteOutput(item)" @configUpdate="configUpdate" :key="item.id"/>
            <whip-output-document v-else-if="item.type == 'whip'" :output="item" :device_guid="device_guid" :canEdit="canEdit" @deleteOutput="deleteOutput(item)" @configUpdate="configUpdate" :key="item.id"/>
            <thumbnail-output-document v-else-if="item.type == 'thumbnail'" :output="item" :device_guid="device_guid" :canEdit="canEdit" @deleteOutput="deleteOutput(item)" @configUpdate="configUpdate" :key="item.id"/>
            <generic-output-document v-else :output="item" :device_guid="device_guid" @deleteOutput="deleteOutput(item)" :canEdit="canEdit" @configUpdate="configUpdate" :key="item.id"/>
          </td>
        </template>
      </v-data-table>
      <div class="mt-5 text-right" v-if="canEdit">
        <v-btn
          color="secondary"
          text
          class="ma-1"
          @click="revertShadow()"
          :disabled="!hasChanges"
        >
          Revert
        </v-btn>
        <v-btn
          color="primary"
          class="ma-1"
          @click="saveShadow()"
          :disabled="hasChanges == false || hasValidationError == true"
        >
          Save
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
  import GenericOutputDocument from './documents/GenericOutputDocument.vue'
  import MulticastOutputDocument from './documents/MulticastOutputDocument.vue'
  import UnicastOutputDocument from './documents/UnicastOutputDocument.vue'
  import RtmpOutputDocument from './documents/RTMPOutputDocument.vue'
  import FileOutputDocument from './documents/FileOutputDocument.vue'
  import RtspOutputDocument from './documents/RTSPOutputDocument.vue'
  import HttpPullOutputDocument from './documents/HTTP_PullOutputDocument.vue'
  import HttpPushOutputDocument from './documents/HTTP_PushOutputDocument.vue'
  import SrtOutputDocument from './documents/SRTOutputDocument.vue'
  import ZixiOutputDocument from './documents/ZixiOutputDocument.vue'
  import WhipOutputDocument from './documents/WHIPOutputDocument.vue'
  import ThumbnailOutputDocument from './documents/ThumbnailOutputDocument.vue'
    
  export default {
    name: 'EdgecasterOutputs',
    components: {
      GenericOutputDocument,
      MulticastOutputDocument,
      UnicastOutputDocument,
      RtmpOutputDocument,
      FileOutputDocument,
      RtspOutputDocument,
      HttpPullOutputDocument,
      HttpPushOutputDocument,
      SrtOutputDocument,
      ZixiOutputDocument,
      WhipOutputDocument,
      ThumbnailOutputDocument
    },
    props: ['deviceShadow', 'device_guid'],
    
    data() {
      return {
        whipDaemonVersion: this.$helpers.parseVersion('device', 'v10.1.0'),
        
        expanded: [],
        
        documents: [],
        hasChanges: false,
        expectingChanges: false,
        hasValidationError: false,
        
        showDeleteOutput: false,
        selectedOutput: false,
        
        showNewOutput: false,
        
        newOutput: false,
        newOutputHasValidationErrors: false,
        
        headers: [
          { text: 'Name', align: 'start', value: 'id', sortable: true },
          { text: 'Output Type', align: 'start text-capitalize', value: 'type', sortable: true },
          { text: 'Output Status', align: 'start', value: 'status', sortable: true },
        ],
        
        outputs: [],
      }
    },
    watch: {
      deviceShadow: {
        handler() {
          // only overwrite if we dont have any documents to start with
          if (this.expectingChanges || this.expanded.length == 0 || this.documents.length == 0) {
            var documents = this.deviceShadow.editableStateCopy()
            if (documents) {
              this.documents = documents
              
              // reset expectingChanges
              setTimeout(() => {
                this.expectingChanges = false
              }, 5000)
            }
          }
        }, 
        deep: true
      },
    },
    computed: {
      device() {
        return this.$devices.getDevice(this.device_guid)
      },
      
      canEdit() {
        if (this.xmlConfig || this.deviceShadow.updatePending) {
          return false
        }
        return this.$devices.getDevice(this.device_guid).canEdit()
      },
      
      xmlConfig() {
        return this.$devices.getDevice(this.device_guid).hasXMLConfig()
      },
      
      isDynamicSupported() {
        return this.$versioning.isDynamicSupported(this.device)
      },
      
      newOutputTypes() {
        var newOutputTypes = [
          { 'value': 'unicast', 'text': 'Unicast Output' },
          { 'value': 'multicast', 'text': 'Multicast Output' },
          { 'value': 'rtmp', 'text': 'RTMP Output' },
          { 'value': 'rtsp', 'text': 'RTSP Output' },
          { 'value': 'http_push', 'text': 'HTTP Push Output' },
          { 'value': 'srt', 'text': 'SRT Output' },
          { 'value': 'zixi', 'text': 'Zixi Output' },
          { 'value': 'file', 'text': 'File Record' },
        ]
        
        if (this.$helpers.versionCheck(this.whipDaemonVersion, this.device.daemon_version)) {
          newOutputTypes.push({ 'value': 'whip', 'text': 'WHIP Output' },)
        }
        
        return newOutputTypes
      }
    },
    
    mounted() {
      var documents = this.deviceShadow.editableStateCopy()
      if (documents) {
        this.documents = documents
        this.hasChanges = false
      }
    },
    
    methods: {
      outputName(item) {
        if (item.config && item.config.name) {
          return this.$helpers.htmlDecode(item.config.name)
        } else if (item.new) {
          return 'New Output'
        }
        return 'Output #' + item.id
      },
      outputType(item) {
        return this.$helpers.getLabel(item.type)
      },
      outputStatus(item) {
        if (item.new) {
          return 'New Component'
        }
        return this.$helpers.outputStatus(item)
      },
      
      newOutputShow() {
        this.newOutput = {  'id': 'new-' +  new Date().getTime(), 'type': 'unicast','new': true };
        this.showNewOutput = true
      },
      
      newOutputTypeChange() {
        console.log('EdgecasterOutputs newOutputTypeChange', this.newOutput)
        this.$delete(this.newOutput, 'config')
      },
      
      newOutputUpdate(config, hasValidationError = false) {
        console.log('EdgecasterOutputs newOutputUpdate', config, hasValidationError)
        this.newOutput.config = config
        this.newOutputHasValidationErrors = hasValidationError
      },
      
      createOutput() {
        if (!this.newOutputHasValidationErrors) {
          // fix for transient vars
          if (!this.newOutput.config.name) {
            this.newOutput.config.name = ''
          }
          
          console.log('EdgecasterOutputs createOutput', this.newOutput)
          
          this.documents.push(Object.assign({}, this.newOutput))
          this.hasChanges = true
          this.expanded = []
          
          this.showNewOutput = false
        }
      },
      
      deleteOutput(item) {
        this.selectedOutput = item
        this.showDeleteOutput = true
      },
      confirmDeleteOutput() {
        var outputIndex = this.documents.indexOf(this.selectedOutput)
        if (outputIndex !== -1) {
          console.log('EdgecasterOutputs confirmDeleteOutput deleting output', this.selectedOutput)
          
          this.documents.splice(outputIndex, 1)
          this.hasChanges = true
          this.hasValidationError = false
          this.expanded = []
        }
        this.showDeleteOutput = false
      },
      
      configUpdate(config, hasValidationError = false) {
        this.hasChanges = true
        this.hasValidationError = hasValidationError
      },
      
      saveShadow() {
        var state = this.documents.map((output) => {
          var id = (output.new) ? false : output.id
          return {'id': id, 'type': output.type, 'config': output.config}
        })
        
        this.deviceShadow.saveShadow(state)
        this.expanded = []
        this.hasChanges = false
        this.expectingChanges = true
      },
      
      revertShadow() {
        this.documents = this.deviceShadow.editableStateCopy()
        this.hasChanges = false
      },
    }
  }
</script>