<template>
  <v-container class="pa-0" fluid>
    <v-row class="ma-0 ma-md-2">
      <v-col cols="12" sm="12">
        <v-card min-height="700px">
          <v-toolbar flat>
            <v-toolbar-title>
              <v-icon size="32px">assessment</v-icon>
              Metrics Explorer
            </v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          
          <v-divider/>
          
          <v-card-text>
            <v-card
              flat
            >
              <v-card-text>
                <v-row class="mx-2">
                  <device-selection v-model="selectedDevices" :colorCode="true" />
                  <metrics-selection v-model="metrics" :symbols="pointStyles" :colorCode="selectedDevices.length < 2" />
                  <period-selection v-model="period" :initialRange="periodKey" />
                </v-row>
              </v-card-text>
            </v-card>
            
            <v-row>
              <v-col cols="12">
                <div v-if="loading" class="mx-5 mt-10">
                  <v-progress-linear
                    color="primary"
                    indeterminate
                    buffer-value="0"
                  ></v-progress-linear>
                </div>
                <div v-if="missingParameters">
                  <v-alert dense outlined type="info" class="ma-5">
                    Please select your reporting criteria above.
                  </v-alert>
                </div>
                <div v-if="!missingParameters">
                  <v-alert dense outlined type="error" v-if="error" class="ma-5">
                    {{ errorText }}
                  </v-alert>

                  <div v-if="!loading" class="mt-5">
                    <v-tabs v-model="section" color="secondary">
                      <v-tab href="#graph">
                        Graph
                      </v-tab>
                      <v-tab href="#data">
                        Data
                      </v-tab>
                    </v-tabs>

                    <v-divider />

                    <v-tabs-items v-model="section">
                      <v-tab-item value="graph">
                        <v-card flat>
                          <v-card-title>Timeseries Graph</v-card-title>
                          <videon-chart
                            :deviceMetrics="deviceMetrics"
                            :unit="unit"
                            :colorByDevice="selectedDevices.length > 1"
                            :pointStyles="pointStyles"
                            :pointStyleInLegend="true"
                            :pointStyleInTooltip="true"
                          />
                        </v-card>
                      </v-tab-item>
                      
                      <v-tab-item value="data">
                        <v-card flat>
                          <v-card-title>
                            Metrics Data
                            <v-spacer />
                            <v-btn
                              @click="exportAsCsv"
                              color="secondary"
                              small 
                              outlined
                            >
                              Download CSV
                            </v-btn>
                          </v-card-title>
                        
                          <v-data-table
                            class="mt-5"
                            :headers="tableHeaders"
                            :items="tableData"
                            :hide-default-footer="true"
                            :items-per-page="10000"
                          />
                        </v-card>
                      </v-tab-item>
                    </v-tabs-items>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
  //import { mapGetters } from 'vuex'
  import DeviceSelection from '../components/device/components/DeviceSelection.vue'
  import MetricsSelection from '../components/common/metrics/MetricsSelection.vue'
  import PeriodSelection from '../components/common/PeriodSelection.vue'
  
  import DeviceMetrics from '../components/common/metrics/DeviceMetrics.js'
  
  import moment from 'moment'

  export default {
    name: "Metrics",
    
    components: {
      DeviceSelection,
      MetricsSelection,
      PeriodSelection
    },
    computed: {
      queryParameters() {
        return this.$route.query
      },
      unit() {
        // just look at the first metric to determine unit
        if (this.metrics.length > 0) {
          var unit = false
          
          for (var index in this.metrics) {
            var thisUnit = this.$helpers.getUnit(this.metrics[index])
            if (unit && thisUnit != unit) {
              // not the same, do dont use a unit
              return ''
            } else {
              unit = thisUnit
            }
          }
          
          return unit
        }
        return ''
      },
      missingParameters() {
        if (!this.selectedDevices || this.selectedDevices.length == 0) {
          return true
        }
        
        if (!this.metrics || this.metrics.length == 0) {
          return true
        }
        
        if (!this.period) {
          return true
        }
        
        return false
      },
    },
    watch: {
      $route() {
        this.fetchMetrics()
      },
      selectedDevices() {
        if (!this.loading) {
          this.updateQueryParameters()
        } else {
          this.fetchQueued = true
        }
      },
      metrics() {
        if (!this.loading) {
          this.updateQueryParameters()
        } else {
          this.fetchQueued = true
        }
      },
      period() {
        if (!this.loading) {
          this.updateQueryParameters()
        } else {
          this.fetchQueued = true
        }
      },
      section(current) {
        if (current == 'data') {
          this.buildTable()
        }
      }
    },
    data() {
      return {
        loading: true,
        error: false,
        errorText: '',
        
        showSelectDevices: false,
        selectedDevices: [],

        section: false,
        
        device_guids: false,
        
        metrics: false,
        
        period: false,
        periodKey: false,
        
        deviceMetrics: new DeviceMetrics(),

        tableHeaders: [],
        tableData: [],

        fetchQueued: false,
        refreshTimer: false,
        refreshInterval: 20000,

        pointStyles: ['circle', 'triangle', 'rect', 'rectRot']
      }
    },
    created() {
      if (this.$route.query) {
        if (this.$route.query.device_guids) {
          this.device_guids = Array.isArray(this.$route.query.device_guids) ? this.$route.query.device_guids : [this.$route.query.device_guids]
          
          this.selectedDevices = this.device_guids.map((device_guid) => {
            return this.$devices.getDevice(device_guid)
          })
        }
        
        if (this.$route.query.metrics) {
          this.metrics = Array.isArray(this.$route.query.metrics) ? this.$route.query.metrics : [this.$route.query.metrics]
        }
        
        this.periodKey = (this.$route.query.period_key) ? this.$route.query.period_key : false

        this.section = this.$route.query.section || 'graph'
      }
    },
    mounted() {
      this.fetchMetrics()
    },
    methods: {
      fetchMetrics() {
        if (this.missingParameters) {
          this.loading = false
          return
        }

        if (this.fetchQueued) {
          this.fetchQueued = false

          this.updateQueryParameters()
        }

        this.tableData = []

        if (this.loading || document.hasFocus()) {
          
          this.loading = true
          this.error = false
          
          this.deviceMetrics.devices = this.selectedDevices
          this.deviceMetrics.metrics = this.metrics
          
          this.deviceMetrics.unit = this.unit
          
          this.deviceMetrics.end_time = this.period.end
          this.deviceMetrics.start_time = this.period.start
          this.deviceMetrics.period = this.period.period
          
          
          this.deviceMetrics.fetchMetrics().then(() => {
            console.log('Metrics fetchMetrics', this.deviceMetrics)
            
            this.loading = false
            
            if (this.section == 'data') {
              this.buildTable()
            }

            if (this.fetchQueued) {
              this.fetchMetrics()
            }
          }).catch((error) => {
            this.error = true
            this.errorText = this.$helpers.parseError(error)
            this.loading = false
            console.log('Metrics fetchMetrics error', error)
          })
        }
        
        if (this.refreshInterval && this.refreshInterval > 0) {
          this.refreshTimer = setTimeout(() => {
            this.fetchMetrics()
          }, this.refreshInterval * 1000)
        }
      },
      
      updateQueryParameters() {
        this.showSelectDevices = false
        
        this.device_guids = this.selectedDevices.map((device) => { return device.device_guid })
        
        if (this.device_guids && this.metrics) {
          console.log('Metrics updateQueryParameters', this.device_guids, this.metrics)
          
          this.$router.push({ path: '/metrics', query: {
            'device_guids': this.device_guids,
            'metrics': this.metrics,
            'period_key': this.period.key,
            'timestamp': new Date().getTime(),
            'section': this.section
          }}).catch((error) => {
            console.log('Metrics updateQueryParameters error', error)
          })
        }
      },

      buildTable() {
        // When fetchMetrics is being run, chartData() is an array of strings.
        // We know that fetchMetrics will call this when done, so we don't do anything.
        if (this.loading) {
          return
        } 
        
        var tableData = {}
        var tableHeaders = [
          {
            text: 'Timestamp',
            align: 'start text-no-wrap',
            value: 'timestamp',
          }
        ]
        
        // This will construct tableData like so:
        // {
        //   [time]: {
        //     [metric name]: [value],
        //     ...
        //   },
        //   ...
        // }
        
        this.deviceMetrics.chartData().forEach((metric) => {
          if (!(metric.label in tableHeaders)) {
            tableHeaders.push({
              text: metric.label,
              value: metric.label,
              align: 'center'
            })
          }
          
          metric.data.forEach((point) => {
            if (point) {
              var { x, y } = point
            
              if (!(x in tableData)) {
                tableData[x] = {}
              }
            
              tableData[x][metric.label] = y
            }
            
          })
        })

        // This will then convert that object to the form of
        // [
        //   {
        //     name: [time],
        //     [metric name]: [value],
        //     ...
        //   },
        //   ...
        // ]
        this.tableData = Object.keys(tableData).map(key => (
          {
            timestamp: moment(key).toISOString(),
            ...tableData[key]
          }
        ))
        this.tableHeaders = tableHeaders
      },

      tableToCsv() {
        this.buildTable()
        
        // headers = "Time","[header 0]","[header 1]",...
        var headers = this.tableHeaders.map((header) => '"' + header.text + '"').join(',') + '\n'
        
        return headers + this.tableData.map((row) => {
          // return "[date]",[value 0],[value 1]
          return this.tableHeaders.map((header) => {
            // Wrapping the time header in quotes. This isn't necessary, but having a date unwrapped scares me
            var wrapper = header.text == 'Timestamp' ? '"' : ''
            return (row[header.value]) ? wrapper + row[header.value] + wrapper : 'null'
          }).join(',')
        }).join('\n')
      },

      exportAsCsv() {
        var csv = this.tableToCsv()
        var file = new Blob([csv], {type: 'text/plain'})
        this.$helpers.downloadFile('metrics.csv', file)
      }
    }
  }
</script>