<template>
  <v-card flat>
    
    <v-dialog
      v-model="showDeleteEncoder"
      max-width="480px"
    >
      <videon-card heading="Delete Encoder" showClose="true" @close="showDeleteEncoder = false">
        <v-card-text>
          <v-alert
            type="info"
            text
            icon="mdi-lock-outline"
            class="mt-3"
            v-if="!isDynamicSupported"
          >
            Adding and removing encoders dynamically requires a minimum of daemon version {{ dynamicSupportRequirements.minimumDaemonVersion.original }} 
            and cloud agent version {{ dynamicSupportRequirements.minimumAgentVersion.original }}.
          </v-alert>
          <v-alert
            type="info"
            text
            icon="mdi-lock-outline"
            class="mt-3"
            v-else-if="selectedEncoder.type == 'data' && !$helpers.versionCheck(addRemoveDataEncoderDaemonVersion, device.daemon_version)"
          >
            Adding and removing data encoders dynamically requires a minimum of daemon version {{ addRemoveDataEncoderDaemonVersion.original }} 
            and cloud agent version {{ dynamicSupportRequirements.minimumAgentVersion.original }}.
          </v-alert>
          <v-form v-else @submit.prevent="confirmDeleteEncoder">
            <p class="subtitle-1 mt-3">
              Are you sure you want to delete this encoder?
            </p>
            <div class="text-right">
              <v-btn
                class="ma-1"
                color="secondary"
                @click="showDeleteEncoder = false"
                text
              >
                Cancel
              </v-btn>
              <v-btn
                class="ma-1"
                color="primary"
                @click="confirmDeleteEncoder"
              >
                Delete
              </v-btn>
            </div>
          </v-form>
        </v-card-text>
      </videon-card>
    </v-dialog>
    
    <v-dialog
      v-model="showNewEncoder"
      max-width="640px"
    >
      <videon-card heading="Add New Encoder" showClose="true" @close="showNewEncoder = false">
        <v-card-text>
          <v-alert
            type="info"
            text
            icon="mdi-lock-outline"
            class="mt-3"
            v-if="!isDynamicSupported"
          >
            Adding and removing encoders dynamically requires newer software. Please update to at least daemon version <strong>{{ dynamicSupportRequirements.minimumDaemonVersion.original }}</strong>
            and cloud agent version <strong>{{ dynamicSupportRequirements.minimumAgentVersion.original }}</strong>.
          </v-alert>
          
          <v-form v-else @submit.prevent="createEncoder">
            <v-select
              v-model="newEncoder.type"
              :items="newEncoderTypes"
              @change="newEncoderTypeChange"
              label="Encoder Type"
              outlined
              hide-details
            ></v-select>
            
            <div class="mt-5" v-if="newEncoder">
              <video-encoder-document v-if="newEncoder.type == 'video'" :encoder="newEncoder" :device_guid="device_guid" :canEdit="canEdit" :isNew="true" @configUpdate="newEncoderUpdate" :key="newEncoder.id"/>
              <audio-encoder-document v-else-if="newEncoder.type == 'audio'" :encoder="newEncoder" :device_guid="device_guid" :canEdit="canEdit" :isNew="true" @configUpdate="newEncoderUpdate" :key="newEncoder.id"/>
              <data-encoder-document v-else-if="newEncoder.type == 'data'" :encoder="newEncoder" :device_guid="device_guid" :canEdit="canEdit" :isNew="true" @configUpdate="newEncoderUpdate" :key="newEncoder.id"/>
              <pre v-else>{{ newEncoder }}</pre>
            </div>
            
            <div class="text-right">
              <v-btn
                class="ma-1"
                color="secondary"
                @click="showNewEncoder = false"
                text
              >
                Cancel
              </v-btn>
              <v-btn
                class="ma-1"
                color="primary"
                @click="createEncoder"
                :disabled="newEncoderHasValidationErrors"
              >
                Create Encoder
              </v-btn>
            </div>
          </v-form>
        </v-card-text>
      </videon-card>
    </v-dialog>
    
    <v-card-title>
      Encoder Configuration
      <v-spacer/>
      <v-btn
        small
        color="primary"
        tooltip="New Encoder"
        @click="newEncoderShow()"
        :disabled="!canEdit"
      >
        <v-icon>mdi-plus</v-icon> New Encoder
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-alert type="info" v-if="xmlConfig">These settings are currently managed by the active XML configuration.</v-alert>
      <v-data-table
        :headers="headers"
        :items="documents"
        :single-expand="true"
        :expanded.sync="expanded"
        item-key="id"
        :items-per-page="100"
        show-expand
        hide-default-footer
        @click:row="(item, slot) => slot.expand(!slot.isExpanded)"
      >
        <template
          v-slot:item.id="{ item }"
        >
          <span :class="(item.new) ? 'text--disabled' : ''">{{ encoderName(item) }}</span>
        </template>
        <template
          v-slot:item.type="{ item }"
        >
          <span :class="(item.new) ? 'text--disabled' : ''">{{ encoderType(item) }}</span>
        </template>
        <template
          v-slot:item.status="{ item }"
        >
          <span v-if="item.new" class="text--disabled">Not Created</span>
          <span v-else-if="item.status == 'UNABLE_TO_ENCODE'" class="error--text"><v-icon small color="error">warning</v-icon> {{ encoderStatus(item) }}</span>
          <span v-else-if="item.status != 'RUNNING'" :class="(item.new) ? 'text--disabled' : ''">{{ encoderStatus(item) }}</span>
          
          <span v-if="item.type == 'video' && item.status == 'RUNNING'" class="primary--text">{{ $helpers.getLabel(item.config.scaling_resolution) }} @ {{ item.current_bitrate }} kbps</span>
          <span v-if="item.type == 'audio' && item.status == 'RUNNING'" class="primary--text">{{ item.config.selected_channels.length }} channels @ {{ item.config.bitrate }} kbps</span>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length" class="px-8 pt-8 pb-5">
            <video-encoder-document v-if="item.type == 'video'" :encoder="item" :device_guid="device_guid" :canEdit="canEdit" @configUpdate="configUpdate" @deleteEncoder="deleteEncoder(item)" :key="item.id"/>
            <audio-encoder-document v-else-if="item.type == 'audio'" :encoder="item" :device_guid="device_guid" :canEdit="canEdit" @configUpdate="configUpdate" @deleteEncoder="deleteEncoder(item)" :key="item.id"/>
            <sync-group-document v-else-if="item.type == 'sync_group'" :encoder="item" :device_guid="device_guid" :canEdit="canEdit" @configUpdate="configUpdate" @deleteEncoder="deleteEncoder(item)" :key="item.id"/>
            <data-encoder-document v-else-if="item.type == 'data'" :encoder="item" :device_guid="device_guid" :canEdit="canEdit" @configUpdate="configUpdate" @deleteEncoder="deleteEncoder(item)" :key="item.id"/>
            <generic-encoder-document v-else :encoder="item" :device_guid="device_guid" :canEdit="canEdit" @configUpdate="configUpdate" @deleteEncoder="deleteEncoder(item)" :key="item.id"/>
          </td>
        </template>
      </v-data-table>
      <div class="mt-5 text-right" v-if="canEdit">
        <v-btn
          color="secondary"
          text
          class="ma-1"
          @click="revertShadow()"
          :disabled="hasChanges == false"
        >
          Revert
        </v-btn>
        <v-btn
          color="primary"
          class="ma-1"
          @click="saveShadow()"
          :disabled="hasChanges == false || hasValidationError == true"
        >
          Save
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
  import { mapGetters } from 'vuex'
  
  import VideoEncoderDocument from './documents/VideoEncoderDocument.vue'
  import AudioEncoderDocument from './documents/AudioEncoderDocument.vue'
  import SyncGroupDocument from './documents/SyncGroupDocument.vue'
  import DataEncoderDocument from './documents/DataEncoderDocument.vue'
  import GenericEncoderDocument from './documents/GenericEncoderDocument.vue'

  export default {
    name: 'EdgecasterEncoders',
    components: {
      VideoEncoderDocument,
      AudioEncoderDocument,
      SyncGroupDocument,
      DataEncoderDocument,
      GenericEncoderDocument
    },
    props: ['deviceShadow', 'device_guid'],
    
    data() {
      return {
        addRemoveDataEncoderDaemonVersion: this.$helpers.parseVersion('device', 'v10.1.0'),
        
        dynamicSupportRequirements: {
          minimumDaemonVersion: this.$helpers.parseVersion('device', 'v9.0.0'),
          minimumAgentVersion: this.$helpers.parseVersion('cloud', 'v1.1.0'),
        },
        
        expanded: [],
        
        documents: [],
        hasChanges: false,
        expectingChanges: false,
        hasValidationError: false,
        
        showDeleteEncoder: false,
        selectedEncoder: false,
        
        showNewEncoder: false,
        
        newEncoder: false,
        newEncoderHasValidationErrors: false,
        
        headers: [
          { text: 'Name', align: 'start', value: 'id', sortable: true },
          { text: 'Encoder Type', align: 'start text-capitalize', value: 'type', sortable: true },
          { text: 'Encoder Status', align: 'start', value: 'status', sortable: true },
        ],
        
        encoders: []
      }
    },
    watch: {
      deviceShadow: {
        handler() {
          // only overwrite if we dont have any documents to start with
          if (this.expectingChanges || this.expanded.length == 0 || this.documents.length == 0) {
            var documents = this.deviceShadow.editableStateCopy()
            if (documents) {
              this.documents = documents
              
              // reset expectingChanges
              setTimeout(() => {
                this.expectingChanges = false
              }, 5000)
            }
          }
        }, 
        deep: true
      }
    },
    computed: {
      ...mapGetters('userPreferences', ['shadowDebug']),
      
      device() {
        return this.$devices.getDevice(this.device_guid)
      },
      canEdit() {
        if (this.xmlConfig || this.deviceShadow.updatePending) {
          return false
        }
        return this.$devices.getDevice(this.device_guid).canEdit()
      },
      xmlConfig() {
        return this.$devices.getDevice(this.device_guid).hasXMLConfig()
      },
      // dynamic encoders have been supported for a while
      isDynamicSupported() {
        if (this.device.cloud_version.dev || this.$helpers.versionCheck(this.dynamicSupportRequirements.minimumAgentVersion, this.device.cloud_version)) {
          if (this.device.product_name == 'edgecaster_max' || this.$helpers.versionCheck(this.dynamicSupportRequirements.minimumDaemonVersion, this.device.daemon_version)) {
            return true
          }
        }
        return false
      },
      newEncoderTypes() {
        var encoderTypes = [
          { 'value': 'video', 'text': 'Video Encoder' },
          { 'value': 'audio', 'text': 'Audio Encoder' }
        ]
        
        if (this.$helpers.versionCheck(this.addRemoveDataEncoderDaemonVersion, this.device.daemon_version)) {
          encoderTypes.push({ 'value': 'data', 'text': 'Data Encoder' })
        }
        
        return encoderTypes
      }
    },
    
    mounted() {
      var documents = this.deviceShadow.editableStateCopy()
      if (documents) {
        this.documents = documents
        this.hasChanges = false
      }
    },
    
    methods: {
      encoderName(item) {
        if (item.config && item.config.name) {
          return this.$helpers.htmlDecode(item.config.name)
        } else if (item.new) {
          return 'New Encoder'
        }
        return 'Encoder #' + item.id
      },
      encoderType(item) {
        var encType = item.type.split('_').join(' ')
        
        if (item.config && item.config.selected_codec) {
          encType += ' (' + this.$helpers.getLabel(item.config.selected_codec) + ')'
        } else if (item.config && item.config.codec) {
          encType += ' (' + this.$helpers.getLabel(item.config.codec) + ')'
        } else if (item.config && item.config.data_type) {
          encType += ' (' + this.$helpers.getLabel(item.config.data_type) + ')'
        }
        
        return encType
      },
      encoderStatus(item) {
        if (item.status) {
          return this.$helpers.getLabel(item.status)
        }
        return ''
      },
      
      newEncoderShow() {
        this.newEncoder = { 'id': 'new-' +  new Date().getTime(), 'type': 'video', 'new': true }
        this.showNewEncoder = true
      },
      
      newEncoderTypeChange() {
        console.log('EdgecasterEncoders newEncoderTypeChange', this.newEncoder)
        this.$delete(this.newEncoder, 'config')
      },
      
      newEncoderUpdate(config, hasValidationError = false) {
        console.log('EdgecasterEncoders newEncoderUpdate', config, hasValidationError)
        this.newEncoder.config = config
        this.newEncoderHasValidationErrors = hasValidationError
      },
      
      createEncoder() {
        if (!this.newEncoderHasValidationErrors) {
          if (!this.newEncoder.config.name) {
            this.newEncoder.config.name = ''
          }
          
          console.log('EdgecasterEncoders createEncoder', this.newEncoder)
          this.documents.push(Object.assign({}, this.newEncoder))
          this.hasChanges = true
          this.expanded = []
          
          this.showNewEncoder = false
        }
      },
      
      deleteEncoder(item) {
        this.selectedEncoder = item
        this.showDeleteEncoder = true
      },
      confirmDeleteEncoder() {
        var encoderIndex = this.documents.indexOf(this.selectedEncoder)
        if (encoderIndex !== -1) {
          console.log('EdgecasterEncoders confirmDeleteEncoder deleting encoder', this.selectedEncoder)
          
          this.documents.splice(encoderIndex, 1)
          this.hasChanges = true
          this.expanded = []
        }
        this.showDeleteEncoder = false
      },
      
      configUpdate(config, hasValidationError = false) {
        this.hasChanges = true
        this.hasValidationError = hasValidationError
      },
      
      saveShadow() {
        var state = this.documents.map((encoder) => {
          var id = (encoder.new) ? false : encoder.id
          return {'id': id, 'type': encoder.type, 'config': encoder.config}
        })
        
        this.deviceShadow.saveShadow(state)
        this.expanded = []
        this.hasChanges = false
        this.expectingChanges = true
      },
      
      revertShadow() {
        this.documents = this.deviceShadow.editableStateCopy()
        this.hasChanges = false
      }
    }
  }
</script>