<template>
  <v-app id="cloud_platform">
    <navigation :drawer="drawer" v-if="isLoggedIn"></navigation>
    
    <v-app-bar
      :clipped-left="$vuetify.breakpoint.smAndUp"
      color="dark_background"
      dark
      fixed
      flat
      app
    >
      <v-app-bar-nav-icon 
        @click="drawer = !drawer" 
        v-if="isLoggedIn"
        class="d-sm-inline-block d-md-none"
      />
      
      <img class="logo d-none d-sm-inline-block" src="./assets/videon-logo-white-orange-64.svg"  />
      
      <v-toolbar-title class="headline">{{ deploymentName }}</v-toolbar-title>
      
      <v-spacer></v-spacer>
      
      <div v-if="isLoggedIn">
        <v-badge
          v-if="$alerts.hasAlerts()"
          :content="$alerts.alertCount()"
          class="d-none d-md-inline-block mr-5"
          color="error"
          overlap
        >
          <v-icon @click="$router.push('/alerts')">notifications_active</v-icon>
        </v-badge>
        
        <v-menu
          :allow-overflow="true"
          bottom
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              text
              dark
              v-bind="attrs"
              v-on="on"
              :small="$vuetify.breakpoint.mobile"
            >
              <v-badge
                :content="pendingInvites"
                :value="pendingInvites"
                color="primary"
                inline
              >
                <v-icon>settings</v-icon>
                Settings
              </v-badge>
            </v-btn>
          </template>
          
          <v-list>
            <v-list-item to="/account">
              <v-list-item-title><v-icon>person</v-icon> Manage Account</v-list-item-title>
            </v-list-item>
            
            <v-divider></v-divider>
            
              <v-list-item @click="switchOrg" v-if="true || organizations.length > 1">
                <v-badge
                  :content="pendingInvites"
                  :value="pendingInvites"
                  color="primary"
                  inline
                >
                  <v-list-item-title class="mr-2">
                    <v-icon>house</v-icon> 
                    Switch Organization
                  </v-list-item-title>
                </v-badge>
              </v-list-item>
            
            <v-list-item to="/orgs">
              <v-list-item-title><v-icon>location_city</v-icon> My Organizations</v-list-item-title>
            </v-list-item>
            
            <v-divider></v-divider>
            <v-list-item @click="resetCache" v-if="debug && isAdmin">
              <v-list-item-title><v-icon>refresh</v-icon> Reset Caches</v-list-item-title>
            </v-list-item>
            <v-divider v-if="debug && isAdmin"></v-divider>
            <v-list-item @click="menuLogout">
              <v-list-item-title><v-icon>lock</v-icon> Logout</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        
        <v-btn
          color="secondary"
          text
          dark
          target="_blank"
          class="d-none d-sm-inline-flex"
          href="https://support.videonlabs.com/hc/en-us/articles/6004577224979-How-to-use-LiveEdge-Cloud"
        >
          <v-icon>support</v-icon>
          Support
        </v-btn>
      </div>
      <div v-else>
        <v-btn
          v-if="$route.name != 'Authentication'"
          color="primary"
          text
          dark
          @click="$router.push('/auth/login')"
        >
          User Login
        </v-btn>
      </div>
    </v-app-bar>
    
    <v-main>
      <v-container fluid class="page-wrapper pa-0">
        <v-banner
          v-if="shouldShowBanner"
          two-line
          :icon="banner.icon"
          :color="banner.color"
          dark
        >
          <h4 class="mb-1">{{ banner.title }}</h4>
          <div v-html="banner.html"></div>
          
          <template v-slot:actions>
            <v-btn outlined small @click="setHideBanner">Hide</v-btn>
          </template>
        </v-banner>
        
        <router-view></router-view>
      </v-container>
    </v-main>
    
    <v-footer
      color="dark_background"
      dark
      padless
      inset
    >
      <v-col
        class="text-right pa-1 pr-3"
        cols="12"
      >
        &copy; {{ new Date().getFullYear() }} <strong>Videon</strong> - <a @click="$router.push('/legal/csa')">Cloud Services Agreement</a>
      </v-col>
    </v-footer>
  </v-app>
</template>

<script>
  import { mapGetters } from 'vuex'
  import Navigation from './components/Navigation.vue'

  export default {
    components: {
      Navigation
    },
    data() {
      return {
        debug: false,
        drawer: null
      }
    },
    computed: {
      ...mapGetters('config', ['deploymentName']),
      ...mapGetters('user', ['isLoggedIn', 'isAdmin', 'user', 'organization', 'organizations', 'pendingInvites']),
      ...mapGetters('userPreferences', ['darkMode', 'hideBanner']),
      
      shouldShowBanner() {
        if (this.$banners.defaultBanner() && !this.hideBanner) {
          return true
        }
        return false
      },
      
      banner() {
        return this.$banners.defaultBanner()
      }
    },
    created() {
      this.$vuetify.theme.dark = this.darkMode
    },
    watch: {
      darkMode() {
        this.$vuetify.theme.dark = this.darkMode
      }
    },
    methods: {
      setHideBanner() {
        this.$store.dispatch('userPreferences/setHideBanner', true)
      },
      
      switchOrg() {
        this.$store.dispatch('user/setStage', 'organization_selection').then(() => {
          this.$router.push('/auth/organizations')
        })
      },
      menuLogout() {
        this.$router.push('/auth/login').then(() => {
          this.$store.dispatch('user/logout')
        })
      },
      resetCache() {
        // reset caches
        this.$devices.reset()
        this.$fleets.reset()
        this.$deviceShadows.reset()
      }
    }
  }
</script>

<style lang="scss">
  .v-application {
    background-color: var(--v-background-base) !important;
  }
  
  .logo {
    max-width: 130px;
    margin: -8px 20px 0 10px;
  }
  
  .v-icon.v-icon {
    margin-right: 3px;
  }
  
  .page-wrapper a {
    color: var(--v-text-base) !important;
  }
  
  .container {
    max-width: unset !important;
  }
  
  .v-tabs:not(.v-tabs--vertical):not(.v-tabs--right) > .v-slide-group--is-overflowing.v-tabs-bar--is-mobile:not(.v-slide-group--has-affixes) .v-slide-group__prev {
    display: none !important;
  }
</style>
  