<template>
  <v-card 
    class="mx-auto"
  >
    <div v-if="deviceError" class="ma-2">
      <v-row align="center" style="min-height: 200px;">
        <v-col cols="2" class="text-center"><v-icon size="72" color="red">warning</v-icon></v-col>
        <v-col cols="9">
          <h2 class="my-5">Device not found</h2>
          <p>
            There was an error loading this device...
          </p>
          <v-btn
            outlined
            small
            class="ma-1"
            @click="$router.go(-1)"
          >
            <v-icon>mdi-arrow-left</v-icon> Back
          </v-btn>
        </v-col>
      </v-row>
    </div>
    
    <div v-else>
      <v-toolbar flat>
        <v-toolbar-title :class="toolbarClass">
          <v-icon size="32px" v-if="alerts.length > 0" color="red">notifications_active</v-icon>
          <v-icon size="32px" v-else>{{ icon }}</v-icon>
        
          {{ deviceName }}
        
          <span v-if="device.state" class="ml-2">
            <streaming-status-label :device="device" :noSync="true"/>
          </span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          text
          small
          class="ma-1"
          @click="$router.go(-1)"
        >
          <v-icon>mdi-arrow-left</v-icon> Back
        </v-btn>
      
      
        <v-menu
          v-if="device && canControl"
          :allow-overflow="true"
          :close-on-content-click="false"
          eager
          bottom
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              text
              v-bind="attrs"
              v-on="on"
              color="primary"
              :disabled="!device.isOnline()"
            >
              <v-icon>keyboard_command_key</v-icon>
              Commands
            </v-btn>
          </template>
          <v-list>
            <output-control :device="device" v-if="canControl" />
            <download-logs :device="device" v-if="canControl" />
            <adb-control :device="device" v-if="canEdit && device.product_name == 'edgecaster'" />
            <storage-devices :device="device" v-if="canEdit" />
            <local-ui-password :device="device" v-if="canEdit && device.product_name == 'edgecaster'" />
            <v-divider></v-divider>
            <reset-settings :device="device" v-if="canEdit" />
            <reboot-device :device="device" v-if="canControl" />
          </v-list>
        </v-menu>
      </v-toolbar>
    
      <v-divider/>
    
      <v-alert 
        type="error" 
        prominent
        tile
        icon="notifications_active"
        v-if="alerts.length > 0" 
        class="mb-0"
      >
        <div class="text-h6">
          <v-row class="no-gutters ml-3">
            Device Alerts
            <v-spacer/>
            <v-btn
              outlined
              small
              @click="$router.push('/alerts')"
            >
              Manage Alerts
            </v-btn>
          </v-row>
        </div>
        <ul class="ma-2">
          <li v-for="alert in alerts" v-bind:key="alert.alert_guid">
            {{ alert.label }}
          </li>
        </ul>
      </v-alert>
    
      <v-tabs
        v-model="section"
        color="secondary"
        :show-arrows="true"
      >
        <v-tabs-slider></v-tabs-slider>
        <v-tab :to="href('info')">
          Device Info
        </v-tab>
        <v-tab :to="href('config')">
          Configuration
        </v-tab>
        <v-tab :to="href('containers')">
          Containers
        </v-tab>
        <v-tab :to="href('users')" v-if="canEdit">
          Users
        </v-tab>
        <v-tab :to="href('licenses')">
          Licenses
        </v-tab>
      </v-tabs>
    
      <v-divider/>
    
      <v-card-text>
        <v-tabs-items v-model="section">
      
          <v-tab-item :value="href('info')">
            <device-info :device_guid="device_guid" :device="device" />
          </v-tab-item>
        
          <v-tab-item :value="href('config')">
            <device-config :device_guid="device_guid" :device="device" />
          </v-tab-item>
        
          <v-tab-item :value="href('containers')">
            <device-containers :device_guid="device_guid" :device="device" />
          </v-tab-item>
        
          <v-tab-item :value="href('users')" v-if="canEdit">
            <device-users :device_guid="device_guid" :device="device" />
          </v-tab-item>
        
          <v-tab-item :value="href('licenses')">
            <device-licenses :device_guid="device_guid" :device="device" />
          </v-tab-item>
        
        </v-tabs-items>
      </v-card-text>
    </div>
  </v-card>
</template>

<script>
  import { mapGetters } from 'vuex'
  
  import DeviceInfo from './sections/DeviceInfo.vue'
  import DeviceConfig from './sections/DeviceConfig.vue'
  import DeviceContainers from './sections/DeviceContainers.vue'
  import DeviceUsers from './sections/DeviceUsers.vue'
  import DeviceLicenses from './sections/DeviceLicenses.vue'
    
  export default {
    name: 'Device',
        
    components: {
      DeviceInfo,
      DeviceConfig,
      DeviceContainers,
      DeviceUsers,
      DeviceLicenses,
    },
    
    data() {
      return {
        section: this.$route.params.section || 'info',
        
        device: null,
        metrics: null,
        
        refreshTimer: false,
        refreshInterval: this.$helpers.getRefreshInterval(5000, 10000)
      }
    },
    
    computed: {
      ...mapGetters('user', ['user', 'guid', 'isDeviceAdmin', 'organization']),
      
      loading() {
        if (this.device && !this.device.ready) {
          return true
        }
        return false
      },
      
      icon() {
        if (this.device) {
          return this.device.icon()
        }
        return ''
      },
      
      deviceError() {
        if (this.device) {
          return this.device.error
        }
        return false
      },
      
      deviceName() {
        if (this.device) {
          return this.device.deviceName()
        }
        
        return this.device_guid
      },
      
      canEdit() {
        if (this.device) {
          return this.device.canEdit()
        }
        
        return false
      },
      
      canControl() {
        if (this.device) {
          return this.device.canControl()
        }
        
        return false
      },
      
      alerts() {
        return this.$alerts.alertsForDevice(this.device_guid)
      },
      
      toolbarClass() {
        if (this.alerts.length > 0) {
          return 'red--text'
        }
        
        return ''
      },
    },
    props: {
      device_guid: {
        type: String,
        required: true
      }
    },
    watch: {
      $route() {
        this.section = this.$route.params.section || 'info'
      },
    },
    created() {
      this.device = this.$devices.getDevice(this.device_guid)
    },
    mounted() {
      this.syncDocuments()
      
      if (this.device.users.length == 0) {
        this.device.fetchUsers()
      }
    },
    beforeDestroy() {
      if (this.refreshTimer) {
        clearTimeout(this.refreshTimer)
        this.refreshTimer = false
      }
    },
    methods: {
      href(section) {
        return '/devices/' + this.device_guid + '/' + section
      },
      
      syncDocuments() {
        if (this.device.state) {
          this.device.syncDocuments()
        }
      
        if (this.refreshInterval) {
          this.refreshTimer = setTimeout(() => {
            this.syncDocuments()
          }, this.refreshInterval)
        }
      }
    }
  }
</script>